import {
  Button,
  FormHelperText,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import { cloneDeep } from "lodash";
import { memo, ReactNode, useCallback, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ParameterDefinition, ParameterType } from "../../../graphql/generated";
import { TrashIcon } from "../../Icons";
import { useValidationContext } from "../ValidationContext";
import { validateRoutesField } from "../validation-functions";
import {
  ConditionInput,
  ConditionInputValue,
  defaultConditionValue,
} from "./ConditionInput";
import { ParamInputProps } from "./ParameterInput";
import { StringParamInput } from "./StringParamInput";
import styles from "./parameter-input.module.scss";

export type RoutesValue = RouteDefinition[];

interface RouteDefinition {
  id: string;
  condition: string | ConditionInputValue;
}

function emptyItem(): RouteDefinition {
  return {
    id: "",
    condition: defaultConditionValue(),
  };
}
function emptyValue(): RoutesValue {
  return [emptyItem()];
}

export const RoutesInput: React.FC<ParamInputProps<RoutesValue>> = ({
  definition,
  value,
  readOnly,
  onValueChange,
}) => {
  const initValue = value != null && value.length > 0 ? value : emptyValue();
  const [controlValue, setControlValue] = useState<RoutesValue>(initValue);
  const { errors, touch, touched, setError } = useValidationContext();

  const handleAdd = useCallback(() => {
    const newValue = cloneDeep(controlValue);
    newValue.push({
      id: `route-${newValue.length + 1}`,
      condition: defaultConditionValue(),
    });
    setControlValue(newValue);
    onValueChange && onValueChange(newValue);
  }, [controlValue, setControlValue, onValueChange]);

  const handleRemove = useCallback(
    (index: number) => {
      const newValue = cloneDeep(controlValue);
      newValue.splice(index, 1);
      setControlValue(newValue);
      onValueChange && onValueChange(newValue);
    },
    [controlValue, setControlValue, onValueChange],
  );

  const handleItemChange = useCallback(
    (value: any, index: number, field: keyof RouteDefinition) => {
      const newValue = cloneDeep(controlValue);
      newValue[index][field] = value;

      if (!touched[definition.name]) {
        touch(definition.name);
      }

      setControlValue(newValue);
      onValueChange && onValueChange(newValue);
      validateRoutesField(definition, newValue, setError);
    },
    [
      definition,
      controlValue,
      setControlValue,
      onValueChange,
      touch,
      touched,
      setError,
    ],
  );

  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newValue = cloneDeep(controlValue);
      const [draggedItem] = newValue.splice(dragIndex, 1);
      newValue.splice(hoverIndex, 0, draggedItem);
      setControlValue(newValue);
      onValueChange && onValueChange(newValue);
    },
    [controlValue, setControlValue, onValueChange],
  );

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <FormLabel filled={true}>{definition.label}</FormLabel>
        <FormHelperText filled={true}>{definition.description}</FormHelperText>
        {errors[definition.name] && touched[definition.name] && (
          <Stack>
            <FormHelperText sx={{ marginLeft: 0 }} component="span" error>
              {errors[definition.name]}
            </FormHelperText>
          </Stack>
        )}
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={5}
          marginTop={"16px"}
          className={styles["routes-input"]}
        >
          <RouteList
            controlValue={controlValue}
            definition={definition}
            handleRemove={handleRemove}
            handleItemChange={handleItemChange}
            moveItem={moveItem}
            readOnly={readOnly}
          />
          {!readOnly && (
            <Stack
              direction="row"
              width="100%"
              marginTop="16px"
              paddingRight="36px"
            >
              <Button onClick={handleAdd} variant="outlined">
                Add Route
              </Button>
            </Stack>
          )}
        </Stack>
      </DndProvider>
    </>
  );
};

export function getRouteFieldName(
  definition: ParameterDefinition,
  index: number,
  field: keyof RouteDefinition,
) {
  return `${definition.name}-${index}-${field}`;
}

// ----------------------------------------------------------------------

interface RouteListProps {
  definition: ParameterDefinition;
  controlValue: RoutesValue;
  handleRemove: (index: number) => void;
  handleItemChange: (
    value: any,
    index: number,
    field: keyof RouteDefinition,
  ) => void;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  readOnly?: boolean;
}

const RouteList: React.FC<RouteListProps> = ({
  definition,
  controlValue,
  moveItem,
  handleRemove,
  handleItemChange,
  readOnly,
}) => {
  const name = definition.name;
  return (
    <>
      {controlValue.map((item, index) => {
        const key = `${name}-${index}`;
        return (
          <DraggableItem
            index={index}
            key={key}
            routeId={controlValue[index].id}
            routeIndex={(routeId) =>
              controlValue.findIndex((r) => r.id === routeId)
            }
            moveItem={moveItem}
          >
            <Stack direction="column" spacing={2} width={"100%"}>
              <Stack flexGrow={1} spacing={2} direction="row">
                <StringParamInput
                  definition={{
                    name: getRouteFieldName(definition, index, "id"),
                    label: "Route ID",
                    type: ParameterType.String,
                    required: true,
                    description: "",
                    default: "",
                    options: {},
                  }}
                  value={item.id}
                  onValueChange={(v) => handleItemChange(v, index, "id")}
                  readOnly={readOnly}
                  skipValidation
                />
                {!readOnly && (
                  <Stack flexGrow={0} spacing={2}>
                    <Button
                      onClick={() => handleRemove(index)}
                      variant="outlined"
                    >
                      <TrashIcon width={20} />
                    </Button>
                  </Stack>
                )}
              </Stack>
              <ConditionInput
                definition={{
                  name: getRouteFieldName(definition, index, "condition"),
                  label: "",
                  type: ParameterType.Condition,
                  required: true,
                  description: "",
                  default: {
                    ottl: "",
                  },
                  options: {
                    variant: "collapsed,ottlContext",
                  },
                }}
                value={item.condition}
                onValueChange={(newValue) =>
                  handleItemChange(newValue, index, "condition")
                }
                readOnly={readOnly}
              />
            </Stack>
          </DraggableItem>
        );
      })}
    </>
  );
};

// ----------------------------------------------------------------------
interface DraggableItemProps {
  index: number;
  routeId: string;
  routeIndex: (routeId: string) => number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  children: ReactNode;
}

interface RouteDragItem {
  id: string;
}

const DraggableItemControl: React.FC<DraggableItemProps> = ({
  index,
  routeId,
  routeIndex,
  moveItem,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isOver }, drop] = useDrop<
    RouteDragItem,
    unknown,
    { isOver: boolean }
  >({
    accept: "ROUTE",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(dragItem: RouteDragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = routeIndex(dragItem.id);
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: "ROUTE",
    item: { id: routeId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(ref);
  dragPreview(ref);

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        backgroundColor: isOver ? "#eee" : "inherit",
        borderRadius: isOver ? "5px" : "0px",
      }}
    >
      <div style={{ width: "100%", opacity: isOver ? 0 : 1 }}>
        <Stack direction="row" spacing={2} width={"100%"}>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              backgroundColor: "#eee",
              padding: "5px",
              borderRadius: "5px",
              cursor: isDragging ? "grabbing" : "grab",
            }}
            ref={drag}
          >
            <Typography variant="h6">{index + 1}</Typography>
          </Stack>

          {children}
        </Stack>
      </div>
    </div>
  );
};

const DraggableItem = memo(DraggableItemControl);
