import { createContext, useContext, useState } from "react";
import { usePipelineGraphV2MetricsSubscription } from "../../graphql/generated";
import { BPGraphMetrics } from "../ConfigurationFlowV2/metrics";
import { useBPGraph } from "./BPGraphProvider";
import { PipelineGraphMetricsData } from "./PipelineGraphMetricsData";
import { useV2PipelineGraph } from "./PipelineGraphV2Context";

interface GraphMetricsProviderProps {
  telemetryType: string;
}

type GraphMetricsContextValue = BPGraphMetrics | null;
const graphMetricsContext = createContext<GraphMetricsContextValue>(null);

export const GraphMetricsProvider: React.FC<GraphMetricsProviderProps> = ({
  telemetryType,
  children,
}) => {
  const { period, configuration } = useV2PipelineGraph();
  const [graphMetrics, setMetrics] = useState<BPGraphMetrics | null>(null);
  const { graph } = useBPGraph();

  usePipelineGraphV2MetricsSubscription({
    variables: {
      period,
      name: configuration?.metadata.name,
    },
    skip: !configuration,
    onData(options) {
      if (!options.data.data) return;
      const metricData = new PipelineGraphMetricsData(
        options.data.data,
        telemetryType,
      );

      setMetrics(new BPGraphMetrics(graph, metricData, telemetryType, period));
    },
  });
  return (
    <graphMetricsContext.Provider value={graphMetrics}>
      {children}
    </graphMetricsContext.Provider>
  );
};

export function useGraphMetrics() {
  return useContext(graphMetricsContext);
}
