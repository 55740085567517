import { memo } from "react";
import { NodeProps } from "reactflow";
import { hasPipelineTypeFlag } from "../../../types/configuration";
import { findResource } from "../../../utils/classes/configuration";
import { ResourceDestinationCard } from "../../Cards/ResourceDestinationCard";
import { useBPGraph } from "../BPGraphProvider";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { useRouting } from "../RoutingContext/RoutingContext";
import { AttributeName, V2NodeData } from "../types";

const DestinationNodeV2: React.FC<NodeProps<V2NodeData>> = ({ id, data }) => {
  const { configuration, refetchConfiguration, readOnlyGraph } =
    useV2PipelineGraph();
  const { hoveredSet, onMouseEnterNode, onMouseExitNode } = useBPGraph();
  const { canConnect } = useRouting();
  const { attributes } = data;
  const componentPath = attributes[AttributeName.ComponentPath];
  if (componentPath == null) {
    throw new Error("missing required attribute in destination node");
  }

  const isConnectable = canConnect(componentPath);
  const isInactive = !hasPipelineTypeFlag(
    data.telemetryType,
    attributes[AttributeName.ActiveTypeFlags],
  );
  const notInHoveredSet =
    hoveredSet.length > 0 && !hoveredSet.find((elem) => elem === id);

  if (configuration == null) {
    return null;
  }

  const resourceConfig = findResource(configuration, componentPath);
  if (resourceConfig == null) {
    return null;
  }

  return (
    <div
      onMouseEnter={() => {
        onMouseEnterNode(id);
      }}
      onMouseLeave={onMouseExitNode}
    >
      <ResourceDestinationCard
        // Override the onClick so we don't open the edit dialog when trying to connect.
        onClick={isConnectable ? () => {} : undefined}
        key={id}
        destinationIndex={attributes[AttributeName.DestinationIndex]}
        name={attributes.resourceId}
        disabled={isInactive || notInHoveredSet}
        onDeleteSuccess={onMouseExitNode}
        configuration={configuration}
        refetchConfiguration={refetchConfiguration}
        readOnly={!!readOnlyGraph}
      />
    </div>
  );
};

export default memo(DestinationNodeV2);
