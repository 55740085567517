import { useSnackbar } from "notistack";
import { memo, useMemo } from "react";
import { ShowPageConfig } from ".";
import {
  DialogResource,
  NewResourceDialog,
  ResourceType,
} from "../../../components/ResourceDialog";
import { Kind, useSourcesAndTypesQuery } from "../../../graphql/generated";
import { UpdateStatus } from "../../../types/resources";
import {
  BPConfiguration,
  BPResourceConfiguration,
} from "../../../utils/classes";
import { existingResourcesNotInConfiguration } from "../../../utils/resources";

interface AddSourcesComponentProps {
  configuration: NonNullable<ShowPageConfig>;
  refetchConfiguration: () => void;
  setAddDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addDialogOpen: boolean;
}

const AddSourcesComponent: React.FC<AddSourcesComponentProps> = ({
  configuration,
  refetchConfiguration,
  setAddDialogOpen,
  addDialogOpen,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useSourcesAndTypesQuery({
    onError(error) {
      console.error(error);
      enqueueSnackbar(
        "There was an error retrieving Sources and SourceTypes.",
        {
          variant: "error",
        },
      );
    },
  });

  async function onNewSourceSave(
    values: { [key: string]: any },
    sourceType: ResourceType,
  ) {
    const newSourceConfig = new BPResourceConfiguration();
    newSourceConfig.type = sourceType.metadata.name;
    newSourceConfig.setParamsFromMap(values);

    const updatedConfig = new BPConfiguration(configuration);
    updatedConfig.addSource(newSourceConfig);
    try {
      const update = await updatedConfig.apply();
      if (update.status === UpdateStatus.INVALID) {
        console.error(update);
        throw new Error("failed to add source to configuration.");
      }

      setAddDialogOpen(false);
      refetchConfiguration();
    } catch (err) {
      enqueueSnackbar("Failed to save source.", {
        variant: "error",
      });
      console.error(err);
    }
  }

  async function addExistingSource(existingSource: DialogResource) {
    const config = new BPConfiguration(configuration);
    config.addSource({
      name: existingSource.metadata.name,
      disabled: existingSource.spec.disabled ?? false,
    });

    try {
      const update = await config.apply();
      if (update.status === UpdateStatus.INVALID) {
        console.error(update);
        throw new Error(
          `failed to update resource, got status ${update.status}`,
        );
      }

      setAddDialogOpen(false);
      refetchConfiguration();
    } catch (err) {
      enqueueSnackbar("Failed to add Source.", { variant: "error" });
    }
  }

  const unusedExistingSources = useMemo(
    () =>
      existingResourcesNotInConfiguration(
        data?.sources ?? [],
        configuration.spec.sources ?? [],
      ),
    [data?.sources, configuration.spec.sources],
  );

  return (
    <NewResourceDialog
      platform={configuration.metadata.labels.platform ?? "unknown"}
      kind={Kind.Source}
      resourceTypes={data?.sourceTypes ?? []}
      resources={unusedExistingSources}
      existingResourceNames={data?.sources.map((s) => s.metadata.name) ?? []}
      open={addDialogOpen}
      onSaveNew={onNewSourceSave}
      onSaveExisting={addExistingSource}
      onClose={() => setAddDialogOpen(false)}
    />
  );
};

export const AddSourcesSection = memo(AddSourcesComponent);
