import { gql } from "@apollo/client";
import { useFeatureFlagQuery } from "../graphql/generated";

gql`
  query FeatureFlag($flagID: String!) {
    featureEnabled(flagID: $flagID)
  }
`;

export function useFeatureFlag(flagID: string): boolean | undefined {
  const { data } = useFeatureFlagQuery({
    variables: { flagID },
  });
  return data?.featureEnabled;
}
