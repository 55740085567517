import { ApolloError } from "@apollo/client";
import {
  Card,
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  Kind,
  ResourceConfiguration,
  useGetResourceTypeQuery,
  useGetResourceWithTypeQuery,
} from "../../graphql/generated";
import colors from "../../styles/colors";
import { BPResourceConfiguration } from "../../utils/classes";
import { ConfirmDeleteResourceDialog } from "../ConfirmDeleteResourceDialog";
import {
  BookmarkIcon,
  EditIcon,
  MenuIcon,
  ProcessorIcon,
  TrashIcon,
} from "../Icons";
import { getStabilityChip } from "../StabilityChip";
import styles from "./resource-configuration-label-card.module.scss";

interface LabelCardProps {
  index: number;
  item: ResourceConfiguration;
  resourceKind: Kind.Processor | Kind.Extension;
  dragDropRef?: React.RefObject<HTMLDivElement>;
  isHovered?: boolean;
  onEdit: () => void;
  onDelete: () => void;
  readOnly: boolean;
}

export const ResourceConfigurationLabelCard: React.FC<LabelCardProps> = ({
  index,
  resourceKind,
  item,
  dragDropRef,
  isHovered,
  onEdit,
  onDelete,
  readOnly,
}) => {
  const resourceConfig = new BPResourceConfiguration(item);
  const { enqueueSnackbar } = useSnackbar();
  const [resourceTypeDisplayName, setResourceTypeDisplayName] =
    useState<string>("");
  const [resourceNameOrDescription, setResourceNameOrDescription] =
    useState<string>();
  const [resourceTypeStability, setResourceTypeStability] = useState<string>();
  const [icon, setIcon] = useState<string>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  function onError(error: ApolloError) {
    console.error(error);
    enqueueSnackbar("Error retrieving Resource Type", {
      variant: "error",
      key: "Error retrieving Resource Type",
    });
  }

  const resourceType =
    resourceKind === Kind.Processor ? Kind.ProcessorType : Kind.ExtensionType;

  useGetResourceTypeQuery({
    variables: { kind: resourceType, name: resourceConfig.type! },
    skip: !resourceConfig.isInline(),
    onError,
    onCompleted(data) {
      setResourceTypeDisplayName(data.resourceType!.metadata!.displayName!);
      setResourceNameOrDescription(item.displayName ?? "");
      setResourceTypeStability(data.resourceType?.metadata.stability ?? "");
      setIcon(data.resourceType!.metadata!.icon!);
    },
  });

  useGetResourceWithTypeQuery({
    variables: { kind: resourceKind, name: resourceConfig.name! },
    skip: resourceConfig.isInline(),
    onError,
    onCompleted(data) {
      setResourceTypeDisplayName(
        data?.resourceWithType!.resourceType!.metadata!.displayName!,
      );
      setResourceNameOrDescription(
        data?.resourceWithType?.resource?.metadata?.name,
      );
      setResourceTypeStability(
        data.resourceWithType.resourceType?.metadata.stability ?? "",
      );
      setIcon(data.resourceWithType!.resourceType!.metadata!.icon!);
    },
  });

  const labelTitle = `${resourceTypeDisplayName}${resourceNameOrDescription ? `: ${resourceNameOrDescription}` : ""}`;

  return (
    <Stack
      direction={"row"}
      sx={{ width: "100%" }}
      position="relative"
      overflow="visible"
      alignItems={"center"}
    >
      <Card
        variant="outlined"
        ref={dragDropRef}
        style={{
          border: isHovered ? `1px solid ${colors.pixelPointBlue}` : undefined,
          marginRight: "4px",
          width: "100%",
        }}
        classes={{ root: styles.card }}
      >
        <Stack
          direction="row"
          alignItems={"center"}
          spacing={1}
          justifyContent={"space-between"}
        >
          <Stack
            direction={"row"}
            spacing={1}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            width={"100%"}
            alignItems={"center"}
          >
            <MenuIcon className={styles["hover-icon"]} />
            {icon ? (
              <span
                className={styles.icon}
                style={{ backgroundImage: `url(${icon})` }}
              />
            ) : (
              <span className={styles.icon}>
                <ProcessorIcon />
              </span>
            )}
            <Tooltip title={labelTitle}>
              <Typography
                fontWeight={600}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                maxWidth={"95%"}
              >
                {resourceNameOrDescription ? (
                  <>
                    <span className={styles.displayName}>
                      {resourceTypeDisplayName}:{" "}
                    </span>
                    <span className={styles.shortDescription}>
                      {resourceNameOrDescription}
                    </span>
                  </>
                ) : (
                  <span className={styles.displayName}>
                    {resourceTypeDisplayName}
                  </span>
                )}
              </Typography>
            </Tooltip>
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            {getStabilityChip(resourceTypeStability)}
            {resourceConfig.name && (
              <BookmarkIcon
                width={18}
                height={18}
                stroke={colors.pixelPointBlue}
                fill={colors.pixelPointBlue}
                style={{ position: "relative", top: "-13px", right: "-6px" }}
              />
            )}
            <IconButton
              onClick={onEdit}
              data-testid={`edit-${resourceKind.toLowerCase()}-${index}`}
              sx={{ right: "-6px" }}
            >
              <EditIcon width={15} height={15} />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              onClick={() => setDeleteDialogOpen(true)}
              sx={{ height: 32, right: "6px" }}
              disabled={readOnly}
            >
              <TrashIcon width="16px" />
            </IconButton>
          </Stack>
        </Stack>
      </Card>

      <ConfirmDeleteResourceDialog
        onDelete={() => {
          onDelete();
          setDeleteDialogOpen(false);
        }}
        onCancel={() => setDeleteDialogOpen(false)}
        action={"delete"}
        open={deleteDialogOpen}
      >
        <Typography>
          Are you sure you want to delete this{" "}
          {resourceKind === Kind.Processor ? "processor" : "extension"}?
        </Typography>
      </ConfirmDeleteResourceDialog>
    </Stack>
  );
};
