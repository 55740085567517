import { Dialog, Stack } from "@mui/material";
import {
  ResourceConfiguration,
  ProcessorsWithParamsQuery,
  PipelineType,
  Kind,
} from "../../graphql/generated";
import { TitleSection } from "../DialogComponents";
import { ResourceConfigurationEditor } from "../ResourceConfigurationEditor";
import { ResourceDialogContextProvider } from "../ResourceDialog/ResourceDialogContext";
import { SnapshotContextProvider } from "../SnapShotConsole/SnapshotContext";
import { SnapshotSection } from "./SnapshotSection";
import { withCommonProcessorDialog } from "./withCommonProcessorDialog";
import styles from "./processor-dialog.module.scss";

export interface ProcessorDialogComponentProps {
  agentID?: string;
  // closeDialog should be a function that closes the dialog
  closeDialog: () => void;
  configurationName: string;
  description: string;
  dialogOpen: boolean;
  initProcessors: ResourceConfiguration[];
  libraryProcessors: ProcessorsWithParamsQuery["processors"];
  // onClose is the handler that is called when hitting escape or closing
  // the dialog
  onClose: () => void;
  onDelete?: () => Promise<void>;
  onProcessorsChange: (processors: ResourceConfiguration[]) => void;
  onUpdateInlineProcessors(
    processors: ResourceConfiguration[],
  ): Promise<boolean>;
  pipelineType: string;
  processors: ResourceConfiguration[];
  readOnly: boolean;
  refetchConfiguration: () => void;
  refetchLibraryProcessors: () => void;
  resourceIndex: number;
  resourceName: string;
  snapshotID: string;
  telemetryTypes: PipelineType[];
  title: string;
  position: "s0" | "d0" | "p1";
}

export const ProcessorDialogComponent: React.FC<
  ProcessorDialogComponentProps
> = ({
  agentID,
  closeDialog,
  description,
  dialogOpen,
  initProcessors,
  libraryProcessors,
  onClose,
  onDelete,
  onProcessorsChange,
  onUpdateInlineProcessors,
  pipelineType,
  processors,
  readOnly,
  refetchConfiguration,
  refetchLibraryProcessors,
  snapshotID,
  telemetryTypes,
  title,
}) => {
  return (
    <ResourceDialogContextProvider onClose={onClose} purpose={"edit"}>
      <SnapshotContextProvider
        pipelineType={pipelineType as PipelineType}
        snapshotID={snapshotID}
        showAgentSelector
        agentID={agentID}
      >
        <Dialog
          open={dialogOpen}
          maxWidth={"xl"}
          fullWidth
          onClose={onClose}
          classes={{
            root: styles.dialog,
            paper: styles.paper,
          }}
        >
          <Stack height="calc(100vh - 100px)" minHeight="800px">
            <TitleSection
              title={title}
              description={description}
              onClose={onClose}
            />
            <SnapshotSection readOnly={readOnly ?? false}>
              <ResourceConfigurationEditor
                closeDialog={closeDialog}
                initItems={initProcessors}
                items={processors}
                kind={Kind.Processor}
                reusableResources={libraryProcessors}
                refetchReusableResources={refetchLibraryProcessors}
                onItemsChange={onProcessorsChange}
                refetchConfiguration={refetchConfiguration}
                telemetryTypes={telemetryTypes}
                updateInlineItems={onUpdateInlineProcessors}
                readOnly={readOnly}
                onDelete={onDelete}
              />
            </SnapshotSection>
          </Stack>
        </Dialog>
      </SnapshotContextProvider>
    </ResourceDialogContextProvider>
  );
};

export const ProcessorDialog: React.FC = withCommonProcessorDialog(
  ProcessorDialogComponent,
);
