import { Kind } from "../../graphql/generated";
import { ResourceKind } from "../../types/resources";

const ALPHA_NUMERIC_START_END_REGEX = /(([A-Za-z0-9].*)?[A-Za-z0-9])?/;
const LABEL_REGEX = /(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])?/;
const PROTECTED_KEYWORDS = ["new", "new-raw"];

/** validateNameField makes sure that the BindPlane Resource name is a valid selector.  ie.
 * 1. Matches label regex
 * 2. Contains no spaces
 * 3. Must be 63 characters or less
 * 4. If existingResources is passed, it validates that the name is not taken.
 */
export function validateNameField(
  name?: string | null,
  kind?: Kind.Configuration | ResourceKind,
  existingNames?: string[],
): string | null {
  if (name == null || name === "") {
    return "Required.";
  } else {
    // protected keywords
    const p = PROTECTED_KEYWORDS.find(
      (protectedKeyword) =>
        protectedKeyword.toLowerCase() === name.toLowerCase(),
    );
    if (p != null) {
      return `'${name}' matches a protected keyword and cannot be used.`;
    }

    // name must be valid label
    const labelError = validateStringAsBPLabelKey(name);
    if (labelError != null) {
      return labelError;
    }

    // Verify name does not exist already.
    if (existingNames != null) {
      const r = existingNames.find(
        (existingName) => existingName.toLowerCase() === name.toLowerCase(),
      );
      if (r != null) {
        return `A ${kind} named ${name} already exists.`;
      }
    }
  }
  return null;
}

/**
 * validateStringAsBPLabelKey makes sure that the string is a valid BindPlane Label Value or Key.  ie.
 * 1. Matches label regex
 * 2. Contains no spaces
 * 3. Must be 63 characters or less
 *
 * @param str the string to check
 * @returns a friendly error message or undefined if the string is valid
 */
export function validateStringAsBPLabelKey(str: string) {
  // Label Regex
  const match0 = ALPHA_NUMERIC_START_END_REGEX.exec(str);
  if (match0 != null && match0[0] !== str) {
    return "Must begin and end with an alphanumeric character.";
  }

  const match = LABEL_REGEX.exec(str);
  if (match != null && match[0] !== str) {
    return "Invalid character. Can contain alphanumeric characters, dashes ( - ), underscores ( _ ), and dots ( . ).";
  }

  // No Spaces
  if (str.includes(" ")) {
    return "Must not contain spaces.";
  }

  // Length
  if (str.length > 63) {
    return "Must be 63 characters or less.";
  }
}
