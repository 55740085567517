// This file contains camelcased color names for use in styled components.
// It requires more copy-paste, but the typed variable is easier to use in
// ts files.
const colors = {
  // Pixelpoint Primary
  pixelPointBlue: "#0073e5",
  pixelPointBlueHover: "#0050a0", // follows mui button standard
  pixelPointBlueMediumHover: "#0073e54a",
  pixelPointBlueLightHover: "#0073e50a",

  // Pixelpoint Secondary
  palePrim: "#fff699",
  freeSpeechRed: "#cc0000",
  malachite: "#00cc66",

  // Pixelpoint Semitones
  semitoneBlue: "#4da6ff",
  semitonePalePrim: "#fff9b3",
  semitoneTan: "#fcd49c",
  semitoneTanLight: "#fef4e5",
  semitoneTanVeryLight: "#fffbf5",

  // Pixelpoint Grayscale
  black: "#17191c",
  blackShadow: "#17191c14",
  darkestGray: "#2e3138",
  veryDarkGray: "#454a54",
  darkGray: "#5c6370",
  middleDarkGray: "#8f96a3",
  middleGray: "#abb0ba",
  middleLightGray: "#c7cad1",
  lightGray: "#e3e5e8",
  verylightGray: "#eeeff1",
  lightestGray: "#f3f3f6",
  backgroundWhite: "#fafafa",
  white: "#ffffff",

  // not Pixelpoint
  gradientGray: "#d9d9d9", // only used for a few gradients, which match our website.
  disabled: "#00000061", // not a global standard. mui defaults are used for buttons, and grayscale are used on resource cards. this affects a few input params.
  // status page colors
  lightGreen: "#00CC66",
  lightBlue: "#4DA6FF",
  // Web-only colors
  neonCarrot: "#ffa729",
  // Error colors
  muiErrorDark: "#d32f2f",
};

export default colors;
