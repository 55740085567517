import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { isFunction } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HelpCircleIcon } from "../../../components/Icons";
import { Kind, useGetConfigNamesLazyQuery } from "../../../graphql/generated";
import colors from "../../../styles/colors";
import { validateNameField } from "../../../utils/forms/validate-name-field";
import { copyConfig } from "../../../utils/rest/copy-config";

interface Props extends DialogProps {
  currentConfigName?: string;
  latestConfigName?: string;
  onSuccess: () => void;
}

type Version = "current" | "latest";

export const DuplicateConfigDialog: React.FC<Props> = ({
  currentConfigName,
  latestConfigName,
  onSuccess,
  ...dialogProps
}) => {
  const [version, setVersion] = useState<Version>(
    latestConfigName ? "latest" : "current",
  );

  const [newName, setNewName] = useState("");
  const [touched, setTouched] = useState(false);
  const [existingConfigNames, setExistingConfigNames] = useState<string[]>([]);

  const [fetchConfigNames] = useGetConfigNamesLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setExistingConfigNames(
        data.configurations.configurations.map((c) => c.metadata.name),
      );
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar("Error retrieving config names.", {
        variant: "error",
      });
    },
  });

  const formError = validateNameField(
    newName,
    Kind.Configuration,
    existingConfigNames,
  );

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  function clearState() {
    setTouched(false);
    setNewName("");
  }

  useEffect(() => {
    if (dialogProps.open) {
      fetchConfigNames();
    }
  }, [dialogProps.open, fetchConfigNames]);

  async function handleSave(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const status = await copyConfig({
      existingName:
        version === "latest" ? latestConfigName! : currentConfigName!,
      newName: newName,
    });

    let message: string;
    switch (status) {
      case "conflict":
        message = "Looks like a configuration with that name already exists.";
        enqueueSnackbar(message, { key: message, variant: "warning" });
        break;
      case "error":
        message = "Oops, something went wrong. Failed to duplicate.";
        enqueueSnackbar(message, { key: message, variant: "error" });
        break;
      case "created":
        message = "Successfully duplicated!";
        onSuccess();
        enqueueSnackbar(message, { key: message, variant: "success" });
        navigate(`/configurations/${newName}`);
        break;
    }
  }

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    if (!touched) {
      setTouched(true);
    }
    setNewName(e.target.value);
  }

  return (
    <Dialog
      {...dialogProps}
      TransitionProps={{
        onExited: clearState,
      }}
    >
      <DialogContent>
        <Typography variant="h6" marginBottom={2}>
          Duplicate Configuration
        </Typography>
        <Typography>
          Clicking save will create a new Configuration with identical sources
          and destinations.
        </Typography>
        <form onSubmit={handleSave}>
          <TextField
            value={newName}
            autoComplete="off"
            onChange={handleChange}
            size="small"
            label="Name"
            helperText={touched && formError ? formError : undefined}
            name="name"
            fullWidth
            error={touched && formError != null}
            margin="normal"
            required
            onBlur={() => setTouched(true)}
            autoFocus
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
            marginTop="8px"
          >
            {currentConfigName && latestConfigName ? (
              <Stack direction="row" spacing={1} alignItems={"center"}>
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  onChange={(_, v) => setVersion(v)}
                  value={version}
                  size="small"
                >
                  <ToggleButton value={"current"}>Live</ToggleButton>
                  <ToggleButton value={"latest"}>Draft</ToggleButton>
                </ToggleButtonGroup>
                <Tooltip
                  title={`"Live" is the last configuration that has been successfully rolled out to agents, "Draft" is the version with the most recent edits`}
                >
                  <span>
                    <HelpCircleIcon color={colors.middleDarkGray} />
                  </span>
                </Tooltip>
              </Stack>
            ) : (
              <div />
            )}
            <Stack direction="row" spacing={1}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  isFunction(dialogProps.onClose) &&
                    dialogProps.onClose({}, "backdropClick");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={formError != null}
                type="submit"
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};
