import {
  Stack,
  Accordion,
  AccordionSummary,
  IconButton,
  FormLabel,
  AccordionDetails,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { clone, isEqual } from "lodash";
import { useState } from "react";
import { MapParamInput, StringParamInput } from ".";
import { ParameterType } from "../../../graphql/generated";
import colors from "../../../styles/colors";
import { ChevronDown, ChevronUp, EditIcon, TrashIcon } from "../../Icons";
import { useValidationContext } from "../ValidationContext";
import { validateGoogleSecOpsStandardizationField } from "../validation-functions";
import {
  ConditionMatch,
  ConditionInputValue,
  ConditionInput,
} from "./ConditionInput";
import { ParamInputProps } from "./ParameterInput";
import { parameterErrors } from "./utils";

export type GoogleSecOpsStandardizationValue = GoogleSecOpsStandardization[];

interface GoogleSecOpsStandardization {
  condition: ConditionInputValue | string;
  logType: string;
  namespace: string;
  ingestionLabels: Record<string, string>;
}

const EMPTY_ITEM: GoogleSecOpsStandardization = {
  condition: {
    ottl: "",
    ui: {
      operator: "",
      statements: [
        {
          match: ConditionMatch.ATTRIBUTES,
          key: "",
          operator: "Equals",
          value: "",
        },
      ],
    },
  },
  logType: "New Log Type",
  namespace: "",
  ingestionLabels: {},
};

const EMPTY_VALUE: GoogleSecOpsStandardizationValue = [];

export const GoogleSecOpsStandardizationInput: React.FC<
  ParamInputProps<GoogleSecOpsStandardizationValue>
> = ({ definition, value: paramValue, readOnly, onValueChange }) => {
  const initValue =
    paramValue != null && paramValue.length > 0 ? paramValue : EMPTY_VALUE;
  const [controlValue, setControlValue] =
    useState<GoogleSecOpsStandardizationValue>(initValue);
  const [expanded, setExpanded] = useState<string | false>();

  const { errors, touched, touch, setError } = useValidationContext();

  function handleValueChange(newValue: GoogleSecOpsStandardizationValue) {
    setError(
      definition.name,
      validateGoogleSecOpsStandardizationField(definition, newValue),
    );
    if (isEqual(newValue, EMPTY_VALUE)) {
      onValueChange && onValueChange([]);
    } else {
      onValueChange && onValueChange(newValue);
    }
  }

  function handleParameterValueChange(index: number, key: string, value: any) {
    const item: GoogleSecOpsStandardization = clone(controlValue[index]);
    if (key in item) {
      (item as any)[key] = value;
    }

    const newControlValue = [...controlValue];
    newControlValue[index] = item;
    setControlValue(newControlValue);
    onValueChange && onValueChange(newControlValue);

    setError(
      definition.name,
      validateGoogleSecOpsStandardizationField(definition, newControlValue),
    );

    if (!touched[definition.name]) {
      touch(definition.name);
    }
  }

  function handleAddLogTypeClick() {
    const newValue = [...controlValue, EMPTY_ITEM];
    setControlValue(newValue);
    handleValueChange(newValue);
    setError(definition.name, null);
    const index = newValue.length - 1;
    touch(`sec-ops-${index}-log-type`);
    touch(`sec-ops-${index}-namespace`);
    setError(`sec-ops-${index}-log-type`, null);
    setError(`sec-ops-${index}-namespace`, null);

    setExpanded("m-" + index);
  }

  function handleDeleteLogTypeClick(index: number) {
    const newValue = [...controlValue];
    newValue.splice(index, 1);
    setControlValue(newValue);
    handleValueChange(newValue);
    setError(definition.name, null);
    setError(`sec-ops-${index}-log-type`, null);
    setError(`sec-ops-${index}-namespace`, null);

    // close accordion if open on index
    if (expanded) {
      const parts = expanded.split("-");
      if (parts.length === 2 && Number(parts[1]) === index) {
        setExpanded(false);
      } else if (parts.length === 2 && Number(parts[1]) > index) {
        // need to decrement 'expanded' to keep it open
        setExpanded("m-" + (Number(parts[1]) - 1));
      }
    }
  }

  function handleAccordionChange(acc: string) {
    if (expanded === acc) {
      setExpanded(false);
    } else {
      setExpanded(acc);
    }
  }

  return (
    <Stack spacing={1}>
      {/* form title */}
      <Stack flexGrow={1}>
        <FormLabel filled required={definition.required}>
          {definition.label}
        </FormLabel>
      </Stack>
      {controlValue.map((item, index) => (
        <div key={`sec-ops-${index}-item-container`}>
          <Accordion
            sx={{
              border: 1,
              borderColor: colors.middleLightGray,
            }}
            expanded={expanded === `m-${index}`}
            disableGutters
          >
            <AccordionSummary>
              <Stack
                direction="row"
                justifyContent={"center"}
                alignItems={"center"}
                spacing={"auto"}
                width={"100%"}
              >
                <Typography>{item.logType}</Typography>
                <Stack direction={"row"} spacing={"10px"} alignItems={"center"}>
                  <IconButton
                    size={"small"}
                    onClick={() => handleAccordionChange(`m-${index}`)}
                    sx={{
                      width: "28px",
                      height: "28px",
                      cursor: "pointer",
                    }}
                  >
                    {expanded === `m-${index}` ? (
                      <ChevronUp />
                    ) : readOnly ? (
                      <ChevronDown />
                    ) : (
                      <EditIcon />
                    )}
                  </IconButton>
                  <IconButton
                    size={"small"}
                    onClick={() => handleDeleteLogTypeClick(index)}
                    disabled={readOnly}
                    sx={{
                      width: "28px",
                      height: "28px",
                      cursor: "pointer",
                    }}
                  >
                    <TrashIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack padding={"10px"} spacing={2}>
                {/* condition */}
                <Box paddingBottom={"20px"}>
                  <ConditionInput
                    value={item.condition}
                    onValueChange={(v) =>
                      handleParameterValueChange(index, "condition", v)
                    }
                    readOnly={readOnly}
                    definition={{
                      description:
                        "An OTTL condition that must evaluate to true to apply this log type. By default, the processor applies to all logs.",
                      label: "Condition",
                      name: `sec-ops-${index}-condition`,
                      options: {},
                      required: false,
                      type: ParameterType.Condition,
                    }}
                  />
                </Box>
                {/* Log Type*/}
                <div>{parameterErrors(definition, errors, touched)}</div>
                <Stack direction={"row"} spacing={2}>
                  <StringParamInput
                    value={item.logType}
                    onValueChange={(v) =>
                      handleParameterValueChange(index, "logType", v)
                    }
                    readOnly={readOnly}
                    definition={{
                      __typename: undefined,
                      description: "Type of log to be sent to Google SecOps.",
                      documentation: definition.documentation,
                      label: "Log Type *",
                      name: `sec-ops-${index}-log-type`,
                      required: false,
                      type: ParameterType.String,
                      options: {},
                    }}
                  />
                </Stack>
                {/* Namespace */}
                <Stack direction={"row"} spacing={2}>
                  <StringParamInput
                    value={item.namespace}
                    onValueChange={(v) =>
                      handleParameterValueChange(index, "namespace", v)
                    }
                    readOnly={readOnly}
                    definition={{
                      __typename: undefined,
                      description:
                        "User-configured environment namespace to identify the data domain the logs originated from.",
                      label: "Namespace",
                      name: `sec-ops-${index}-namespace`,
                      required: false,
                      type: ParameterType.String,
                      options: {},
                    }}
                  />
                </Stack>
                {/* Ingestion Labels */}
                <Stack direction={"row"} spacing={2}>
                  <Box width={"100%"}>
                    <MapParamInput
                      value={item.ingestionLabels}
                      onValueChange={(v) =>
                        handleParameterValueChange(index, "ingestionLabels", v)
                      }
                      readOnly={readOnly}
                      definition={{
                        __typename: undefined,
                        default: {},
                        description: "",
                        label: "Ingestion Labels",
                        name: `sec-ops-${index}-ingestion-labels`,
                        options: {
                          labels: {
                            key: "Key",
                            value: "Value",
                          },
                        },
                        required: false,
                        type: ParameterType.Map,
                      }}
                    />
                  </Box>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}

      {/* new log type button */}
      {!readOnly && (
        <Stack width="100%">
          <Button variant="outlined" onClick={handleAddLogTypeClick}>
            Add Log Type
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
