import { NodeProps } from "reactflow";
import { ConnectorCard } from "../../Cards/ConnectorCard";
import { useBPGraph } from "../BPGraphProvider";
import { RoutingNodeWrapper } from "../RoutingContext/RoutingNodeWrapper";
import { AttributeName, V2NodeData } from "../types";

export const ConnectorNode: React.FC<NodeProps<V2NodeData>> = ({
  data,
  id,
  type,
  xPos,
  yPos,
}) => {
  const { onMouseEnterNode, onMouseExitNode } = useBPGraph();
  const { attributes } = data;

  return (
    <RoutingNodeWrapper
      componentType={"connectors"}
      componentPath={attributes[AttributeName.ComponentPath]}
      nodeType={type}
      nodeID={id}
      xPos={xPos}
      yPos={yPos}
    >
      <div
        onMouseEnter={() => onMouseEnterNode(id)}
        onMouseLeave={onMouseExitNode}
      >
        <ConnectorCard label={id} />
      </div>
    </RoutingNodeWrapper>
  );
};
