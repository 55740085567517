import { Box, Card, CardActionArea, Stack, Typography } from "@mui/material";
import { Handle, Position } from "reactflow";
import { classes } from "../../utils/styles";
import { NodeId } from "../ConfigurationFlowV2/graph";
import { CountChip } from "../CountChip";
import { ProcessorIcon } from "../Icons";
import { useGraphMetrics } from "../PipelineGraphV2/GraphMetricsProvider";
import styles from "./cards.module.scss";

interface ProcessorCardProps {
  nodeId?: NodeId;
  onClick?: () => void;
  processorCount: number;
  recommendation?: boolean;
}

export const ProcessorCardV2: React.FC<ProcessorCardProps> = ({
  nodeId,
  onClick,
  processorCount,
  recommendation,
}) => {
  const graphMetrics = useGraphMetrics();

  let outboundPercentage = 0;
  let outboundMetricFormatted = "";
  let outboundPercentageFormatted: any = "";

  if (nodeId != null) {
    outboundPercentage = graphMetrics?.getOutboundMetricPercentage(nodeId) ?? 0;
    outboundMetricFormatted =
      graphMetrics?.getOutboundMetricFormatted(nodeId) ?? "";
    outboundPercentageFormatted =
      graphMetrics?.getOutboundMetricPercentageFormatted(nodeId) ?? "";
  }

  return (
    <Box className={recommendation ? styles["processor-recommendation"] : ""}>
      <Card
        className={
          styles[
            nodeId != null ? "processor-card-v2" : "dummy-processor-card-v2"
          ]
        }
        onClick={onClick}
      >
        <CardActionArea
          className={classes([
            styles.processorActionV2,
            onClick == null ? styles.cursorDefault : undefined,
          ])}
          disabled={onClick == null}
        >
          <Handle type="source" position={Position.Right} style={{ top: 35 }} />
          <Handle type="target" position={Position.Left} style={{ top: 35 }} />
          <Stack
            width="100%"
            height="100%"
            justifyContent="left"
            alignItems="left"
            direction={"row"}
          >
            <ProcessorIcon
              width={45}
              height={45}
              style={
                {
                  marginLeft: 12,
                  marginTop: 12,
                } as React.CSSProperties
              }
            />
            {nodeId != null && (
              <Stack
                className={styles["bar-graph-background"]}
                style={
                  {
                    "--percentage": `${outboundPercentage}%`,
                  } as React.CSSProperties
                }
                alignItems={"center"}
                justifyContent={"center"}
                direction={"column"}
              >
                <Typography className={styles["router-connector-throughput"]}>
                  {outboundMetricFormatted}
                </Typography>
                {outboundPercentageFormatted}
              </Stack>
            )}
          </Stack>
        </CardActionArea>
      </Card>
      {processorCount > 0 && (
        <CountChip className={styles["count-chip-v2"]} count={processorCount} />
      )}
    </Box>
  );
};
