import {
  Card,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import colors from "../../styles/colors";
import styles from "./number-chip.module.scss";

interface NumberChipProps {
  label: string;
  value?: number;
  loading?: boolean;
  color?: TypographyProps["color"];
  onClick?: () => void;
}

/**
 * NumberChip displays a label and a number in a box.
 * The color prop is only used if the number is greater than zero.
 */
export const NumberChip: React.FC<NumberChipProps> = ({
  label,
  value,
  loading,
  color,
  onClick,
}) => {
  return (
    <Stack width={116} justifyContent="center" alignItems="center">
      <Card classes={{ root: styles.card }} role="button" onClick={onClick}>
        <Stack className={styles.cardContent}>
          {loading || value == null ? (
            <CircularProgress size={24} />
          ) : (
            <div>
              <Typography
                className={styles.cardText}
                fontSize={fontSize(value)}
              >
                {value}
              </Typography>
              <Stack>
                <Divider />
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography
                    className={styles.cardText}
                    fontSize={14}
                    color={value > 0 ? color : "default"}
                  >
                    •
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={12}
                    noWrap
                    color={colors.darkGray}
                  >
                    {label}
                  </Typography>
                </Stack>
              </Stack>
            </div>
          )}
        </Stack>
      </Card>
    </Stack>
  );
};

function fontSize(value: number): number {
  const length = Math.log10(value);
  if (length <= 4) {
    return 22;
  } else if (length <= 5) {
    return 20;
  } else {
    return 16;
  }
}
