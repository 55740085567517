import { DialogResource, ResourceType } from ".";
import { AdditionalInfo, Kind } from "../../graphql/generated";
import { ResourceKind, Stability } from "../../types/resources";
import { FormValues, ResourceConfigurationView } from "../ResourceConfigForm";

interface ConfigureViewProps {
  selected: ResourceType;
  kind: ResourceKind;
  createNew: boolean;
  clearResource: () => void;
  handleSaveNew: (
    formValues: FormValues,
    selected: ResourceType,
    type?: string,
  ) => void;
  resources: DialogResource[];
  existingResourceNames: string[];
  additionalInfo?: AdditionalInfo | null;
  resourceDocLink?: string;
  fromLibrary?: boolean;
}

export const ConfigureView: React.FC<ConfigureViewProps> = ({
  selected,
  kind,
  createNew,
  clearResource,
  handleSaveNew,
  existingResourceNames,
  additionalInfo,
  resourceDocLink,
  fromLibrary,
}) => {
  if (selected === null) {
    return <></>;
  }

  return (
    <ResourceConfigurationView
      kind={kind}
      includeNameField={kind === Kind.Destination && createNew && !fromLibrary}
      includeDisplayNameField={kind === Kind.Source}
      existingResourceNames={existingResourceNames}
      onBack={clearResource}
      onSave={(fv, type) => {
        handleSaveNew(fv, selected, type);
      }}
      resourceType={selected.metadata.name}
      resourceTypeDisplayName={selected.metadata.displayName ?? ""}
      description={selected.metadata.description ?? ""}
      parameterDefinitions={selected.spec.parameters ?? []}
      additionalInfo={additionalInfo}
      resourceDocLink={resourceDocLink}
      stability={selected?.metadata?.stability || Stability.UNKNOWN}
    />
  );
};
