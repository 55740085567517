/**
 * Error thrown when the server returns a 404 for the install command
 *
 * It indicates that either offline agent upgrades are misconfigured, agent version syncing has failed, or
 * the agent version is not available for the platform.
 * @extends Error
 */
export class NoAgentVersionsError extends Error {
  constructor() {
    super("No agent versions found");
  }
}
