import { Card, Chip, Stack, Typography } from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { DataPoint, PipelineType } from "../../../graphql/generated";
import { useSnapshot } from "../SnapshotContext";
import { SnapshotRegion } from "../regions";
import {
  DataPointValueType,
  FieldType,
  MetricDataType,
  dataPointTypeEnumSymbol,
} from "../types";
import { flattenFields, getTimestamp } from "../utils";
import { DetailsContainer } from "./DetailsContainer";
import { FieldRow } from "./FieldRow";
import { MapValueSummary } from "./MapValueSummary";
import { RowSummary } from "./RowSummary";
import { AttributesProvider } from "./SnapShotRow";
import { SummaryTable } from "./SummaryTable";
import { Toggle } from "./Toggle";
import {
  highlightSearchQuery,
  useCombinedRefs,
  useWatchForOpen,
} from "./utils";
import styles from "../snap-shot-console.module.scss";

interface MetricsRecordRowProps {
  filtered?: boolean;
  message: DataPoint;
  attributes: AttributesProvider;
  bindplaneID: string;
}

export const MetricsRecordRow: React.FC<MetricsRecordRowProps> = ({
  filtered,
  message,
  attributes,
  bindplaneID,
}) => {
  const timestamp = useMemo(
    () => getTimestamp(message, PipelineType.Logs),
    [message],
  );

  // value could be an object for type Summary metrics
  const { searchRegex } = useSnapshot();

  const stringifiedValue = useMemo(() => {
    switch (message.valueType) {
      case DataPointValueType.Double:
        return message.valueDouble;
      case DataPointValueType.Int:
        return message.valueInt;
    }
    switch (message.type) {
      case MetricDataType.METRIC_DATA_TYPE_SUMMARY:
        return JSON.stringify(message.quantileValues);
      case MetricDataType.METRIC_DATA_TYPE_HISTOGRAM:
        return JSON.stringify(message.fields?.["bucket_counts"]);
      case MetricDataType.METRIC_DATA_TYPE_EXPONENTIAL_HISTOGRAM:
        return JSON.stringify(message.fields?.["positive.bucket_counts"]);
    }
  }, [message]);

  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  useWatchForOpen(ref, setOpen);

  const { ref: viewRef, inView } = useInView({ threshold: 0.1 });
  const combinedRef = useCombinedRefs<HTMLDivElement>(ref, viewRef);

  return (
    <Card
      classes={{ root: styles.card }}
      data-region={SnapshotRegion.ROW}
      data-row-id={bindplaneID}
      ref={combinedRef}
    >
      {inView ? (
        <RowSummary
          filtered={filtered}
          bindplaneID={bindplaneID}
          timestamp={timestamp}
          data-region={SnapshotRegion.ROW_SUMMARY}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Chip
              className={styles["metric-name"]}
              label={highlightSearchQuery(message.name, searchRegex)}
              size={"small"}
              data-region={SnapshotRegion.ROW_NAME}
              data-name={message.name}
            />

            <Typography
              fontFamily="monospace"
              fontSize={12}
              overflow={"hidden"}
              textOverflow="ellipsis"
            >
              {stringifiedValue} {message.unit}
            </Typography>
          </Stack>
        </RowSummary>
      ) : (
        <div
          className={styles.ch}
          data-region={SnapshotRegion.EXPANDER}
          data-bindplane-id={bindplaneID}
        >
          <Toggle bindplaneID={bindplaneID} />
        </div>
      )}
      {open && (
        <DetailsContainer filtered={filtered}>
          <Typography fontWeight={600}>Data Point</Typography>
          <SummaryTable>
            <FieldRow
              name="time"
              value={timestamp}
              fieldType={FieldType.DataPoint}
            />
            {message.valueType === DataPointValueType.Int && (
              <FieldRow
                name="value_int"
                value={message.valueInt}
                fieldType={FieldType.DataPoint}
              />
            )}
            {message.valueType === DataPointValueType.Double && (
              <FieldRow
                name="value_double"
                value={message.valueDouble}
                fieldType={FieldType.DataPoint}
              />
            )}
            {message.quantileValues && (
              <FieldRow
                name="quantile_values"
                value={JSON.stringify(message.quantileValues)}
                fieldType={FieldType.DataPoint}
              />
            )}
            {message.type === MetricDataType.METRIC_DATA_TYPE_HISTOGRAM && (
              <>
                <FieldRow
                  name="explicit_bounds"
                  value={message.fields?.["explicit_bounds"]}
                  fieldType={FieldType.DataPoint}
                />
                <FieldRow
                  name="bucket_counts"
                  value={message.fields?.["bucket_counts"]}
                  fieldType={FieldType.DataPoint}
                />
              </>
            )}
            {message.type ===
              MetricDataType.METRIC_DATA_TYPE_EXPONENTIAL_HISTOGRAM && (
              <>
                <FieldRow
                  name="positive.offset"
                  value={message.fields?.["positive.offset"]}
                  fieldType={FieldType.DataPoint}
                />
                <FieldRow
                  name="positive.bucket_counts"
                  value={message.fields?.["positive.bucket_counts"]}
                  fieldType={FieldType.DataPoint}
                />
                <FieldRow
                  name="negative.offset"
                  value={message.fields?.["negative.offset"]}
                  fieldType={FieldType.DataPoint}
                />
                <FieldRow
                  name="negative.bucket_counts"
                  value={message.fields?.["negative.bucket_counts"]}
                  fieldType={FieldType.DataPoint}
                />
              </>
            )}
            {[
              MetricDataType.METRIC_DATA_TYPE_SUMMARY,
              MetricDataType.METRIC_DATA_TYPE_HISTOGRAM,
              MetricDataType.METRIC_DATA_TYPE_EXPONENTIAL_HISTOGRAM,
            ].includes(message.type) && (
              <>
                <FieldRow
                  name="count"
                  value={message.fields?.count}
                  fieldType={FieldType.DataPoint}
                />
                <FieldRow
                  name="sum"
                  value={message.fields?.sum}
                  fieldType={FieldType.DataPoint}
                />
              </>
            )}
          </SummaryTable>

          <Typography fontWeight={600} marginTop={2}>
            Attributes
          </Typography>
          <MapValueSummary
            value={flattenFields(attributes())}
            fieldType={FieldType.Attribute}
            emptyMessage="No attribute values"
          />

          <Typography fontWeight={600} marginTop={2}>
            Metric
          </Typography>
          <SummaryTable>
            <FieldRow
              name="name"
              value={message.name}
              fieldType={FieldType.Metric}
            />
            <FieldRow
              name="description"
              value={message.description}
              fieldType={FieldType.Metric}
            />
            <FieldRow
              name="type"
              value={dataPointTypeEnumSymbol(message.type)}
              fieldType={FieldType.Metric}
            />
            <FieldRow
              name="unit"
              value={message.unit}
              fieldType={FieldType.Metric}
            />
            {[
              MetricDataType.METRIC_DATA_TYPE_SUM,
              MetricDataType.METRIC_DATA_TYPE_HISTOGRAM,
              MetricDataType.METRIC_DATA_TYPE_EXPONENTIAL_HISTOGRAM,
            ].includes(message.type) && (
              <FieldRow
                name="aggregation_temporality"
                value={message.fields?.["aggregation_temporality"]}
                fieldType={FieldType.Metric}
              />
            )}
            {message.type === MetricDataType.METRIC_DATA_TYPE_SUM && (
              <FieldRow
                name="is_monotonic"
                value={message.fields?.["is_monotonic"]}
                fieldType={FieldType.Metric}
              />
            )}
          </SummaryTable>

          <Typography fontWeight={600} marginTop={2}>
            Resource
          </Typography>
          <MapValueSummary
            value={flattenFields(message.resource)}
            fieldType={FieldType.Resource}
            emptyMessage="No resource values"
          />
        </DetailsContainer>
      )}
    </Card>
  );
};
