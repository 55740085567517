import { useSnackbar } from "notistack";
import { createContext, useContext, useState } from "react";
import {
  Kind,
  useConfigurationCountLazyQuery,
} from "../../../graphql/generated";
import { ResourceKind } from "../../../types/resources";
import { trimVersion } from "../../../utils/version-helpers";
import { EditResourceInUseWarningDialog } from "../EditResourceInUseWarningDialog/EditResourceInUseWarningDialog";
import { DialogAction } from "./utils";

export interface ConfirmResourceInUseWarningAction
  extends DialogAction<undefined> {
  kind: ResourceKind;
  name: string;
}

export interface ConfirmResourceInUseWarningDialogContextValue {
  confirmResourceInUseWarning: (
    action: ConfirmResourceInUseWarningAction,
  ) => void;
}

const defaultValue: ConfirmResourceInUseWarningDialogContextValue = {
  confirmResourceInUseWarning: () => {
    throw new Error("ConfirmResourceInUseWarningDialogProvider not specified");
  },
};

export const ConfirmResourceInUseWarningDialogContext =
  createContext(defaultValue);

export interface ConfirmResourceInUseWarningDialogProviderProps {}

export const ConfirmResourceInUseWarningDialogProvider: React.FC<
  ConfirmResourceInUseWarningDialogProviderProps
> = ({ children }) => {
  const [action, setAction] = useState<
    ConfirmResourceInUseWarningAction | undefined
  >();
  const [open, setOpen] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const [fetchConfigurationCount, { data: configurationCount }] =
    useConfigurationCountLazyQuery({
      fetchPolicy: "network-only",
      onError(error) {
        const kind = action?.kind ?? Kind.Unknown;
        const name = trimVersion(action?.name ?? "");
        console.error(error);
        enqueueSnackbar(
          `Failed to get configuration count for ${kind} ${name}`,
          {
            variant: "error",
          },
        );
      },
      onCompleted(data) {
        if (data.configurationCount <= 1) {
          // less than one configuration is using this resource, so skip the confirmation
          action?.onSuccess(undefined);
          setAction(undefined);
          return;
        }
        setOpen(true);
      },
    });

  // ----------------------------------------------------------------------

  function confirmResourceInUseWarning(
    action: ConfirmResourceInUseWarningAction,
  ) {
    const kind = action?.kind ?? Kind.Unknown;
    const name = trimVersion(action?.name ?? "");
    fetchConfigurationCount({
      variables: {
        query: `${kind}:${name}`,
      },
    });
    setAction(action);
  }

  function onClose() {
    action?.onCancel?.();
    setAction(undefined);
    setOpen(false);
  }

  function onConfirm() {
    action?.onSuccess?.(undefined);
    setAction(undefined);
    setOpen(false);
  }

  // ----------------------------------------------------------------------

  return (
    <ConfirmResourceInUseWarningDialogContext.Provider
      value={{ confirmResourceInUseWarning }}
    >
      <EditResourceInUseWarningDialog
        resourceName={trimVersion(action?.name ?? "")}
        resourceKind={action?.kind ?? Kind.Unknown}
        configurationCount={configurationCount?.configurationCount!}
        open={open}
        onCancel={onClose}
        onConfirm={onConfirm}
      />

      {children}
    </ConfirmResourceInUseWarningDialogContext.Provider>
  );
};

/**
 * Provides a confirmResourceInUseWarning function that will display a confirmation dialog
 * showing the number of configurations using a resource. If there is 0 or 1 configuration
 * using the resource, no confirmation is displayed.
 */
export function useConfirmResourceInUseWarningDialog() {
  return useContext(ConfirmResourceInUseWarningDialogContext);
}
