import { gql } from "@apollo/client";
import { Button, Card, Stack, Tooltip, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { AssistedWizardFormValues } from ".";
import { ConfirmDeleteResourceDialog } from "../../../../components/ConfirmDeleteResourceDialog";
import { BookmarkIcon, PlusCircleIcon } from "../../../../components/Icons";
import {
  DialogResource,
  NewResourceDialog,
  ResourceType,
} from "../../../../components/ResourceDialog";
import { EditResourceDialog } from "../../../../components/ResourceDialog/EditResourceDialog";
import { EditSourceDialog } from "../../../../components/Tables/SourceTable/EditSourceDialog";
import { useWizard } from "../../../../components/Wizard/WizardContext";
import {
  Kind,
  ResourceConfiguration,
  SourceType,
  useSourcesAndTypesQuery,
} from "../../../../graphql/generated";
import colors from "../../../../styles/colors";
import { BPResourceConfiguration } from "../../../../utils/classes/resource-configuration";
import { trimVersion } from "../../../../utils/version-helpers";
import mixins from "../../../../styles/mixins.module.scss";
import styles from "./assisted-config-wizard.module.scss";

gql`
  query sourcesAndTypes {
    sourceTypes {
      apiVersion
      kind
      metadata {
        id
        name
        version
        displayName
        description
        icon
        additionalInfo {
          message
          documentation {
            text
            url
          }
        }
        resourceDocLink
      }
      spec {
        parameters {
          name
          label
          description
          relevantIf {
            name
            operator
            value
          }
          documentation {
            text
            url
          }
          advancedConfig
          required
          type
          validValues
          default
          options {
            creatable
            multiline
            trackUnchecked
            sectionHeader
            subHeader
            horizontalDivider
            gridColumns
            labels
            metricCategories {
              label
              column
              metrics {
                name
                description
                kpi
              }
            }
            password
            processorContext
            sensitive
            variant
          }
        }
        supportedPlatforms
        version
        telemetryTypes
      }
    }
    sources {
      metadata {
        id
        version
        name
      }
      spec {
        type
        parameters {
          name
          value
        }
        disabled
      }
      usage {
        configurationCount
      }
    }
  }
`;

export const StepTwo: React.FC = (props) => {
  const { formValues, setValues, goToStep } =
    useWizard<AssistedWizardFormValues>();

  const [open, setOpen] = useState(false);
  const [editingSourceIx, setEditingSourceIx] = useState(-1);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { data } = useSourcesAndTypesQuery({
    onError(err) {
      console.error(err);
      enqueueSnackbar(
        "There was an error retrieving Sources and SourceTypes.",
        {
          variant: "error",
        },
      );
    },
  });

  function onSave(values: { [name: string]: any }, sourceType: ResourceType) {
    const sourceConfig = new BPResourceConfiguration();
    sourceConfig.setParamsFromMap(values);
    sourceConfig.type = sourceType.metadata.name;

    const sources = [...formValues.sources, sourceConfig];
    setValues({ sources: sources });
    setOpen(false);
  }

  function onEditSourceSave(values: { [key: string]: any }) {
    const newSource = new BPResourceConfiguration(
      formValues.sources[editingSourceIx],
    );

    // Replace the parameters with edited values
    newSource.setParamsFromMap(values);

    const newSources = [...formValues.sources];
    newSources[editingSourceIx] = newSource;

    setValues({ sources: newSources });
    setEditModalOpen(false);
    setEditingSourceIx(-1);
  }

  function deleteSelectedSource() {
    const newSources = [...formValues.sources];
    newSources.splice(editingSourceIx, 1);

    setValues({ sources: newSources });
  }

  function onSourceRemove() {
    setRemoveModalOpen(false);
    setEditModalOpen(false);
    deleteSelectedSource();
    setEditingSourceIx(-1);
  }

  function renderSourceAccordion(
    s: ResourceConfiguration,
    index: number,
  ): JSX.Element {
    let type: string;
    if (s.name) {
      type =
        data?.sources?.find((source) => {
          return s.name === source.metadata.name;
        })?.spec.type ?? "";
    } else {
      type = s.type ?? "";
    }

    const sourceType = data?.sourceTypes.find((st: SourceType) => {
      return st.metadata.name === trimVersion(type);
    });

    if (sourceType == null) {
      // TODO (dsvanlani) error toast and exit
      console.error(
        `sourceType not found for source ${s.name} of type ${s.type}`,
      );
      return <></>;
    }
    const displayName = sourceType.metadata.displayName;
    const icon = sourceType.metadata.icon;

    const resourceNameOrDescription = s.name
      ? (s.name ?? "")
      : (s.displayName ?? "");

    const labelTitle = `${displayName ? displayName : ""}${resourceNameOrDescription ? `: ${resourceNameOrDescription}` : ""}`;

    return (
      <Card
        style={{ marginTop: "4px" }}
        key={`step-two-source-card-${index}-${s.type}`}
      >
        <Stack
          data-testid={`step-two-source-card-${index}`}
          padding={"12px 16px"}
          minHeight={"48px"}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            alignContent={"space-between"}
            spacing={1}
            position={"relative"}
          >
            <span
              className={styles.icon}
              style={{ backgroundImage: `url(${icon})` }}
            />
            <Tooltip title={labelTitle}>
              <Typography
                fontWeight={600}
                maxWidth={"65%"}
                overflow={"hidden"}
                whiteSpace={"nowrap"}
                textOverflow={"ellipsis"}
              >
                {labelTitle}
              </Typography>
            </Tooltip>
            <Stack
              direction="row"
              style={{ position: "absolute", right: "0px" }}
            >
              {s.name && (
                <BookmarkIcon
                  width={18}
                  height={18}
                  stroke={colors.pixelPointBlue}
                  fill={colors.pixelPointBlue}
                  style={{ position: "relative", top: "-17px", left: "2px" }}
                />
              )}
              <Button
                onClick={() => {
                  setEditingSourceIx(index);
                  setEditModalOpen(true);
                }}
              >
                {s.name ? "View" : "Edit"}
              </Button>
              <Button
                color="error"
                onClick={() => {
                  setEditingSourceIx(index);
                  setRemoveModalOpen(true);
                }}
              >
                Remove
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    );
  }

  function openResourceDialog() {
    setOpen(true);
  }

  const editingSourceType = findSourceType(
    formValues.sources[editingSourceIx]?.type,
    data?.sourceTypes,
  );

  function onChooseExistingSource(resource: DialogResource) {
    const sourceConfig = new BPResourceConfiguration();
    sourceConfig.name = resource.metadata.name;

    const sources = [...formValues.sources, sourceConfig];
    setValues({ sources: sources });
    setOpen(false);
  }

  function findDisplayName() {
    return formValues?.sources[editingSourceIx]?.parameters?.find((p) => {
      return p.name === "displayName";
    })?.value;
  }

  function resourceInLibrary(): boolean {
    return formValues?.sources[editingSourceIx]?.name ? true : false;
  }

  return (
    <>
      <div className={styles.container} data-testid="step-two">
        {/* ---------------------------------- Copy ---------------------------------- */}
        <Stack spacing={2} marginBottom={2}>
          <Typography variant="h6" fontWeight={600}>
            Add Sources from which you'd like to collect telemetry.
          </Typography>
          <Typography>
            A Source is a combination of OpenTelemetry receivers and processors
            that allows you to collect telemetry from a specific technology.
            Ensuring the right combination of these components is one of the
            most challenging aspects of building an OpenTelemetry configuration
            file. With BindPlane, we handle that all for you.
          </Typography>
        </Stack>

        <div>
          <div className={mixins["mb-3"]}>
            {formValues.sources.map((s, ix) => renderSourceAccordion(s, ix))}
          </div>
          <Button
            variant="contained"
            endIcon={<PlusCircleIcon />}
            onClick={openResourceDialog}
            data-testid="add-source-button"
          >
            Add Source
          </Button>

          <NewResourceDialog
            platform={
              isEmpty(formValues.secondaryPlatform)
                ? formValues.platform
                : formValues.secondaryPlatform
            }
            title="Choose a Source"
            kind={Kind.Source}
            open={open}
            onClose={() => setOpen(false)}
            resourceTypes={data?.sourceTypes ?? []}
            resources={data?.sources ?? []}
            existingResourceNames={
              data?.sources.map((s) => s.metadata.name) ?? []
            }
            onSaveNew={onSave}
            onSaveExisting={onChooseExistingSource}
          />
        </div>
      </div>

      {formValues?.sources?.[editingSourceIx]?.name ? (
        editModalOpen && (
          <EditSourceDialog
            name={formValues?.sources?.[editingSourceIx]?.name ?? ""}
            onSaveSuccess={() => {}}
            onCancel={() => {
              setEditingSourceIx(-1);
              setEditModalOpen(false);
            }}
            readOnly
            showLibraryBookmark
          ></EditSourceDialog>
        )
      ) : (
        <EditResourceDialog
          resourceTypeDisplayName={
            editingSourceType?.metadata.displayName ?? ""
          }
          displayName={
            formValues?.sources[editingSourceIx]?.displayName ??
            findDisplayName() ??
            ""
          }
          description={editingSourceType?.metadata.description ?? ""}
          parameterDefinitions={editingSourceType?.spec.parameters ?? []}
          additionalInfo={editingSourceType?.metadata.additionalInfo}
          resourceDocLink={editingSourceType?.metadata.resourceDocLink ?? ""}
          fullWidth
          maxWidth="sm"
          parameters={formValues.sources[editingSourceIx]?.parameters ?? []}
          open={editModalOpen}
          onClose={() => {
            setEditingSourceIx(-1);
            setEditModalOpen(false);
          }}
          onCancel={() => {
            setEditingSourceIx(-1);
            setEditModalOpen(false);
          }}
          onDelete={() => setRemoveModalOpen(true)}
          onSave={onEditSourceSave}
          kind={Kind.Source}
          // If resource is in library (is reusable), don't allow editing and do show that it's in library
          readOnly={resourceInLibrary()}
          showLibraryBookmark={resourceInLibrary()}
          resourceNameField={formValues?.sources[editingSourceIx]?.name ?? ""}
          libraryResources={data?.sources}
        />
      )}

      <ConfirmDeleteResourceDialog
        open={removeModalOpen}
        onDelete={onSourceRemove}
        onCancel={() => setRemoveModalOpen(false)}
        action="remove"
      >
        <Typography>Are you sure you want to remove this source?</Typography>
      </ConfirmDeleteResourceDialog>

      <Stack direction={"row"} justifyContent="space-between">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => goToStep(0)}
        >
          Back
        </Button>
        <Button variant="contained" onClick={() => goToStep(2)}>
          Next
        </Button>
      </Stack>
    </>
  );
};

function findSourceType(type?: string | null, sourceTypes?: SourceType[]) {
  if (sourceTypes == null || type == null) {
    return;
  }
  return sourceTypes.find((st) => st.metadata.name === type);
}
