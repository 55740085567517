import { Fade, IconButton } from "@mui/material";
import { useMemo } from "react";
import { EdgeLabelRenderer } from "reactflow";
import { PipelineType } from "../../../graphql/generated";
import colors from "../../../styles/colors";
import { ComponentType } from "../../../utils/classes/component-type";
import { findResource } from "../../../utils/classes/configuration";
import { getNextComponentPaths } from "../../../utils/classes/resource-configuration";
import { size as nodeSize } from "../../ConfigurationFlowV2/layout-grid";
import { PlusCircleIcon } from "../../Icons";
import { useBPGraph } from "../BPGraphProvider";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { V2Config } from "../types";
import { useRouting } from "./RoutingContext";
import styles from "./routing-node-wrapper.module.scss";

interface RoutingNodeWrapperProps {
  componentType: ComponentType;
  componentPath: string;
  nodeType: string;
  nodeID: string;
  xPos: number;
  yPos: number;
}

export const RoutingNodeWrapper: React.FC<RoutingNodeWrapperProps> = ({
  children,
  componentType,
  componentPath,
  nodeType,
  nodeID,
  xPos,
  yPos,
}) => {
  const { configuration } = useV2PipelineGraph();
  const { hoveredNode, onMouseEnterNode, onMouseExitNode, pipelineType } =
    useBPGraph();

  const labelX = xPos + nodeSize(nodeType).width + 2;
  const labelY = yPos + nodeSize(nodeType).height / 2 - 19; // 12 is half the height of the button

  const {
    canConnect,
    hasAvailableConnections,
    onConnect,
    onRouteButtonClick,
    isConnecting,
    readOnly,
  } = useRouting();

  const connectable = componentPath && canConnect(componentPath);

  const classNames = [styles.container];
  if (connectable) {
    classNames.push(styles.highlighted);
  }
  function handleConnectClick() {
    if (!connectable) {
      return;
    }
    onConnect(componentPath!);
  }

  const isRoutable =
    (componentType === "sources" || componentType === "processors") &&
    hasAvailableConnections(nodeID);

  const hovered = nodeID === hoveredNode;
  const orphaned = useMemo(
    () => nodeIsOrphaned(configuration, componentPath, pipelineType),
    [configuration, componentPath, pipelineType],
  );

  const fadeIn = (hovered || orphaned) && !readOnly && !isConnecting;

  return (
    <div onClick={handleConnectClick} className={styles.container}>
      <div className={connectable ? styles.highlighted : undefined}>
        {children}
      </div>

      {isRoutable && (
        <EdgeLabelRenderer>
          <Fade in={fadeIn}>
            <div
              className={"nopan"}
              style={{
                transform: `translate(${labelX}px,${labelY}px)`,
                pointerEvents: fadeIn ? "all" : "none",
                position: "absolute",
                backgroundColor: colors.white,
                borderRadius: "50%",
                zIndex: 1,
                opacity: fadeIn ? 1 : 0,
              }}
            >
              <IconButton
                onMouseEnter={() => onMouseEnterNode(nodeID)}
                onMouseLeave={onMouseExitNode}
                onClick={(_e) =>
                  onRouteButtonClick(componentType, componentPath)
                }
                color="primary"
              >
                <PlusCircleIcon />
              </IconButton>
            </div>
          </Fade>
        </EdgeLabelRenderer>
      )}
    </div>
  );
};

function nodeIsOrphaned(
  configuration: V2Config,
  componentPath: string,
  pipelineType: PipelineType,
): boolean {
  if (!configuration) return false;
  const rc = findResource(configuration, componentPath);
  return rc != null && getNextComponentPaths(rc, pipelineType).length === 0;
}
