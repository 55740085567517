import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetFeatureGateQuery } from "../../graphql/generated";
import { SubNavInfo, SubNavItem, SubNavRoutes } from "./types";
import styles from "./sub-nav.module.scss";

// subNavInfo contains the information about the sub navigation for each route.
const subNavInfo: SubNavRoutes = {
  overview: {
    subNavItems: [
      {
        label: "Visualize",
        path: "/overview",
      },
      {
        label: "Summary",
        path: "/overview/summary",
      },
    ],
  },
};

export const SubNav: React.FC = () => {
  const location = useLocation();
  const info = getInfoForPath(location.pathname);
  const [enabled, setEnabled] = useState(false);

  const navigate = useNavigate();

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    // preserve the search params
    const to = newValue + location.search;
    navigate(to);
  };

  // SubNav is only used on the overview page for the feature gated
  // summary page.  For now we can hard code the feature gate id.
  useGetFeatureGateQuery({
    variables: {
      input: "overview-summary-page",
    },
    onCompleted: (data) => {
      setEnabled(data.enabled);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (info == null) {
    return null;
  }

  if (!enabled) {
    return null;
  }

  return (
    <Box className={styles.box} sx={{ background: "white" }}>
      <Tabs
        onChange={handleChange}
        value={location.pathname}
        classes={{ scroller: styles.scroller }}
      >
        {info.subNavItems.map((item) => (
          <Tab
            key={item.path}
            label={<ItemLabel item={item} />}
            value={item.path}
            classes={{
              root: styles.tab,
              selected: styles.selected,
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

const ItemLabel: React.FC<{ item: SubNavItem }> = ({ item }) => {
  const [hovered, setHovered] = useState(false);
  const theme = useTheme();
  return (
    <Typography
      fontFamily={theme.secondaryFontFamily}
      fontWeight={hovered ? 400 : 300}
      fontSize={"0.75rem"}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {item.label}
    </Typography>
  );
};

// getInfoForPath returns the SubNavInfo for the given path.  If
// there is no SubNavInfo for the given path it returns null
function getInfoForPath(path: string): SubNavInfo | null {
  // get the base path for the given path
  const basePath = path.split("/")[1];
  const info = subNavInfo[basePath];
  if (info == null) {
    return null;
  }
  return info;
}
