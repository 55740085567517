import {
  Stack,
  Tooltip,
  Box,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import { memo, ChangeEvent } from "react";
import { XIcon, SearchIcon } from "../Icons";
import { AgentsTableSelect } from "./AgentsTableSelect";
import { useSnapshot } from "./SnapshotContext";

export interface SnapShotControlsProps {
  setWorkingQuery: (query: string) => void;
  workingQuery?: string;
}
export const SnapShotControls: React.FC<SnapShotControlsProps> = memo(
  ({ setWorkingQuery, workingQuery }) => {
    const {
      showAgentSelect,
      agentID,
      setAgentID,
      searchQuery,
      setSearchQuery,
      setFooter,
      setProcessedFooter,
      snapshotSearchSupported,
      refresh,
    } = useSnapshot();

    const handleSearch = () => {
      if (workingQuery === searchQuery) {
        // no change, but user requested another search
        refresh();
      } else {
        setSearchQuery(workingQuery ?? "");
      }
      setFooter("Searching...");
      setProcessedFooter("Searching...");
    };

    const toolTip = !snapshotSearchSupported
      ? "Snapshot search is only supported on agents with version v1.44.0 or greater."
      : null;

    return (
      <Stack direction="row" spacing={2} style={{ marginTop: 5 }}>
        {showAgentSelect && (
          <AgentsTableSelect
            agentID={agentID}
            query={"-status:disconnected"}
            onChange={setAgentID}
          />
        )}
        <>
          <Tooltip title={toolTip}>
            <Box flexGrow={1}>
              <TextField
                id="search-telemetry"
                label="Search telemetry"
                variant="outlined"
                size="small"
                fullWidth
                sx={{
                  height: "100%",
                  "& .MuiInputBase-root": {
                    height: "100%",
                    "& input": {
                      height: "100%",
                      boxSizing: "border-box",
                    },
                  },
                }}
                value={workingQuery}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setWorkingQuery(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                disabled={!snapshotSearchSupported}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size="small"
                      sx={{
                        visibility: workingQuery !== "" ? "visible" : "hidden",
                        marginRight: -1,
                      }}
                      onClick={() => {
                        setWorkingQuery("");
                        setSearchQuery("");
                      }}
                    >
                      <XIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </Tooltip>
          <Button
            variant="contained"
            size="medium"
            onClick={handleSearch}
            disabled={!snapshotSearchSupported}
            startIcon={<SearchIcon />}
            sx={{ padding: "0px 25px" }}
          >
            Search
          </Button>
        </>
      </Stack>
    );
  },
);
