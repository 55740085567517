import { useApolloClient } from "@apollo/client";
import { Typography } from "@mui/material";
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from "@mui/x-data-grid-pro";
import { Maybe } from "graphql/jsutils/Maybe";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { ConfirmDeleteResourceDialog } from "../../../components/ConfirmDeleteResourceDialog";
import { TableOptions } from "../../../components/TableOptions/TableOptions";
import { deleteAccount } from "../../../utils/rest/delete-account";

export type MinimumAccount = {
  metadata: {
    displayName?: Maybe<string>;
    dateModified?: Maybe<string>;
    id: string;
  };
  userCount: number;
};

interface OrganizationAccountTableProps {
  accounts: MinimumAccount[];
  refetch: () => void;
}

export const OrganizationAccountTable: React.FC<
  OrganizationAccountTableProps
> = ({ accounts, refetch }) => {
  const apolloClient = useApolloClient();
  const [projectId, setProjectId] = useState<string | null>(null);
  const [projectName, setProjectName] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  async function handleSuccessfulAccountDeletion() {
    setDeleteDialogOpen(false);
    refetch();
    await apolloClient.refetchQueries({
      include: ["settingsMenu"],
    });
  }

  async function handleDeleteProject(projectId: string | null) {
    if (projectId === null) return;
    try {
      await deleteAccount(projectId);
      handleSuccessfulAccountDeletion();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to delete account", {
        variant: "error",
      });
    }
  }

  const columns: GridColDef<MinimumAccount>[] = [
    {
      field: "displayName",
      headerName: "Name",
      valueGetter: (_, row) => row.metadata.displayName,
      renderHeader,
      flex: 1,
    },
    {
      field: "users",
      headerName: "Users",
      renderHeader,
      valueGetter: (_, row: MinimumAccount) => row.userCount,
      width: 200,
    },
    {
      field: " ",
      disableColumnMenu: true,
      width: 30,
      renderCell: (params: GridCellParams) =>
        accounts.length > 1
          ? renderOptionsCell(params, () => {
              setDeleteDialogOpen(true);
              setProjectId(params.row.metadata.id || "");
              setProjectName(params.row.metadata.displayName || "");
            })
          : null,
      sortable: false,
    },
  ];
  return (
    <>
      <DataGridPro
        columns={columns}
        rows={accounts}
        getRowId={(row: MinimumAccount) => row.metadata.id}
        disableRowSelectionOnClick
        sortingOrder={["asc", "desc"]}
        density="compact"
        slots={{
          footer: () => null,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "displayName", sort: "asc" }],
          },
        }}
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
        }}
        disableVirtualization={process.env.NODE_ENV === "test"}
      />
      <ConfirmDeleteResourceDialog
        onDelete={() => handleDeleteProject(projectId)}
        onCancel={() => setDeleteDialogOpen(false)}
        action={"delete"}
        open={deleteDialogOpen}
      >
        <Typography>
          Are you sure you want to delete this project?
          <Typography
            component="span"
            sx={{
              fontWeight: "bold",
              display: "block",
              paddingTop: "4px",
            }}
          >
            {projectName}
          </Typography>
        </Typography>
      </ConfirmDeleteResourceDialog>
    </>
  );
};

function renderHeader(params: GridColumnHeaderParams<MinimumAccount>) {
  return <Typography fontWeight={600}>{params.colDef.headerName}</Typography>;
}

function renderOptionsCell(params: GridCellParams, onDelete: () => void) {
  return (
    <TableOptions
      id={params.row.id}
      onDelete={onDelete}
      link={params.row.link}
    />
  );
}
