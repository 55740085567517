import { ApolloError, gql } from "@apollo/client";
import { Alert, Dialog, DialogProps, Stack } from "@mui/material";
import { cloneDeep } from "lodash";
import { useState } from "react";
import { EESnapshotContextProvider } from "../../contexts/EESnapshotContext";
import {
  GetResourceTypesQuery,
  Kind,
  PipelineType,
  ResourceConfiguration,
} from "../../graphql/generated";
import { BPResourceConfiguration } from "../../utils/classes";
import { DialogContainer } from "../DialogComponents/DialogContainer";
import { ProcessorTelemetrySwitcher } from "../ProcessorTelemetrySwitcher/ProcessorTelemetrySwitcher";
import { ProcessorDialogComponentProps } from "../ProcessorsDialog/ProcessorDialog";
import {
  ReusableProcessors,
  withCommonProcessorDialog,
} from "../ProcessorsDialog/withCommonProcessorDialog";
import { ResizeHandle } from "../ResizeHandle/ResizeHandle";
import { ResourceConfigurationEditor } from "../ResourceConfigurationEditor";
import { ResourceDialogContextProvider } from "../ResourceDialog/ResourceDialogContext";
import {
  SnapshotAction,
  SnapshotActionHandler,
  SnapshotActionType,
  SnapshotConsole,
} from "../SnapShotConsole/SnapShotConsole";
import { SnapShotControls } from "../SnapShotConsole/SnapShotControls";
import { useSnapshot } from "../SnapShotConsole/SnapshotContext";
import { ResourceEditor, handleAction } from "./actions";
import { SnapshotRegionMenu } from "./menu";
import consoleStyles from "../SnapShotConsole/snap-shot-console.module.scss";
import styles from "./processor-dialog-with-preview.module.scss";

gql`
  mutation updateProcessors($input: UpdateProcessorsInput!) {
    updateProcessors(input: $input)
  }
`;

export interface ProcessorDialogProps extends DialogProps {
  processors: ResourceConfiguration[];
  reusableProcessors?: ReusableProcessors;
  fetchReusableProcessorsError?: ApolloError;
  refetchReusableProcessors: () => void;
  readOnly: boolean;
  useV2Context?: boolean;
}

export type ProcessorType = GetResourceTypesQuery["resourceTypes"][0];

export const ProcessorDialogWithPreviewComponent: React.FC<
  ProcessorDialogComponentProps
> = ({
  agentID,
  closeDialog,
  configurationName,
  description,
  dialogOpen,
  initProcessors,
  libraryProcessors,
  onClose,
  onDelete,
  onProcessorsChange,
  onUpdateInlineProcessors,
  pipelineType,
  processors,
  readOnly,
  refetchConfiguration,
  refetchLibraryProcessors,
  resourceIndex,
  resourceName,
  snapshotID,
  telemetryTypes,
  title,
  position,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [action, setAction] = useState<SnapshotAction>({
    pipelineType: PipelineType.Logs,
    type: SnapshotActionType.NONE,
    data: {},
  });

  const toggleActionMenu = (newAnchorEl: HTMLElement | null) => {
    if (menuAnchorEl) {
      menuAnchorEl.classList.remove(consoleStyles["menu-open"]);
    }
    setMenuAnchorEl(newAnchorEl);
  };

  function handleAddProcessor(type: string, params: Record<string, any>): void {
    const processorConfig = new BPResourceConfiguration();
    processorConfig.setParamsFromMap(params);
    processorConfig.type = type;

    const newProcessors = [...processors, processorConfig];
    onProcessorsChange(newProcessors);
  }

  // editProcessor will edit an existing processor in the list of processors, returning
  // true if a match was found and the edit was successful.
  function handleEditProcessor({ match, update }: ResourceEditor): boolean {
    const index = processors.findIndex(match);
    if (index === -1) {
      return false;
    }

    const processor = processors[index];
    const copy = cloneDeep(processor);
    if (!update(copy)) {
      return false;
    }
    const newProcessors = [...processors];
    newProcessors[index] = copy;
    onProcessorsChange(newProcessors);
    return true;
  }

  const onAction = (action: SnapshotAction) => {
    setAction(action);

    let showMenuAtElement: HTMLElement | null = null;
    switch (action.type) {
      case SnapshotActionType.OPEN_METRIC_NAME_MENU:
      case SnapshotActionType.OPEN_FIELD_ROW_MENU:
      case SnapshotActionType.OPEN_SEVERITY_MENU:
        showMenuAtElement = action.source ?? null;
        break;
      default:
        handleAction(action, {
          addProcessor: handleAddProcessor,
          editProcessor: handleEditProcessor,
        });
        break;
    }

    toggleActionMenu(showMenuAtElement);
  };

  const menu = (
    <SnapshotRegionMenu
      open={Boolean(menuAnchorEl)}
      anchorEl={menuAnchorEl}
      action={action}
      onAction={onAction}
      onClose={() => toggleActionMenu(null)}
    />
  );

  return (
    <ResourceDialogContextProvider onClose={onClose} purpose={"edit"}>
      <EESnapshotContextProvider
        pipelineType={pipelineType as PipelineType}
        processors={processors}
        resourceIndex={resourceIndex}
        resourceName={resourceName}
        snapshotID={snapshotID}
        showAgentSelector
        agentID={agentID}
        configurationName={configurationName}
        position={position}
      >
        <Dialog open={dialogOpen} fullScreen onClose={onClose}>
          <DialogContainer
            title={title}
            description={description}
            onClose={onClose}
          >
            <ProcessorsBody onAction={onAction} readOnly={readOnly}>
              <ResourceConfigurationEditor
                closeDialog={closeDialog}
                initItems={initProcessors}
                items={processors}
                kind={Kind.Processor}
                reusableResources={libraryProcessors}
                refetchReusableResources={refetchLibraryProcessors}
                onItemsChange={onProcessorsChange}
                refetchConfiguration={refetchConfiguration}
                telemetryTypes={telemetryTypes}
                updateInlineItems={onUpdateInlineProcessors}
                readOnly={readOnly}
                onDelete={onDelete}
              />
            </ProcessorsBody>
          </DialogContainer>
          {menu}
        </Dialog>
      </EESnapshotContextProvider>
    </ResourceDialogContextProvider>
  );
};

interface Props {
  onAction?: SnapshotActionHandler;
  readOnly: boolean;
}

export const ProcessorsBody: React.FC<Props> = ({
  children,
  onAction,
  readOnly,
}) => {
  const {
    logs,
    metrics,
    traces,
    processedLogs,
    processedMetrics,
    processedTraces,
    footer,
    processedFooter,
    error,
    isAddedField,
    isRemovedField,
  } = useSnapshot();
  const [workingQuery, setWorkingQuery] = useState<string>("");

  const [leftWidth, setLeftWidth] = useState(window.innerWidth / 3);
  const [rightWidth, setRightWidth] = useState(window.innerWidth / 3);

  return (
    <Stack
      height="100%"
      spacing={2}
      className={
        readOnly ? consoleStyles["read-only"] : consoleStyles["read-write"]
      }
    >
      {error && (
        <Alert severity="error" sx={{ marginTop: 2 }}>
          {error}
        </Alert>
      )}

      <Stack
        direction="row"
        flexGrow={1}
        spacing={2}
        className={
          readOnly ? consoleStyles["read-only"] : consoleStyles["read-write"]
        }
      >
        <Stack
          spacing={2}
          height="100%"
          minWidth={leftWidth}
          maxWidth={leftWidth}
          className={styles["snapshot-container-left"]}
        >
          <SnapShotControls
            setWorkingQuery={setWorkingQuery}
            workingQuery={workingQuery}
          />
          <SnapshotConsole
            logs={logs}
            metrics={metrics}
            traces={traces}
            footer={footer}
            onAction={onAction}
            readOnly={readOnly}
            isRemovedField={isRemovedField}
          />
        </Stack>
        <ResizeHandle
          resizeCallback={(delta: number) => {
            setLeftWidth((oldWidth) => oldWidth + delta);
          }}
        />
        <Stack
          height="100%"
          spacing={2}
          minWidth={rightWidth}
          maxWidth={rightWidth}
        >
          <ProcessorTelemetrySwitcher setWorkingQuery={setWorkingQuery} />
          <div className={styles["form-container"]}>{children}</div>
        </Stack>
        <ResizeHandle
          resizeCallback={(delta: number) => {
            setRightWidth((oldWidth) => oldWidth + delta);
          }}
        />
        <div className={styles["snapshot-container-right"]}>
          <SnapshotConsole
            hideControls
            logs={processedLogs}
            metrics={processedMetrics}
            traces={processedTraces}
            footer={processedFooter}
            onAction={onAction}
            readOnly={readOnly}
            isAddedField={isAddedField}
          />
        </div>
      </Stack>
    </Stack>
  );
};

export const ProcessorDialogWithPreview: React.FC = withCommonProcessorDialog(
  ProcessorDialogWithPreviewComponent,
);
