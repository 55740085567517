import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  MenuProps,
} from "@mui/material";
import colors from "../../../styles/colors";
import { PlusCircleIcon, TrashIcon } from "../../Icons";

interface EdgeMenuProps extends MenuProps {
  onInsertConnectorNode?: () => void;
  onInsertProcessorNode: () => void;
  onDisconnect: () => void;
}

export const EdgeMenu: React.FC<EdgeMenuProps> = ({
  onInsertConnectorNode,
  onInsertProcessorNode,
  onDisconnect,
  ...menuProps
}) => {
  return (
    <Menu {...menuProps}>
      <MenuList dense sx={{ padding: 0 }}>
        <MenuItem onClick={onInsertProcessorNode}>
          <ListItemIcon color="primary">
            <PlusCircleIcon
              stroke={colors.pixelPointBlue}
              width="16px"
              onClick={() => onInsertProcessorNode()}
            />
          </ListItemIcon>
          <ListItemText>Insert Processor Node</ListItemText>
        </MenuItem>
        {onInsertConnectorNode && (
          <MenuItem onClick={onInsertConnectorNode}>
            <ListItemIcon color="primary">
              <PlusCircleIcon
                stroke={colors.pixelPointBlue}
                width="16px"
                onClick={() => onInsertConnectorNode()}
              />
            </ListItemIcon>
            <ListItemText>Insert Connector</ListItemText>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={onDisconnect}>
          <ListItemIcon color="primary">
            <TrashIcon stroke={colors.muiErrorDark} width="16px" />
          </ListItemIcon>
          <ListItemText>Disconnect</ListItemText>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
