import { Button, Stack } from "@mui/material";
import { Kind, ResourceConfiguration, Role } from "../../graphql/generated";
import { ActionsSection } from "../DialogComponents";
import { RBACWrapper } from "../RBACWrapper/RBACWrapper";
import { ResourceConfigurationsContainer } from "../ResourceConfigurationsContainer";
import { useSnapshot } from "../SnapShotConsole/SnapshotContext";
import { ViewHeading } from "./ViewHeading";
import mixins from "../../styles/mixins.module.scss";

interface AllItemsProps {
  items: ResourceConfiguration[];
  readOnly: boolean;
  resourceKind: Kind.Processor | Kind.Extension;
  onAddItem: () => void;
  onEditItem: (item: number) => void;
  onDeleteItem: (item: number) => void;
  onSave: () => void;
  onDelete?: () => void;
  onItemsChange: (items: ResourceConfiguration[]) => void;
  onViewRecommendation: (rec: ResourceConfiguration) => void;
}

/**
 * AllItems view shows the initial view of the resource configuration editing dialog, which is a list of all items,
 * with the ability to add a new item, reorder items, and select an item to edit or delete.
 */
export const AllItemsView: React.FC<AllItemsProps> = ({
  onSave,
  onDelete,
  readOnly,
  resourceKind,
  onViewRecommendation,
  ...resourceContainerProps
}) => {
  // When inside the EESnapshotContext we can have processor recommendations to pass to the container
  const { processorRecommendations } = useSnapshot();

  return (
    <Stack
      className={mixins["flex-grow"]}
      style={{ height: "100%", paddingRight: "16px" }}
    >
      <ViewHeading heading={`${resourceKind}s`} />
      <ResourceConfigurationsContainer
        resourceKind={resourceKind}
        readOnly={readOnly}
        processorRecommendations={processorRecommendations}
        onViewRecommendation={onViewRecommendation}
        {...resourceContainerProps}
      />
      {!readOnly && (
        <ActionsSection>
          <RBACWrapper requiredRole={Role.User}>
            {onDelete && (
              <Button variant="contained" color="error" onClick={onDelete}>
                Delete
              </Button>
            )}
            <Button variant="contained" onClick={onSave}>
              Save
            </Button>
          </RBACWrapper>
        </ActionsSection>
      )}
    </Stack>
  );
};
