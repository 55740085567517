import { Button, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Kind,
  ProcessorRecommendation,
  ResourceConfiguration,
} from "../../graphql/generated";
import { PlusCircleIcon } from "../Icons";
import { ProcessorRecCard } from "./ProcessorRecCard";
import { ResourceConfigurationLabel } from "./ResourceConfigurationLabel";
import { ViewOnlyResourceConfigurationLabel } from "./ViewOnlyResourceConfigurationLabel";
import mixins from "../../styles/mixins.module.scss";

interface Props {
  resourceKind: Kind.Processor | Kind.Extension;
  items: ResourceConfiguration[];
  onAddItem: () => void;
  onEditItem: (index: number) => void;
  onDeleteItem: (index: number) => void;
  onItemsChange: (ps: ResourceConfiguration[]) => void;
  readOnly?: boolean;
  onViewRecommendation: (item: ResourceConfiguration) => void;
  processorRecommendations?: ProcessorRecommendation[] | null;
}

export const ResourceConfigurationsContainer: React.FC<Props> = ({
  resourceKind,
  items,
  onItemsChange,
  onAddItem,
  onEditItem,
  onDeleteItem,
  onViewRecommendation,
  processorRecommendations,

  readOnly = false,
}) => {
  function handleDrop() {
    onItemsChange(items);
  }

  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex === hoverIndex) {
        return;
      }

      const newItems = [...items];

      const dragItem = newItems[dragIndex];
      const hoverItem = newItems[hoverIndex];

      // Swap places of dragItem and hoverItem in the array
      newItems[dragIndex] = hoverItem;
      newItems[hoverIndex] = dragItem;

      onItemsChange(newItems);
    },
    [items, onItemsChange],
  );

  const showRecommendations =
    !readOnly &&
    processorRecommendations != null &&
    processorRecommendations.length > 0;

  if (showRecommendations) {
    processorRecommendations.sort((a, b) =>
      a.displayName < b.displayName ? -1 : 1,
    );
  }

  return (
    <Stack className={mixins["flex-grow"]} overflow="auto">
      <DndProvider backend={HTML5Backend}>
        {readOnly && items.length === 0 && (
          <Stack
            justifyContent="center"
            alignItems="center"
            width="100%"
            marginBottom={2}
          >
            <Typography>No {resourceKind.toLowerCase()}s</Typography>
          </Stack>
        )}

        {items.map((p, ix) => {
          return readOnly ? (
            <ViewOnlyResourceConfigurationLabel
              resourceKind={resourceKind}
              key={`${p.name}-${ix}`}
              index={ix}
              item={p}
              onEdit={() => onEditItem(ix)}
              onDelete={() => onDeleteItem(ix)}
              readOnly={readOnly}
            />
          ) : (
            <ResourceConfigurationLabel
              resourceKind={resourceKind}
              moveItem={moveItem}
              key={`${p.name}-${ix}`}
              item={p}
              onEdit={() => onEditItem(ix)}
              onDelete={() => onDeleteItem(ix)}
              index={ix}
              onDrop={handleDrop}
              readOnly={readOnly}
            />
          );
        })}
        {!readOnly && (
          <Button
            size="small"
            variant="text"
            startIcon={<PlusCircleIcon />}
            classes={{ root: mixins["mb-2"] }}
            onClick={onAddItem}
          >
            Add {resourceKind.toLowerCase()}
          </Button>
        )}

        {!readOnly && showRecommendations && (
          <>
            <Typography fontWeight={600}>Recommendations</Typography>
            {processorRecommendations.map((rec) => (
              <ProcessorRecCard
                key={rec.displayName}
                item={rec}
                onView={onViewRecommendation}
              />
            ))}
          </>
        )}
      </DndProvider>
    </Stack>
  );
};
