import { Stack } from "@mui/material";
import { chartsGridClasses } from "@mui/x-charts/ChartsGrid";
import { LineChart } from "@mui/x-charts/LineChart";
import { add } from "date-fns";
import { SummaryGraphData } from "../../graphql/generated";
import {
  SummaryInterval,
  SummaryPeriod,
} from "../../pages/overview/SummaryPage/SummaryPageContext";
import colors from "../../styles/colors";
import { formatBytes } from "../../utils/graph/utils";

interface DataSummaryGraphProps {
  summaryGraphData: SummaryGraphData;
  period: SummaryPeriod;
  interval: SummaryInterval;
}

export const DataSummaryGraph: React.FC<DataSummaryGraphProps> = ({
  summaryGraphData: dataset,
  period,
  interval,
}) => {
  const timeSeries = dataset.dataset.map((point) => {
    return {
      timestamp: new Date(point.timestamp),
      s0Value: point.s0Value,
      d1Value: point.d1Value,
    };
  });
  timeSeries.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
  if (timeSeries.length < 2) {
    return (
      <Stack
        height={360}
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        No Data
      </Stack>
    );
  }

  return (
    <LineChart
      xAxis={[
        {
          id: "x-axis",
          dataKey: "timestamp",
          valueFormatter: (value) => formatTime(value, period),
          scaleType: "time",
          tickMinStep: minTickStep(interval),
          min: xMin(interval),
          max: new Date(),
        },
      ]}
      series={[
        {
          id: "s0",
          dataKey: "s0Value",
          color: colors.lightGreen,
          curve: "monotoneX",
          valueFormatter: formatBytes,
          showMark: false,
          area: true,
        },
        {
          id: "d1",
          dataKey: "d1Value",
          color: colors.lightBlue,
          curve: "monotoneX",
          valueFormatter: formatBytes,
          showMark: false,
          area: true,
        },
      ]}
      dataset={timeSeries}
      height={360}
      margin={{ left: 70, top: 48 }}
      yAxis={[
        {
          id: "y-axis",
          valueFormatter: formatBytes,
          min: 0,
        },
      ]}
      grid={{ horizontal: true }}
      sx={chartStyles}
    >
      <defs>
        <linearGradient id="gradient-s0Value" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor={"#d6f3e5"} stopOpacity={0.5} />
          <stop
            offset="100%"
            stopColor={colors.backgroundWhite}
            stopOpacity={0.3}
          />
        </linearGradient>
        <linearGradient id="gradient-d1Value" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor={"#d8e9fb"} stopOpacity={0.5} />
          <stop
            offset="100%"
            stopColor={colors.backgroundWhite}
            stopOpacity={0.3}
          />
        </linearGradient>
      </defs>
    </LineChart>
  );
};

const chartStyles = {
  "& .MuiAreaElement-series-s0": {
    fill: "url('#gradient-s0Value')",
  },
  "& .MuiAreaElement-series-d1": {
    fill: "url('#gradient-d1Value')",
  },
  "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
    strokeWidth: "0.4",
    fill: colors.darkGray,
  },
  "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
    strokeWidth: "0.4",
    fill: colors.darkGray,
  },
  "& .MuiChartsAxis-tick": {
    display: "none",
  },
  "& .MuiChartsAxis-line": {
    display: "none",
  },
  [`& .${chartsGridClasses.line}`]: {
    strokeDasharray: "5 3",
    strokeWidth: 1,
    zIndex: 400,
  },
};

function formatTime(time: Date, period: SummaryPeriod): string {
  switch (period) {
    case SummaryPeriod.OneHour:
      return time.toLocaleTimeString(undefined, {
        hour: "numeric",
      });

    case SummaryPeriod.OneDay:
      return time.toLocaleDateString(undefined, {
        month: "numeric",
        day: "numeric",
      });
    default:
      return time.getMinutes().toString();
  }
}

function minTickStep(interval: string): number {
  switch (interval) {
    case SummaryInterval.OneDay:
      return 1000 * 60 * 60 * 3; // 3 hours
    case SummaryInterval.ThirtyDays:
      return 1000 * 60 * 60 * 24 * 5; // 5 days
    case SummaryInterval.NinetyDays:
      return 1000 * 60 * 60 * 24 * 5; // 5 days
    default:
      return 1000 * 60 * 60 * 24; // 1 day
  }
}

/**
 * xMin returns the start time for a graph given the interval.
 *
 * @param interval SummaryInterval, the interval to get the min date for
 * @returns Date, the start time for the graph
 */
export function xMin(interval: SummaryInterval): Date {
  const now = new Date();

  switch (interval) {
    case SummaryInterval.OneDay:
      return add(now, { days: -1 });
    case SummaryInterval.ThirtyDays:
      return add(now, { days: -30 });
    case SummaryInterval.NinetyDays:
      return add(now, { days: -90 });
  }
}
