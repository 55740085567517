import { useState } from "react";
import { V2Config } from "../components/PipelineGraphV2/types";
import { useGetConfigurationWithRoutesQuery } from "../graphql/generated";
import { nameAndVersion, trimVersion } from "../utils/version-helpers";

type useConfigurationEditDataValue = {
  live: V2Config | null;
  draft: V2Config | null;
  refetch: () => void;
};

/**
 * useConfigurationEditData is a hook that fetches the live and draft versions of a configuration
 * and returns them along with a refetch function.  It's used to reduce re-renders from changing
 * between "live" and "draft" tabs while editing a configuration.
 *
 * @param configurationName the name of the configuration, versioned is okay but not necessary
 * @param live the live version of the configuration
 * @param draft the draft version of the configuration
 * @returns
 */
export function useConfigurationEditData(
  configurationName: string,
  live: number,
  draft?: number,
): useConfigurationEditDataValue {
  const [queriedLiveVersion, setQueriedLiveVersion] = useState<number>();
  const [queriedDraftVersion, setQueriedDraftVersion] = useState<number>();

  const [versions, setVersions] = useState<
    Omit<useConfigurationEditDataValue, "refetch">
  >({
    live: null,
    draft: null,
  });

  useGetConfigurationWithRoutesQuery({
    variables: {
      name: nameAndVersion(trimVersion(configurationName), live),
    },
    skip: queriedLiveVersion === live,
    onCompleted(data) {
      setVersions((prev) => ({ ...prev, live: data.configuration }));
      setQueriedLiveVersion(live);
    },
  });

  const { refetch } = useGetConfigurationWithRoutesQuery({
    variables: {
      name: nameAndVersion(trimVersion(configurationName), draft),
    },
    skip: draft == null || queriedDraftVersion === draft,
    onCompleted(data) {
      setVersions((prev) => ({ ...prev, draft: data.configuration }));
      setQueriedDraftVersion(draft);
    },
  });

  return { ...versions, refetch };
}
