import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import {
  ComponentDialogProvider,
  useComponentDialog,
} from "../../components/Dialogs/hooks/useComponentDialog";
import {
  DeleteResourcesDialogProvider,
  useDeleteResourcesDialog,
} from "../../components/Dialogs/hooks/useDeleteResourcesDialog";
import {
  SaveToLibraryDialogProvider,
  useSaveToLibraryDialog,
} from "../../components/Dialogs/hooks/useSaveToLibraryDialog";
import { ChevronDown } from "../../components/Icons";
import {
  NewResourceDialog,
  ResourceType,
} from "../../components/ResourceDialog";
import { ConnectorDataGrid } from "../../components/Tables/ConnectorTable/ConnectorDataGrid";
import { RBACWrapper } from "../../contexts/RBAC";
import {
  Kind,
  Role,
  useGetResourcesQuery,
  useGetResourceTypesQuery,
} from "../../graphql/generated";
import { ResourceKind } from "../../types/resources";
import { BPResourceConfiguration } from "../../utils/classes";
import { resourceTypeOfKind } from "../../utils/classes/component-type";
import { classes } from "../../utils/styles";
import { resourcesFromSelected } from "./ResourceLibraryPage";
import mixins from "../../styles/mixins.module.scss";
import styles from "./resource-library.module.scss";

interface ResourceLibraryBodyProps {
  kind: ResourceKind;
}

/**
 * Table of reusable components (body)
 */
export const ResourceLibraryBody: React.FC<ResourceLibraryBodyProps> = ({
  kind,
}) => {
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);

  const { deleteResources } = useDeleteResourcesDialog();
  const { editComponent } = useComponentDialog();
  const { saveToLibrary } = useSaveToLibraryDialog();

  const { data: resources, refetch: refetchResources } = useGetResourcesQuery({
    variables: { kind },
    fetchPolicy: "network-only",
    onError: (e) => {
      console.error(e);
      enqueueSnackbar(`There was an error retrieving ${kind}s.`, {
        variant: "error",
      });
    },
  });

  const { data: resourceTypes } = useGetResourceTypesQuery({
    variables: { kind: resourceTypeOfKind(kind) },
    onError: (e) => {
      console.error(e);
      enqueueSnackbar(`There was an error retrieving ${kind} types.`, {
        variant: "error",
      });
    },
  });

  function handleSaveNew(
    parameters: { [key: string]: any },
    resourceType: ResourceType,
    type?: string,
  ) {
    saveToLibrary({
      resource: {
        apiVersion: "v1",
        kind: kind,
        resourceType: type ?? "",
        parameters,
      },
      existingNames: resources?.resources.map((r) => r.metadata.name) ?? [],
      onSuccess: (data) => {
        refetchResources();
        setAddDialogOpen(false);
      },
      onCancel: () => {
        setAddDialogOpen(false);
      },
    });
  }

  function handleDelete(name: string, closeDialog: () => void) {
    deleteResources({
      confirm: true,
      resources: [
        {
          kind,
          metadata: { name },
        },
      ],
      onSuccess: () => {
        refetchResources();
        closeDialog();
      },
    });
  }

  function handleEdit(name: string) {
    editComponent({
      kind,
      resource: new BPResourceConfiguration({
        name,
        disabled: false,
      }),
      onSuccess: () => refetchResources(),
      onDelete: (closeDialog) => handleDelete(name, closeDialog),
    });
  }

  return (
    <Accordion defaultExpanded className={classes([styles.accordion])}>
      <AccordionSummary expandIcon={<ChevronDown />}>
        <Stack
          direction={"row"}
          flexGrow={1}
          justifyContent={"space-between"}
          marginRight={"8px"}
        >
          <Typography variant="h5">{kind}s</Typography>
          <Stack direction={"row"} spacing={2}>
            <RBACWrapper requiredRole={Role.User}>
              <Button
                data-testid={`add-${kind}`}
                size="small"
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation(); // otherwise accordion expands
                  setAddDialogOpen(true);
                }}
              >
                Add {kind}
              </Button>
            </RBACWrapper>
            {selected.length > 0 && (
              <RBACWrapper requiredRole={Role.User}>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={async (e) => {
                    e.stopPropagation(); // otherwise accordion expands

                    const items = resourcesFromSelected(selected, kind);

                    deleteResources({
                      confirm: true,
                      resources: items,
                      onSuccess: (updates) => {
                        setSelected([]);
                        refetchResources();
                      },
                    });
                  }}
                  classes={{ root: mixins["float-right"] }}
                >
                  Delete {selected.length} {kind}
                  {selected.length > 1 && "s"}
                </Button>
              </RBACWrapper>
            )}
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails data-testid={`${kind}-table-details`}>
        <NewResourceDialog
          platform={"unknown"}
          kind={kind}
          resourceTypes={resourceTypes?.resourceTypes ?? []}
          resources={resources?.resources ?? []}
          existingResourceNames={
            resources?.resources.map((r) => r.metadata.name) ?? []
          }
          open={addDialogOpen}
          onSaveNew={handleSaveNew}
          onSaveExisting={() => {}}
          onClose={() => setAddDialogOpen(false)}
          fromLibrary
        />

        {/* Connector grid */}
        {kind === Kind.Connector && (
          <ConnectorDataGrid
            connectors={resources?.resources}
            onEditConnector={(name: string) => handleEdit(name)}
            hideFooter
            rowSelectionModel={selected}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newSelection) =>
              setSelected(newSelection)
            }
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

interface ResourceLibraryTableProps {
  kind: ResourceKind;
}

/**
 * Table of reusable components
 */
export const ResourceLibraryTable: React.FC<ResourceLibraryTableProps> = ({
  kind,
}) => {
  return (
    <ComponentDialogProvider>
      <DeleteResourcesDialogProvider kind={kind}>
        <SaveToLibraryDialogProvider>
          <ResourceLibraryBody kind={kind} />
        </SaveToLibraryDialogProvider>
      </DeleteResourcesDialogProvider>
    </ComponentDialogProvider>
  );
};
