import { CircularProgress, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import colors from "../../styles/colors";
import { invitationKey } from "./Auth0Login";
import { handleLoginResponse } from "./handleLoginResponse";

type CallbackResponse = {
  rawIDToken: string;
  idTokenClaims: IDTokenClaims;
};

type IDTokenClaims = {
  sub: string;
  email: string;
  email_verified: boolean;
  name: string;
  picture: string;
  given_name: string;
  family_name: string;
  locale: string;
  iat: number;
  exp: number;
  iss: string;
  aud: string;
};

export const OIDCCallback: FC = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string | null>(null);
  const [token, setToken] = useState<any | null>(null);
  const invitation = localStorage.getItem(invitationKey);

  const getToken = useCallback(async () => {
    if (loading || token) {
      return;
    }
    setLoading(true);
    try {
      const tokenResponse = await fetch(
        "/auth/oidc/callback?" +
          new URLSearchParams({
            code: searchParams.get("code")!,
            state: searchParams.get("state")!,
          }).toString(),
        {
          credentials: "include",
        },
      );
      const respJson = (await tokenResponse.json()) as CallbackResponse;
      setToken(respJson.rawIDToken);
      setEmail(respJson.idTokenClaims.email);

      const loginResponse = await fetch("/login", {
        method: "POST",
        body: JSON.stringify({
          idToken: respJson.rawIDToken,
          email: respJson.idTokenClaims.email,
          invitation,
        }),
      });

      handleLoginResponse({
        status: loginResponse.status,
        onSuccess: async () => {
          if (error) {
            setError(null);
          }

          const next = searchParams.get("next") || "/agents";
          localStorage.setItem("user", respJson.idTokenClaims.email);
          localStorage.removeItem(invitationKey);
          navigate(next);
        },
        on401: async () => {
          setError("Failed to login.");
        },
        on403: async () => {
          if (error) {
            setError(null);
          }
          localStorage.setItem("user", respJson.idTokenClaims.email);
          localStorage.removeItem(invitationKey);
          navigate("/organization/new");
        },
        on409: async () => {
          if (error) {
            setError(null);
          }
          enqueueSnackbar("Sorry, the invitation is invalid.", {
            variant: "error",
          });
          localStorage.removeItem(invitationKey);
        },
        onFailure: async () => {
          setError("Failed to login.");
        },
      });
    } catch (e) {
      console.error("request failed", { e });
      setError("Failed to login.");
    } finally {
      setLoading(false);
    }
  }, [
    token,
    error,
    enqueueSnackbar,
    navigate,
    loading,
    searchParams,
    invitation,
  ]);

  useEffect(() => {
    if (token == null && email == null && !loading && !error) {
      getToken();
    }
  }, [getToken, token, email, loading, error]);

  return (
    <Stack
      height="100vh"
      alignItems="center"
      justifyContent={"center"}
      bgcolor={colors.backgroundWhite}
    >
      <CircularProgress size={100} />
    </Stack>
  );
};
