import { gql } from "@apollo/client";
import { ComponentProps } from "react";
import { useV2SwitchQuery } from "../../graphql/generated";
import { APIVersion } from "../../types/resources";
import { trimVersion } from "../../utils/version-helpers";
import { PipelineGraphV2 } from "../PipelineGraphV2";
import { PipelineGraph } from "./PipelineGraph";

gql`
  query v2Switch($name: String!) {
    configuration(name: $name) {
      apiVersion
      metadata {
        id
        name
        version
      }
    }
  }
`;

type V2SwitchProps = ComponentProps<typeof PipelineGraph>;

export const V2Switch: React.FC<V2SwitchProps> = ({
  configurationName,
  ...rest
}) => {
  const { data } = useV2SwitchQuery({ variables: { name: configurationName } });
  if (!data || !data.configuration) {
    return null;
  }

  if (data.configuration.apiVersion === APIVersion.V1) {
    return <PipelineGraph configurationName={configurationName} {...rest} />;
  }

  return (
    <PipelineGraphV2
      configurationName={trimVersion(configurationName)}
      {...rest}
    />
  );
};
