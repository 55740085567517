import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { MoreVertical, TrashIcon } from "../Icons";
import styles from "./table-options.module.scss";

interface TableOptionsProps {
  id: string;
  onDelete: (id: string) => void;
  link: string;
}
export const TableOptions: React.FC<TableOptionsProps> = ({
  id,
  link,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    handleClose();
    onDelete(id);
  };

  return (
    <Box className={styles.box}>
      <IconButton
        onClick={handleClick}
        aria-label="options"
        className={styles.button}
      >
        <MoreVertical />
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <TrashIcon width={20} height={20} className={styles.error} />
          </ListItemIcon>
          <ListItemText className={styles.error}>
            <Typography color="error">Delete</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};
