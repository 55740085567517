import { Grid2, Paper, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { ComponentProps, useEffect, useState } from "react";
import { usePreviewFeatureGateQuery } from "../../graphql/generated";
import { useConfigurationEditData } from "../../hooks/useConfigurationEditData";
import { AddDestinationsSection } from "../../pages/configurations/configuration/AddDestinationsSection";
import { AddSourcesSection } from "../../pages/configurations/configuration/AddSourcesSection";
import { hasPipelineTypeFlag } from "../../types/configuration";
import { periodOptionsFromConfig } from "../../utils/period-options-from-config";
import { ComponentDialogProvider } from "../Dialogs/hooks/useComponentDialog";
import { LiveOrDraftSwitcher } from "../LiveOrDraftSwitcher";
import { DEFAULT_PERIOD } from "../MeasurementControlBar";
import { PeriodButtonGroup } from "../MeasurementControlBar/MeasurementControlBar";
import { PipelineGraph } from "../PipelineGraph/PipelineGraph";
import { PipelineGraphAccordion } from "./PipelineGraphAccordion";
import { PipelineGraphErrorBoundary } from "./PipelineGraphErrorBoundary";
import { V2PipelineGraphProvider } from "./PipelineGraphV2Context";
import { AttributeName } from "./types";
import styles from "./pipeline-graph-v2.module.scss";

type PipelineGraphV2Props = ComponentProps<typeof PipelineGraph>;

export const PipelineGraphV2: React.FC<PipelineGraphV2Props> = ({
  readOnly,
  configurationName,
  liveVersion,
  draftVersion,
  agentId,
  tab,
  onTabChange,
  showLiveDraftSwitcher,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [addSourceOpen, setAddSourceOpen] = useState(false);
  const [addDestinationOpen, setAddDestinationOpen] = useState(false);
  const [period, setPeriod] = useState(DEFAULT_PERIOD);
  const [accordionState, setAccordionState] = useState<{
    logs: boolean;
    metrics: boolean;
    traces: boolean;
  }>();

  const {
    live,
    draft,
    refetch: refetchConfiguration,
  } = useConfigurationEditData(configurationName, liveVersion, draftVersion);

  const [previewEnabled, setPreviewEnabled] = useState(false);
  usePreviewFeatureGateQuery({
    onCompleted(data) {
      setPreviewEnabled(data.featureGate);
    },
    onError(err) {
      console.error(err);
      enqueueSnackbar("Error getting feature gate info", {
        variant: "error",
        key: "get-feature-gate-error",
      });
    },
  });

  const configuration = tab === "live" ? live : draft;

  useEffect(() => {
    if (configuration == null || configuration.graph == null) return;
    if (accordionState != null) return;

    const typeFlags =
      configuration.graph.attributes[AttributeName.ActiveTypeFlags];

    setAccordionState({
      logs: hasPipelineTypeFlag("logs", typeFlags),
      metrics: hasPipelineTypeFlag("metrics", typeFlags),
      traces: hasPipelineTypeFlag("traces", typeFlags),
    });
  }, [accordionState, configuration]);

  function handleAccordionToggle(pipelineType: "logs" | "metrics" | "traces") {
    if (!accordionState) return;
    setAccordionState({
      ...accordionState,
      [pipelineType]: !accordionState[pipelineType],
    });
  }

  return (
    <PipelineGraphErrorBoundary>
      <V2PipelineGraphProvider
        period={period}
        configuration={configuration}
        refetchConfiguration={refetchConfiguration}
        addSourceOpen={addSourceOpen}
        setAddSourceOpen={setAddSourceOpen}
        addDestinationOpen={addDestinationOpen}
        setAddDestinationOpen={setAddDestinationOpen}
        readOnly={readOnly}
        agentID={agentId}
      >
        <ComponentDialogProvider
          configuration={configuration}
          onSuccess={() => refetchConfiguration()}
          readOnly={readOnly}
        >
          <GraphContainer>
            {/** Live/Draft and Period Button Group */}
            <Grid2 container width={"100%"} marginBottom={2}>
              <Grid2 size={4}>
                <Stack height="100%" justifyContent="center">
                  <Typography marginLeft={1} variant="h5">
                    Pipelines
                  </Typography>
                </Stack>
              </Grid2>
              <Grid2
                size={4}
                justifyContent={"center"}
                alignItems="center"
                container
              >
                {showLiveDraftSwitcher && (
                  <LiveOrDraftSwitcher tab={tab} onChange={onTabChange} />
                )}
              </Grid2>
              <Grid2
                size={4}
                justifyContent="flex-end"
                alignItems="center"
                container
              >
                <PeriodButtonGroup
                  period={period}
                  onPeriodChange={setPeriod}
                  periodOptions={periodOptionsFromConfig(configuration)}
                />
              </Grid2>
            </Grid2>

            <Stack spacing={1}>
              <PipelineGraphAccordion
                loading={configuration == null}
                period={period}
                telemetryType="logs"
                processorPreviewEnabled={previewEnabled}
                expanded={accordionState?.logs ?? false}
                onChange={() => handleAccordionToggle("logs")}
              />
              <PipelineGraphAccordion
                loading={configuration == null}
                period={period}
                telemetryType="metrics"
                processorPreviewEnabled={previewEnabled}
                expanded={accordionState?.metrics ?? false}
                onChange={() => handleAccordionToggle("metrics")}
              />
              <PipelineGraphAccordion
                loading={configuration == null}
                period={period}
                telemetryType="traces"
                processorPreviewEnabled={previewEnabled}
                expanded={accordionState?.traces ?? false}
                onChange={() => handleAccordionToggle("traces")}
              />
            </Stack>
          </GraphContainer>
        </ComponentDialogProvider>

        {!readOnly && configuration && (
          <>
            <AddSourcesSection
              configuration={configuration}
              refetchConfiguration={refetchConfiguration}
              setAddDialogOpen={setAddSourceOpen}
              addDialogOpen={addSourceOpen}
            />
            <AddDestinationsSection
              configuration={configuration}
              refetchConfiguration={refetchConfiguration}
              setAddDialogOpen={setAddDestinationOpen}
              addDialogOpen={addDestinationOpen}
            />
          </>
        )}
      </V2PipelineGraphProvider>
    </PipelineGraphErrorBoundary>
  );
};

const GraphContainer: React.FC = ({ children }) => {
  return (
    <Paper classes={{ root: styles.container }} elevation={1}>
      {children}
    </Paper>
  );
};
