import { gql } from "@apollo/client";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import React from "react";
import {
  Kind,
  PipelineType,
  ResourceConfiguration,
  useUpdateProcessorsMutation,
  useProcessorsWithParamsQuery,
  ProcessorsWithParamsQuery,
  useGetResourceWithTypeQuery,
  useGetResourceTypeQuery,
} from "../../graphql/generated";
import { BPConfiguration, BPResourceConfiguration } from "../../utils/classes";
import {
  kindFrom,
  resourceTypeKindFrom,
  typeFrom,
} from "../../utils/classes/component-type";
import { findResourceIndex } from "../../utils/classes/configuration";
import { trimVersion } from "../../utils/version-helpers";
import { useProcessorsDialog } from "../ResourceDialog/ProcessorDialogContext";
import { ProcessorDialogComponentProps } from "./ProcessorDialog";

gql`
  query ProcessorsWithParams {
    processors {
      metadata {
        id
        version
        name
      }
      spec {
        type
        parameters {
          name
          value
        }
      }
    }
  }
`;

export type ReusableProcessors = ProcessorsWithParamsQuery["processors"];

/**
 * withCommonProcessorDialog is a higher order component that wraps a ProcessorDialogComponent
 * with the shared logic for the ProcessorDialog and EEProcessorDialog.
 * @param DialogComponent
 * @returns
 */
export function withCommonProcessorDialog(
  DialogComponent: React.FC<ProcessorDialogComponentProps>,
): React.FC {
  return () => {
    const {
      editProcessorsInfo,
      editProcessorsOpen,
      closeProcessorDialog,
      readOnly: readOnlyGraph,
      configuration,
      refetchConfiguration,
      pipelineType,
      agentID,
    } = useProcessorsDialog();
    const componentPath = editProcessorsInfo?.componentPath;
    const [processors, setProcessors] = useState<ResourceConfiguration[]>([]);
    const [resourceConfig, setResourceConfig] =
      useState<BPResourceConfiguration | null>(null);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      if (!editProcessorsInfo) return;
      const resourceConfig = new BPResourceConfiguration(
        editProcessorsInfo.component,
      );
      setProcessors(resourceConfig.processors ?? []);
      setResourceConfig(
        new BPResourceConfiguration(editProcessorsInfo.component),
      );
    }, [editProcessorsInfo]);

    /* ------------------------ GQL Mutations and Queries ----------------------- */
    const { data: resourceTypeData, refetch: refetchType } =
      useGetResourceTypeQuery({
        variables: {
          name: editProcessorsInfo?.component.type!,
          kind: componentPath
            ? resourceTypeKindFrom(componentPath)
            : Kind.Unknown,
        },
        skip: !editProcessorsInfo?.component.type || !componentPath,
      });

    const { data: resourceAndTypeData, refetch: refetchResourceAndType } =
      useGetResourceWithTypeQuery({
        variables: {
          name: editProcessorsInfo?.component.name!,
          kind: componentPath ? kindFrom(componentPath) : Kind.Unknown,
        },
        skip: !editProcessorsInfo?.component.name || !componentPath,
      });

    const resourceType = resourceTypeData
      ? resourceTypeData.resourceType
      : resourceAndTypeData?.resourceWithType.resourceType;

    const [updateProcessors] = useUpdateProcessorsMutation({});

    useEffect(() => {
      if (editProcessorsInfo == null) return;
      if (editProcessorsInfo.component.name) refetchResourceAndType();
      if (editProcessorsInfo.component.type) refetchType();
    }, [editProcessorsInfo, refetchResourceAndType, refetchType]);

    // This data is used by the ChooseView component for reusable processors (and extensions).
    // Putting this fetch here shares it between the EE and non-EE processor dialog.
    const {
      data: reusableProcessorsData,
      refetch: refetchReusableProcessorsData,
    } = useProcessorsWithParamsQuery({ fetchPolicy: "cache-and-network" });

    /* -------------------------------- Functions ------------------------------- */

    // handleClose is called when a user clicks off the dialog or the "X" button
    function handleClose() {
      if (!resourceConfig) return;
      if (!isEqual(processors, resourceConfig.processors ?? [])) {
        const ok = window.confirm("Discard changes?");
        if (!ok) {
          return;
        }
        // reset form values if chooses to discard.
        setProcessors(resourceConfig.processors ?? []);
      }

      closeProcessorDialog();
    }

    async function handleUpdateInlineProcessors(
      processors: ResourceConfiguration[],
    ): Promise<boolean> {
      let success = true;
      await updateProcessors({
        variables: {
          input: {
            configuration: configuration?.metadata?.name!,
            componentPath: componentPath!,
            processors: processors,
          },
        },
        onError(error) {
          success = false;
          console.error(error);
          enqueueSnackbar("Failed to save processors", {
            variant: "error",
            key: "save-processors-error",
          });
        },
      });

      return success;
    }

    if (
      !editProcessorsInfo ||
      !resourceConfig ||
      !componentPath ||
      !configuration
    ) {
      return null;
    }

    let title: string;
    switch (kindFrom(componentPath)) {
      case Kind.Processor:
        title = "Edit Processors";
        break;
      default:
        const parentName = resourceConfig?.isInline()
          ? resourceType?.metadata.displayName
          : trimVersion(resourceConfig.name!);
        title = `${kindFrom(componentPath)} ${parentName}: Processors`;
    }

    let description: string;
    switch (kindFrom(componentPath)) {
      case Kind.Source:
        description =
          "Source processors run on data after being collected by the source.";
        break;
      case Kind.Destination:
        description =
          "Destination processors run on data before being sent to the destination.";
        break;
      default:
        description =
          "Processor nodes run on data as it flows through the pipeline.";
    }
    description = `${description} They will be executed in the order they appear below.`;

    const telemetryTypes: PipelineType[] = resourceType?.spec
      .telemetryTypes ?? [
      PipelineType.Logs,
      PipelineType.Metrics,
      PipelineType.Traces,
    ];

    const bpConfiguration = new BPConfiguration(configuration);

    let handleDelete: (() => Promise<void>) | undefined = undefined;

    // Allow a delete for non source/destination processors
    if (editProcessorsInfo.componentPath.startsWith("processors/")) {
      handleDelete = async () => {
        const bpConfig = new BPConfiguration(configuration);
        bpConfig.deleteComponent(editProcessorsInfo.componentPath);
        await bpConfig.apply();
        closeProcessorDialog();
        refetchConfiguration();
      };
    }

    return (
      <DialogComponent
        agentID={agentID}
        configurationName={configuration.metadata.name}
        description={description}
        dialogOpen={editProcessorsOpen}
        initProcessors={resourceConfig.processors ?? []}
        libraryProcessors={reusableProcessorsData?.processors ?? []}
        onClose={handleClose}
        onProcessorsChange={setProcessors}
        onUpdateInlineProcessors={handleUpdateInlineProcessors}
        closeDialog={closeProcessorDialog}
        pipelineType={pipelineType}
        processors={processors}
        readOnly={!!readOnlyGraph}
        refetchConfiguration={refetchConfiguration}
        refetchLibraryProcessors={refetchReusableProcessorsData}
        resourceIndex={findResourceIndex(configuration, componentPath)}
        resourceName={bpConfiguration.resourceName(componentPath)}
        snapshotID={editProcessorsInfo.snapshotID}
        telemetryTypes={telemetryTypes}
        title={title}
        position={positionFromComponentPath(componentPath)}
        onDelete={handleDelete}
      />
    );
  };
}

function positionFromComponentPath(componentPath: string): "s0" | "d0" | "p1" {
  switch (typeFrom(componentPath)) {
    case "sources":
      return "s0";
    case "destinations":
      return "d0";
    default:
      return "p1";
  }
}
