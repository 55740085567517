import { Card, CardContent, CardProps } from "@mui/material";
import styles from "./summary-page-card.module.scss";

interface SummaryPageCardProps {
  cardStyles: CardProps["style"];
}

export const SummaryPageCard: React.FC<SummaryPageCardProps> = ({
  children,
  cardStyles,
}) => {
  return (
    <Card
      className={styles["card"]}
      style={{
        ...cardStyles,
      }}
    >
      <CardContent className={styles["card-content"]}>{children}</CardContent>
    </Card>
  );
};
