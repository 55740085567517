import { gql } from "@apollo/client";
import { Stack } from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridCellParams,
  GridColDef,
} from "@mui/x-data-grid-pro";
import { memo } from "react";
import {
  Source,
  SourcesQuery,
  useSourcesSummaryQuery,
} from "../../../graphql/generated";
import { convertUnits, orderedByteUnits } from "../../../utils/graph/utils";
import { trimVersion } from "../../../utils/version-helpers";
import { ConfigurationCountCell } from "../ResourceTable/cells";
import { SourceTypeCell } from "./cells";
import styles from "./cells.module.scss";

gql`
  query ConfigurationCount($query: String!) {
    configurationCount(query: $query)
  }
  query SourcesSummary($names: [String!]!) {
    sourcesSummary(
      names: $names
      period: "24h"
      interval: "24h"
      filterGateways: false
    ) {
      sourceName
      totalIngest
    }
  }
`;

export enum SourceTableField {
  NAME = "name",
  TYPE = "type",
  CONFIGURATIONS = "configurations",
  DATA_IN = "data-in",
}

export interface SourceTableProps
  extends Omit<DataGridProProps, "columns" | "rows"> {
  onEditSource: (id: string) => void;
  columnFields?: SourceTableField[];
  sources?: SourcesQuery["sources"];
}

/**
 * Data grid of reusable sources, allowing them to be edited and deleted
 */
export const SourceDataGrid: React.FC<SourceTableProps> = memo(
  ({
    columnFields = [
      SourceTableField.NAME,
      SourceTableField.TYPE,
      SourceTableField.CONFIGURATIONS,
      SourceTableField.DATA_IN,
    ],
    onEditSource,
    sources = [],
    ...props
  }) => {
    /**
     * Because we're querying for per-hour for the last 24 hours, use a query instead of subscription
     */
    const { data } = useSourcesSummaryQuery({
      variables: {
        names: sources.map((source) => source.metadata.name),
      },
      fetchPolicy: "network-only",
    });

    /**
     * Render source type icon and friendly name
     */
    function renderNameAndIconCell({
      value,
    }: GridCellParams<any, string>): JSX.Element {
      return <SourceTypeCell type={trimVersion(value ?? "")} />;
    }

    /**
     * Render name as a button to edit the source
     */
    function renderNameCell(
      cellParams: GridCellParams<any, string>,
    ): JSX.Element {
      return (
        <button
          className={styles.link}
          onClick={() => onEditSource(cellParams.value as string)}
          data-testid={`sources-data-grid-name-${cellParams.row.metadata.name}`}
        >
          {cellParams.value}
        </button>
      );
    }

    /**
     * Render `data in` cell, converting bytes to the most appropriate unit
     * Use blank string if no data is available
     */
    function renderDataInCell(
      cellParams: GridCellParams<any, string>,
    ): JSX.Element {
      const dataIn = data?.sourcesSummary.find(
        (s) => s.sourceName === cellParams.row.metadata.name,
      )?.totalIngest;
      if (dataIn === undefined) {
        return <div />;
      }

      const { unit, value } = convertUnits(
        { value: dataIn, unit: "B" },
        orderedByteUnits,
      );
      return <div>{`${value.toFixed(2)} ${unit}`}</div>;
    }

    const columns: GridColDef<Source>[] = columnFields.map((field) => {
      switch (field) {
        case SourceTableField.NAME:
          return {
            field,
            headerName: "Name",
            width: 300,
            sortable: false,
            type: "string",
            valueGetter: (_, row) => row.metadata.name,
            renderCell: renderNameCell,
          };
        case SourceTableField.TYPE:
          return {
            field,
            headerName: "Type",
            flex: 1,
            width: 200,
            sortable: false,
            valueGetter: (_, row) => row.spec.type,
            renderCell: renderNameAndIconCell,
          };
        case SourceTableField.CONFIGURATIONS:
          return {
            field,
            headerName: "Configurations",
            width: 100,
            flex: 1,
            sortable: false,
            valueGetter: (_, row) => row.metadata.id,
            renderCell: (cellParams) => (
              <ConfigurationCountCell
                resourceName={cellParams.row.metadata?.name as string}
                resourceType="source"
                count={cellParams.row.usage?.configurationCount ?? 0}
              />
            ),
          };
        case SourceTableField.DATA_IN:
          return {
            field,
            headerName: "Data In (24h)",
            flex: 1,
            sortable: true,
            valueGetter: (_, row) =>
              data?.sourcesSummary.find(
                (s) => s.sourceName === row.metadata.name,
              )?.totalIngest ?? 0,
            renderCell: renderDataInCell,
          };
        default:
          return {
            field,
            headerName: field,
            flex: 1,
            sortable: false,
          };
      }
    });

    return (
      <DataGridPro
        {...props}
        autoHeight
        columns={columns}
        rows={sources}
        getRowId={(row) => row.metadata.name}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No Sources
            </Stack>
          ),
        }}
      />
    );
  },
);
