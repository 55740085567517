import { ComponentProps } from "react";
import { HelpMenu } from "../HelpMenu";
import { NavBar } from "../NavBar";
import { SubNav } from "../SubNav";
import { UnlicensedComponentBanner } from "../UnlicensedComponentBanner";

type contentDivProps = ComponentProps<"div">;

// withEENavBar renders the children with a NavBar and SubNav.
// It renders the children in a div with global class "content".
export function withEENavBar(
  FC: React.FC,
  contentWrapperProps?: contentDivProps,
): React.FC {
  if (!contentWrapperProps) {
    contentWrapperProps = { className: "content" };
  }
  return () => (
    <>
      <div id="top-nav">
        <UnlicensedComponentBanner />
        <NavBar />
        <SubNav />
      </div>
      <div {...contentWrapperProps}>
        <FC />
      </div>
      <div className="help-menu">
        <HelpMenu />
      </div>
    </>
  );
}
