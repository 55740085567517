import { createContext, useContext, useState } from "react";
import { PipelineType } from "../../graphql/generated";
import { findResource } from "../../utils/classes/configuration";
import {
  EditProcessorsInfo,
  getSnapshotID,
} from "../PipelineGraphV2/PipelineGraphV2Context";
import { V2Config } from "../PipelineGraphV2/types";
import { ProcessorDialogWithPreview } from "../ProcessorDialogWithPreview";
import { ProcessorDialog } from "../ProcessorsDialog";

interface ProcessorsDialogContextValue {
  agentID?: string;
  closeProcessorDialog: () => void;
  configuration: V2Config | null;
  editProcessors: (componentPath: string) => void;
  editProcessorsInfo: EditProcessorsInfo | null;
  editProcessorsOpen: boolean;
  pipelineType: PipelineType;
  readOnly: boolean;
  refetchConfiguration: () => void;
}

export const processorDialogContext =
  createContext<ProcessorsDialogContextValue>({
    editProcessorsInfo: null,
    editProcessorsOpen: false,
    editProcessors: () => {},
    closeProcessorDialog: () => {},
    readOnly: true,
    configuration: null,
    refetchConfiguration: () => {},
    pipelineType: PipelineType.Logs,
  });

interface ProcessorDialogContextProviderProps {
  configuration: V2Config | null;
  refetchConfiguration: () => void;
  readOnly: boolean;
  pipelineType: PipelineType;
  withProcessorPreview: boolean;
}

export const ProcessorDialogContextProvider: React.FC<
  ProcessorDialogContextProviderProps
> = ({
  children,
  configuration,
  refetchConfiguration,
  readOnly,
  pipelineType,
  withProcessorPreview,
}) => {
  const [editProcessorsInfo, setEditingProcessors] =
    useState<EditProcessorsInfo | null>(null);
  const [editProcessorsOpen, setEditProcessorsOpen] = useState(false);

  function editProcessors(componentPath: string) {
    const component = findResource(configuration!, componentPath);
    if (!component) {
      return;
    }

    const snapshotID = getSnapshotID(configuration!.graph!, componentPath);
    if (!snapshotID) {
      return;
    }

    setEditingProcessors({ component, componentPath, snapshotID });
    setEditProcessorsOpen(true);
  }

  // TODO(dsvanlani): clear this state on exited
  function closeProcessorDialog() {
    setEditProcessorsOpen(false);
    // Reset the editing processors on a timeout to avoid a flash of empty state.
    setTimeout(() => {
      setEditingProcessors(null);
    }, 300);
  }

  const DialogComponent = withProcessorPreview
    ? ProcessorDialogWithPreview
    : ProcessorDialog;

  return (
    <processorDialogContext.Provider
      value={{
        closeProcessorDialog,
        configuration,
        editProcessors,
        editProcessorsInfo,
        editProcessorsOpen,
        pipelineType,
        readOnly,
        refetchConfiguration,
      }}
    >
      {children}
      <DialogComponent />
    </processorDialogContext.Provider>
  );
};

export function useProcessorsDialog() {
  return useContext(processorDialogContext);
}
