import { useSnackbar } from "notistack";
import { createContext, useContext, useState } from "react";
import { Kind } from "../../../graphql/generated";
import { ResourceKind } from "../../../types/resources";
import { ApplyResponse } from "../../../types/rest";
import { BPParameterizedResource } from "../../../utils/classes/resource";
import { applyResources } from "../../../utils/rest/apply-resources";
import { AddToLibraryDialog } from "../AddToLibraryDialog";
import { DialogAction } from "./utils";

export interface NewResource {
  apiVersion: string;
  kind: ResourceKind;
  resourceType: string;
  parameters: { [key: string]: any };
}

interface SaveToLibraryAction extends DialogAction<ApplyResponse> {
  // The resource to save to the library
  resource: NewResource;
  // The names of the existing resources of the same kind to ensure we don't use the same
  // name.
  existingNames: string[];
}

export interface SaveToLibraryDialogContextValue {
  saveToLibrary: (action: SaveToLibraryAction) => void;
}

const defaultValue: SaveToLibraryDialogContextValue = {
  saveToLibrary: () => {
    throw new Error("SaveToLibraryDialogProvider not specified");
  },
};

export const SaveToLibraryDialogContext = createContext(defaultValue);

export const SaveToLibraryDialogProvider: React.FC = ({ children }) => {
  const [action, setAction] = useState<SaveToLibraryAction | undefined>();
  const [open, setOpen] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  async function saveToLibrary(action: SaveToLibraryAction) {
    setAction(action);
    setOpen(true);
  }

  async function save(name: string) {
    if (action == null) {
      return;
    }

    const newResource = action.resource;

    const librarySource = new BPParameterizedResource({
      apiVersion: newResource.apiVersion,
      kind: newResource.kind,
      metadata: {
        name,
        id: "",
        version: 0,
        displayName: newResource.parameters.displayName,
      },
      spec: {
        parameters: [],
        type: newResource.resourceType,
        disabled: false,
      },
    });

    librarySource.setParamsFromMap(newResource.parameters);

    try {
      const result = await applyResources([librarySource]);
      enqueueSnackbar(
        `Successfully added ${newResource.kind} ${name} to Library!`,
        {
          variant: "success",
          autoHideDuration: 3000,
        },
      );
      setOpen(false);

      action?.onSuccess(result);
    } catch (err) {
      enqueueSnackbar(`Failed to add ${newResource.kind} ${name} to Library.`, {
        variant: "error",
        autoHideDuration: 5000,
      });
      console.error(err);
      action?.onError?.(err);
    }

    // reset the state
    setAction(undefined);
  }

  function close() {
    setOpen(false);
    action?.onCancel?.();
    setAction(undefined);
  }

  return (
    <SaveToLibraryDialogContext.Provider
      value={{
        saveToLibrary,
      }}
    >
      <AddToLibraryDialog
        onAdd={save}
        open={open}
        onClose={close}
        kind={action?.resource?.kind ?? Kind.Source} // arbitrary default, should be specified when needed
        existingNames={action?.existingNames ?? []}
      />
      {children}
    </SaveToLibraryDialogContext.Provider>
  );
};

export function useSaveToLibraryDialog(): SaveToLibraryDialogContextValue {
  return useContext(SaveToLibraryDialogContext);
}
