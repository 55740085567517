import { gql } from "@apollo/client";
import { Stack } from "@mui/material";
import {
  Kind,
  useResourceTypeDisplayInfoQuery,
} from "../../../graphql/generated";
import { ProcessorIcon } from "../../Icons";
import styles from "./cells.module.scss";

gql`
  query ResourceTypeDisplayInfo($name: String!, $kind: Kind!) {
    resourceType(name: $name, kind: $kind) {
      metadata {
        id
        displayName
        name
        version
        icon
      }
    }
  }
`;

interface ResourceTypeCellProps {
  type: string;
}

/**
 * Data grid cell that shows display name of a connector type
 */
export const ConnectorTypeCell: React.FC<ResourceTypeCellProps> = ({
  type,
}) => {
  const { data } = useResourceTypeDisplayInfoQuery({
    variables: { name: type, kind: Kind.ConnectorType },
  });
  const text = data?.resourceType?.metadata.displayName ?? type;
  const icon = data?.resourceType?.metadata.icon;
  return (
    <Stack direction="row" spacing={1} className={styles.cell}>
      {icon ? (
        <img className={styles.icon} src={icon} alt={`icon for ${type}`} />
      ) : (
        <ProcessorIcon className={styles.icon} />
      )}
      <span>{text}</span>
    </Stack>
  );
};
