import {
  EdgeMetricV2,
  PipelineGraphV2MetricsSubscription,
} from "../../graphql/generated";
import { MaxValueMap } from "../GraphComponents";

interface PipelineGraphMetricsType {
  metrics: EdgeMetricV2[];
}

type metricID = string;

export class PipelineGraphMetricsData implements PipelineGraphMetricsType {
  metrics: PipelineGraphV2MetricsSubscription["configurationV2Metrics"]["metrics"];
  telemetryType: string;
  private maxValues: MaxValueMap;

  constructor(data: PipelineGraphV2MetricsSubscription, telemetryType: string) {
    this.metrics = data.configurationV2Metrics.metrics;
    this.maxValues = { maxLogValue: 0, maxMetricValue: 0, maxTraceValue: 0 };
    this.calcMaxValues();
    this.telemetryType = telemetryType;
  }

  metric(id: metricID, pipelineType: string): EdgeMetricV2 | undefined {
    return this.metrics.find(
      (metric) =>
        metric.metricID === id && metric.name === metricName(pipelineType),
    );
  }

  maxValue(pipelineType: string) {
    switch (pipelineType) {
      case "logs":
        return this.maxValues.maxLogValue;
      case "metrics":
        return this.maxValues.maxMetricValue;
      case "traces":
        return this.maxValues.maxTraceValue;
    }
    throw new Error(`Unknown pipeline type: ${pipelineType}`);
  }

  /**
   * Calculate the maximum values for each pipeline type
   */
  private calcMaxValues() {
    const newMap: MaxValueMap = {
      maxLogValue: 0,
      maxMetricValue: 0,
      maxTraceValue: 0,
    };
    for (const metric of this.metrics) {
      switch (metric.pipelineType) {
        case "logs":
          newMap.maxLogValue = Math.max(newMap.maxLogValue, metric.value);
          break;
        case "metrics":
          newMap.maxMetricValue = Math.max(newMap.maxMetricValue, metric.value);
          break;
        case "traces":
          newMap.maxTraceValue = Math.max(newMap.maxTraceValue, metric.value);
      }
    }
    this.maxValues = newMap;
  }
}

export const initialData = (telemetryType: string) =>
  new PipelineGraphMetricsData(
    {
      configurationV2Metrics: { metrics: [] },
    },
    telemetryType,
  );

function metricName(pipelineType: string) {
  switch (pipelineType) {
    case "logs":
      return "log_data_size";
    case "metrics":
      return "metric_data_size";
    case "traces":
      return "trace_data_size";
  }
  return "";
}
