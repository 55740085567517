import { NetworkStatus, gql } from "@apollo/client";
import { escapeRegExp } from "lodash";
import { createContext, useContext, useMemo, useState } from "react";
import { v4 } from "uuid";
import {
  PipelineType,
  ProcessorRecommendation,
  SnapshotQuery,
  useSnapshotQuery,
  useSnapshotSearchSupportedQuery,
} from "../../graphql/generated";
import { isFilteredFunc, isModifiedFieldFunc } from "./types";

// while the query includes all three pipeline types, only the pipelineType specified will have results
gql`
  query snapshot(
    $agentID: String!
    $pipelineType: PipelineType!
    $snapshotID: String!
    $searchQuery: String
    $uuid: String!
  ) {
    snapshot(
      agentID: $agentID
      pipelineType: $pipelineType
      snapshotID: $snapshotID
      searchQuery: $searchQuery
      uuid: $uuid
    ) {
      metrics {
        name
        description
        timestamp
        startTimestamp
        unit
        type
        attributes
        resource
        valueType
        valueInt
        valueDouble
        quantileValues
        fields
      }
      logs {
        observedTimestamp
        timestamp
        traceID
        spanID
        body
        severityText
        severityNumber
        attributes
        resource
      }
      traces {
        name
        traceID
        spanID
        parentSpanID
        start
        end
        attributes
        resource
        fields
      }
    }
  }
  query snapshotSearchSupported($agentID: String!) {
    snapshotSearchSupported(agentID: $agentID)
  }
`;

export type DataPoint = SnapshotQuery["snapshot"]["metrics"][0];
export type Log = SnapshotQuery["snapshot"]["logs"][0];
export type Span = SnapshotQuery["snapshot"]["traces"][0];

export interface SnapshotContextValue {
  logs: Log[];
  metrics: DataPoint[];
  traces: Span[];

  setLogs(logs: Log[]): void;
  setMetrics(metrics: DataPoint[]): void;
  setTraces(traces: Span[]): void;

  processedLogs: Log[];
  processedMetrics: DataPoint[];
  processedTraces: Span[];

  setProcessedLogs(logs: Log[]): void;
  setProcessedMetrics(metrics: DataPoint[]): void;
  setProcessedTraces(traces: Span[]): void;

  // true during initial loading and refetching
  loading: boolean;

  // true if a dropdown of agents should be included
  showAgentSelect: boolean;

  // footer is displayed under the snapshot telemetry
  footer: string;
  setFooter(footer: string): void;

  // processedFooter is displayed under the processed telemetry
  processedFooter: string;
  setProcessedFooter(footer: string): void;

  error?: string;
  setError(error: string): void;

  agentID?: string;
  setAgentID(agentID: string | undefined): void;

  pipelineType: PipelineType;
  setPipelineType(type: PipelineType): void;

  searchQuery?: string;
  setSearchQuery(query: string): void;
  snapshotSearchSupported: boolean;
  searchRegex?: RegExp | undefined;
  refresh: () => void;

  // track open snapshot rows
  openRowIDs: string[];
  toggleRow: (id: string) => void;

  isFiltered: isFilteredFunc;
  isAddedField: isModifiedFieldFunc;
  isRemovedField: isModifiedFieldFunc;

  processorRecommendations: ProcessorRecommendation[] | null;
  dismissRecommendation: (id: string) => void;
  onAcceptResourceRecommendation: (id: string) => void;
  refetchResourceRecommendations: () => void;
}

const defaultValue: SnapshotContextValue = {
  logs: [],
  metrics: [],
  traces: [],

  processedLogs: [],
  processedMetrics: [],
  processedTraces: [],

  setLogs: () => {},
  setMetrics: () => {},
  setTraces: () => {},

  setProcessedLogs: () => {},
  setProcessedMetrics: () => {},
  setProcessedTraces: () => {},

  loading: false,
  showAgentSelect: false,

  footer: "",
  setFooter: () => {},

  processedFooter: "",
  setProcessedFooter: () => {},

  error: undefined,
  setError: () => {},

  agentID: undefined,
  setAgentID: () => {},

  searchQuery: "",
  setSearchQuery: () => {},
  snapshotSearchSupported: false,
  searchRegex: undefined,

  pipelineType: PipelineType.Traces,
  setPipelineType: () => {},

  refresh: () => {},

  // track open snapshot rows
  openRowIDs: [],
  toggleRow: () => {},

  isFiltered: () => false,
  isAddedField: () => false,
  isRemovedField: () => false,

  processorRecommendations: null,
  dismissRecommendation: (id: string) => {},
  onAcceptResourceRecommendation: () => {},

  refetchResourceRecommendations: () => {},
};

export const SnapshotContext = createContext(defaultValue);

export interface SnapshotProviderProps {
  pipelineType: PipelineType;
  agentID?: string;
  showAgentSelector?: boolean;
  snapshotID: string;
  skipQuery?: boolean;
}

export const SnapshotContextProvider: React.FC<SnapshotProviderProps> = ({
  children,
  pipelineType: initialPipelineType,
  agentID: initialAgentID,
  showAgentSelector,
  snapshotID,
  skipQuery,
}) => {
  const [pipelineType, setPipelineType] =
    useState<PipelineType>(initialPipelineType);

  const [logs, setLogs] = useState<Log[]>([]);
  const [metrics, setMetrics] = useState<DataPoint[]>([]);
  const [traces, setTraces] = useState<Span[]>([]);

  const [agentID, setAgentID] = useState<string | undefined>(initialAgentID);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [snapshotSearchSupported, setSnapshotSearchSupported] =
    useState<boolean>(false);
  const [searchRegex, setSearchRegex] = useState<RegExp | undefined>();

  const [error, setError] = useState<string>();
  const [uuid] = useState<string>(v4());
  const [footer, setFooter] = useState<string>("");

  useSnapshotSearchSupportedQuery({
    variables: { agentID: agentID! },
    onCompleted: (data) => {
      setSnapshotSearchSupported(data.snapshotSearchSupported);
    },
    skip: agentID == null,
  });
  const { loading, refetch, networkStatus } = useSnapshotQuery({
    variables: {
      agentID: agentID ?? "",
      pipelineType,
      snapshotID,
      searchQuery,
      uuid: process.env.NODE_ENV === "test" ? "test" : uuid,
    },
    skip: agentID == null || skipQuery,
    onCompleted: (data) => {
      const { snapshot } = data;
      setLogs(snapshot.logs.slice().reverse());
      setMetrics(snapshot.metrics.slice().reverse());
      setTraces(snapshot.traces.slice().reverse());
      setError(undefined);
      updateFooterCounts(data);
      if (searchQuery && searchQuery.length > 0) {
        setSearchRegex(new RegExp(`(${escapeRegExp(searchQuery)})`, "g"));
      } else {
        setSearchRegex(undefined);
      }
    },
    onError: (error) => {
      setError(error.message);
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const anyLoading = useMemo(
    () => loading || networkStatus === NetworkStatus.refetch,
    [loading, networkStatus],
  );

  function updateFooterCounts(data: SnapshotQuery) {
    const { logs, metrics, traces } = data.snapshot;
    var count;
    switch (pipelineType) {
      case PipelineType.Logs:
        count = logs.length;
        break;
      case PipelineType.Metrics:
        count = metrics.length;
        break;
      case PipelineType.Traces:
        count = traces.length;
        break;
    }
    setFooter(`Showing ${count} recent ${pipelineType}`);
  }

  // track open snapshot rows
  const [openRowIDs, setOpenRowIDs] = useState<string[]>([]);
  const toggleRow = (rowID: string): void => {
    if (openRowIDs.includes(rowID)) {
      setOpenRowIDs(openRowIDs.filter((id) => id !== rowID));
    } else {
      setOpenRowIDs([...openRowIDs, rowID]);
    }
  };

  return (
    <SnapshotContext.Provider
      value={{
        logs,
        metrics,
        traces,

        setLogs,
        setMetrics,
        setTraces,

        processedLogs: [],
        processedMetrics: [],
        processedTraces: [],

        setProcessedLogs: () => {},
        setProcessedMetrics: () => {},
        setProcessedTraces: () => {},

        loading: anyLoading,
        showAgentSelect: showAgentSelector ?? false,

        footer,
        processedFooter: "",
        setFooter: setFooter,
        setProcessedFooter: () => {},

        error,
        setError,

        agentID,
        setAgentID,

        searchQuery,
        setSearchQuery,
        snapshotSearchSupported,
        searchRegex,

        pipelineType,
        setPipelineType: (type: PipelineType) => {
          setPipelineType(type);
          setFooter("Searching...");
        },

        refresh: () => refetch({ agentID: agentID ?? "", pipelineType }),

        openRowIDs,
        toggleRow,

        isFiltered: () => false,
        isAddedField: () => false,
        isRemovedField: () => false,

        processorRecommendations: [],
        dismissRecommendation: () => {},
        onAcceptResourceRecommendation: () => {},
        refetchResourceRecommendations: () => {},
      }}
    >
      {children}
    </SnapshotContext.Provider>
  );
};

export function useSnapshot(): SnapshotContextValue {
  return useContext(SnapshotContext);
}
