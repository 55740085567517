import { Position } from "reactflow";
import { GetConfigurationWithRoutesQuery } from "../../graphql/generated";

export type V2Config = GetConfigurationWithRoutesQuery["configuration"];

export enum AttributeName {
  // Nodes and Edges
  ActiveTypeFlags = "activeTypeFlags",
  SupportedTypeFlags = "supportedTypeFlags",

  // Nodes Only
  ComponentPath = "componentPath",
  SnapshotID = "snapshotID",
  DestinationIndex = "destinationIndex",
  SourceIndex = "sourceIndex",
  ProcessorIndex = "processorIndex",
  Recommendations = "recommendations",
  ResourceID = "resourceId",
  Resource = "resource",

  // Edges Only
  PipelineType = "pipelineType",
}

type V2NodeAttributes = Partial<Record<AttributeName, any>>;

export interface V2NodeData {
  attributes: V2NodeAttributes;
  telemetryType: string;

  // UI Control Nodes
  buttonText?: string;
  handlePosition?: Position;
  handleType?: "source" | "target";
  onClick?: () => void;
  isButton?: boolean;
}

export interface DummyProcessorNodeData {}
