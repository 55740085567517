import { memo, useEffect, useRef } from "react";
import { EdgeProps, getBezierPath } from "reactflow";
import { classes } from "../../utils/styles";
import { isNodeDisabled } from "../PipelineGraph/Nodes/nodeUtils";
import { useProcessorsDialog } from "../ResourceDialog/ProcessorDialogContext";
import { getWeightedClassNameFunc } from "./utils/get-weighted-classname";
import styles from "./custom-edge.module.scss";

// Constants guiding the edge the animation:
// The original animation was 1 second long and used 90-100% CPU (Firefox Dev
// Ed, other browsers similar, though less dramatic differences). It can be
// replicated with duration = 1000, skipFrames = 1, % CPU hovers around 50-60.

// duration is the length of the animation in milliseconds
export const edgeAnimationDuration = 1000;

// stroke array is 10 3, so the total length is 13
// negative offset gives us the desired effect
export const edgeStrokeOffsetSpan = -13;

// Number of frames to skip before updating the strokeDashoffset
export const skipFrames = 1;

export interface CustomEdgeData {
  connectedNodesAndEdges: string[];
  metrics: {
    rawValue: number;
    value: string;
    startOffset: string;
    textAnchor: string;
  }[];
  active: boolean;
  attributes: Record<string, any>;
}

export interface MaxValueMap {
  maxMetricValue: number;
  maxLogValue: number;
  maxTraceValue: number;
}

export interface CustomEdgeProps extends EdgeProps<CustomEdgeData> {
  hoveredSet?: string[];
  className?: string;
  telemetryType: string;
  maxValues: MaxValueMap;
  getWeightedClassFunc: getWeightedClassNameFunc;
}

export const CustomEdge: React.FC<CustomEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  hoveredSet,
  className,
  telemetryType,
  maxValues,
  getWeightedClassFunc,
}) => {
  hoveredSet ||= [];

  const [path] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const inactive = isNodeDisabled(telemetryType || "", data?.attributes || {});
  const dimmed = hoveredSet.length > 0 && !hoveredSet.includes(id);

  const metricLabels: JSX.Element[] = [];
  if (data?.metrics) {
    for (var i = 0; i < data.metrics.length; i++) {
      const m = data.metrics[i];
      const metric = (
        // transform moves the metric a few pixels off the line
        <g transform={`translate(0 -15)`} key={`metric${i}`}>
          <text>
            <textPath
              className={classes([
                styles["metric"],
                className ? styles[className] : undefined,
              ])}
              href={`#${id}`}
              startOffset={m.startOffset}
              textAnchor={m.textAnchor}
              spacing="auto"
            >
              {m.value}
            </textPath>
          </text>
        </g>
      );
      metricLabels.push(metric);
    }
  }

  const pathClasses = [styles.edge];
  dimmed && pathClasses.push(styles.dimmed);

  if (inactive) {
    pathClasses.push(styles.inactive);
  } else {
    pathClasses.push(styles.dashes);
    pathClasses.push(
      getWeightedClassFunc(data?.metrics, maxValues, telemetryType),
    );
  }

  const { editProcessorsOpen } = useProcessorsDialog();
  const open = useRef(false);
  const pathRef = useRef<SVGPathElement>(null);

  // this stateful variable doesn't work inside the animation loop
  useEffect(() => {
    open.current = editProcessorsOpen;
  }, [editProcessorsOpen]);

  useEffect(() => {
    let frameCount = 0; // Counter for frame skipping

    // Fun fact:
    //    function animate(time: number) {
    // seems to cause a 10-20% CPU usage increase compared to:
    const animate = (time: number) => {
      frameCount++;

      if (frameCount > skipFrames) {
        if (!pathRef.current) return;

        const currentTime = time % edgeAnimationDuration;
        const progress = currentTime / edgeAnimationDuration;
        const currentOffset = progress * edgeStrokeOffsetSpan;
        pathRef.current.style.strokeDashoffset = `${currentOffset}`;

        frameCount = 0; // Reset the frame counter
      }

      if (!inactive && !open.current) {
        requestAnimationFrame(animate);
      }
    }; // ¯\_(ツ)_/¯

    if (!inactive && !open.current) {
      requestAnimationFrame(animate);
    }
  }, [inactive, editProcessorsOpen]);

  return (
    <>
      {metricLabels}
      <path ref={pathRef} id={id} className={classes(pathClasses)} d={path} />
    </>
  );
};

export default memo(CustomEdge);
