import { gql } from "@apollo/client";
import { useUlidLazyQuery } from "../generated";

gql`
  query ulid {
    ulid
  }
`;

interface ulid {
  new: () => Promise<string>;
}

export function useUlid(): ulid {
  const [fetchNewUlid] = useUlidLazyQuery({ fetchPolicy: "network-only" });

  async function returnUlid(): Promise<string> {
    const response = await fetchNewUlid();
    return response.data?.ulid || "";
  }

  return {
    new: returnUlid,
  };
}
