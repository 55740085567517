import { Stack } from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridCellParams,
  GridColDef,
} from "@mui/x-data-grid-pro";
import { memo } from "react";
import { Processor, ProcessorsQuery } from "../../../graphql/generated";
import { trimVersion } from "../../../utils/version-helpers";
import { ConfigurationCountCell } from "../ResourceTable/cells";
import { ProcessorTypeCell } from "./cells";
import styles from "./cells.module.scss";

export enum ProcessorTableField {
  NAME = "name",
  TYPE = "type",
  CONFIGURATIONS = "configurations",
}

export interface ProcessorTableProps
  extends Omit<DataGridProProps, "columns" | "rows"> {
  onEditProcessor: (id: string) => void;
  columnFields?: ProcessorTableField[];
  processors?: ProcessorsQuery["processors"];
}

/**
 * Data grid of reusable processors, allowing them to be edited and deleted
 */
export const ProcessorDataGrid: React.FC<ProcessorTableProps> = memo(
  ({
    columnFields = [
      ProcessorTableField.NAME,
      ProcessorTableField.TYPE,
      ProcessorTableField.CONFIGURATIONS,
    ],
    onEditProcessor,
    processors = [],
    ...dataGridProps
  }) => {
    /**
     * Render name as a button to edit the processor
     */
    function renderNameCell({
      value,
    }: GridCellParams<any, string>): JSX.Element {
      return (
        <button
          className={styles.link}
          // TODO: We need to fetch by ID instead of name
          onClick={() => onEditProcessor(value as string)}
        >
          {value}
        </button>
      );
    }

    const columns: GridColDef<Processor>[] = columnFields.map((field) => {
      switch (field) {
        case ProcessorTableField.NAME:
          return {
            field,
            headerName: "Name",
            width: 300,
            sortable: false,
            valueGetter: (_, row) => row.metadata.name,
            renderCell: renderNameCell,
          };
        case ProcessorTableField.TYPE:
          return {
            field,
            headerName: "Type",
            flex: 1,
            width: 200,
            sortable: false,
            valueGetter: (_, row) => row.spec.type,
            renderCell: ({ value }) => (
              <ProcessorTypeCell type={trimVersion(value ?? "")} />
            ),
          };
        case ProcessorTableField.CONFIGURATIONS:
          return {
            field,
            headerName: "Configurations",
            flex: 2,
            sortable: false,
            renderCell: (cellParams) => (
              <ConfigurationCountCell
                resourceName={cellParams.row.metadata?.name as string}
                resourceType="processor"
                count={cellParams.row.usage?.configurationCount ?? 0}
              />
            ),
          };
        default:
          return {
            field,
            headerName: field,
            flex: 1,
            sortable: false,
          };
      }
    });

    return (
      <DataGridPro
        {...dataGridProps}
        autoHeight
        columns={columns}
        rows={processors}
        getRowId={(row) => row.metadata.name}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No Processors
            </Stack>
          ),
        }}
      />
    );
  },
);
