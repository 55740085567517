import { Stack } from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridCellParams,
  GridColDef,
} from "@mui/x-data-grid-pro";
import { memo } from "react";
import {
  GetResourcesQuery,
  ParameterizedResource,
} from "../../../graphql/generated";
import { trimVersion } from "../../../utils/version-helpers";
import { ConfigurationCountCell } from "../ResourceTable/cells";
import { ConnectorTypeCell } from "./cells";
import styles from "./cells.module.scss";

export enum ConnectorTableField {
  NAME = "name",
  TYPE = "type",
  CONFIGURATIONS = "configurations",
}

export interface ConnectorTableProps
  extends Omit<DataGridProProps, "columns" | "rows"> {
  onEditConnector: (id: string) => void;
  columnFields?: ConnectorTableField[];
  connectors?: GetResourcesQuery["resources"];
}

/**
 * Data grid of reusable connectors, allowing them to be edited and deleted
 */
export const ConnectorDataGrid: React.FC<ConnectorTableProps> = memo(
  ({
    columnFields = [
      ConnectorTableField.NAME,
      ConnectorTableField.TYPE,
      ConnectorTableField.CONFIGURATIONS,
    ],
    onEditConnector,
    connectors = [],
    ...dataGridProps
  }) => {
    /**
     * Render name as a button to edit the connector
     */
    function renderNameCell({
      value,
    }: GridCellParams<any, string>): JSX.Element {
      return (
        <button
          className={styles.link}
          // TODO: We need to fetch by ID instead of name
          onClick={() => onEditConnector(value as string)}
        >
          {value}
        </button>
      );
    }

    const columns: GridColDef<ParameterizedResource>[] = columnFields.map(
      (field) => {
        switch (field) {
          case ConnectorTableField.NAME:
            return {
              field,
              headerName: "Name",
              width: 300,
              sortable: false,
              valueGetter: (_, row) => row.metadata.name,
              renderCell: renderNameCell,
            };
          case ConnectorTableField.TYPE:
            return {
              field,
              headerName: "Type",
              flex: 1,
              width: 200,
              sortable: false,
              valueGetter: (_, row) => row.spec.type,
              renderCell: ({ value }) => (
                <ConnectorTypeCell type={trimVersion(value ?? "")} />
              ),
            };
          case ConnectorTableField.CONFIGURATIONS:
            return {
              field,
              headerName: "Configurations",
              flex: 2,
              sortable: false,
              renderCell: (cellParams) => (
                <ConfigurationCountCell
                  resourceName={cellParams.row.metadata?.name as string}
                  resourceType="connector"
                  count={cellParams.row.usage?.configurationCount ?? 0}
                />
              ),
            };
          default:
            return {
              field,
              headerName: field,
              flex: 1,
              sortable: false,
            };
        }
      },
    );

    return (
      <DataGridPro
        {...dataGridProps}
        autoHeight
        columns={columns}
        rows={connectors}
        getRowId={(row) => row.metadata.name}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No Connectors
            </Stack>
          ),
        }}
      />
    );
  },
);
