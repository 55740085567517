import { cloneDeep } from "lodash";
import { MinimumRequiredConfig } from "../../components/PipelineGraph/PipelineGraph";
import { V2Config } from "../../components/PipelineGraphV2/types";
import {
  Configuration,
  ConfigurationSpec,
  Kind,
  Metadata,
  ResourceConfiguration,
} from "../../graphql/generated";
import { APIVersion, isVersion2, ResourceStatus } from "../../types/resources";
import { applyResources } from "../rest/apply-resources";
import { ComponentType, kindFrom, typeFrom } from "./component-type";
import { BPResourceConfiguration } from "./resource-configuration";

export class BPConfiguration
  implements Pick<Configuration, "apiVersion" | "kind" | "metadata" | "spec">
{
  apiVersion: string;
  kind: Kind;
  spec: ConfigurationSpec;
  metadata: Metadata;
  constructor(configuration: MinimumRequiredConfig) {
    this.apiVersion = configuration?.apiVersion ?? APIVersion.V1;
    this.kind = Kind.Configuration;
    this.spec = configuration?.spec ?? {
      measurementInterval: "",
      raw: "",
      sources: [],
      destinations: [],
      extensions: [],
      rollout: { disabled: false },
    };
    this.metadata = configuration?.metadata ?? {
      name: "",
      id: "",
      version: 1,
    };
  }

  name(): string {
    return this.metadata.name;
  }

  isRaw(): boolean {
    return this.spec.raw != null && this.spec.raw.length > 0;
  }

  isModular(): boolean {
    return !this.isRaw();
  }

  isV2(): boolean {
    return isVersion2(this.apiVersion);
  }

  isV1(): boolean {
    return this.apiVersion === APIVersion.V1;
  }

  addSource(rc: ResourceConfiguration) {
    const newSources = this.spec.sources ? [...this.spec.sources] : [];
    newSources.push(rc);

    const newSpec = cloneDeep(this.spec);
    newSpec.sources = newSources;

    this.spec = newSpec;
  }

  /**
   * @param rc New source
   * @param id ID of the source to be replaced
   * @throws Error if the source with the given ID is not found
   */
  replaceSource(rc: ResourceConfiguration, id: string) {
    const newSources = this.spec.sources ? [...this.spec.sources] : [];
    const idx = this.spec.sources?.findIndex((s) => s.id === id);
    if (idx == null) {
      throw new Error(`failed to find source with id ${id}`);
    }
    newSources[idx] = rc;

    const newSpec = cloneDeep(this.spec);
    newSpec.sources = newSources;

    this.spec = newSpec;
  }

  /**
   * @param id ID of the source to be removed
   * @throws Error if the source with the given ID is not found
   */
  removeSource(id: string) {
    const newSources = this.spec.sources ? [...this.spec.sources] : [];

    const idx = this.spec.sources?.findIndex((s) => s.id === id);
    if (idx == null) {
      throw new Error(`failed to find source with id ${id}`);
    }
    newSources.splice(idx, 1);

    const newSpec = cloneDeep(this.spec);
    newSpec.sources = newSources;

    this.spec = newSpec;
  }

  addDestination(rc: ResourceConfiguration) {
    const newDestinations = this.spec.destinations
      ? [...this.spec.destinations]
      : [];
    newDestinations.push(rc);

    const newSpec = cloneDeep(this.spec);
    newSpec.destinations = newDestinations;

    this.spec = newSpec;
  }

  replaceDestination(rc: ResourceConfiguration, ix: number) {
    const newDestinations = this.spec.destinations
      ? [...this.spec.destinations]
      : [];
    newDestinations[ix] = rc;

    const newSpec = cloneDeep(this.spec);
    newSpec.destinations = newDestinations;

    this.spec = newSpec;
  }

  removeDestination(ix: number) {
    const destinationResourceConfig = this.spec.destinations?.[ix];
    if (destinationResourceConfig == null) {
      throw new Error(`failed to find destination at index ${ix}`);
    }

    if (this.isV2()) {
      const destinationRC = new BPResourceConfiguration(
        destinationResourceConfig,
      );

      this.removeComponentPathFromAllRoutes(
        destinationRC.componentPath("destinations"),
      );
    }

    const newDestinations = this.spec.destinations
      ? [...this.spec.destinations]
      : [];
    newDestinations.splice(ix, 1);

    const newSpec = cloneDeep(this.spec);
    newSpec.destinations = newDestinations;
    this.spec = newSpec;
  }

  removeComponent(rc: BPResourceConfiguration, type: ComponentType) {
    if (rc.id == null || rc.id === "") {
      throw new Error("resource configuration ID is null or empty");
    }
    const newResources = [...this.resources(type)];
    const idx = newResources.findIndex((p) => p.id === rc.id);
    if (idx === -1) {
      throw new Error(`failed to find ${type} with id ${rc.id}`);
    }
    newResources.splice(idx, 1);
    this.setResources(type, newResources);
    // cleanup routes
    this.removeComponentPathFromAllRoutes(rc.componentPath(type));
  }

  /**
   * replace a resource configuration of type componentType.
   * An error will be thrown if
   * 1) the given resource configuration does not have an ID set
   * 2) the resource configuration with the given ID is not found
   *
   * @param rc The new resource configuration to replace the old one
   * @componentType The type of the resource configuration to replace
   */
  updateComponent(rc: ResourceConfiguration, type: ComponentType) {
    if (rc.id == null || rc.id === "") {
      throw new Error("resource configuration ID is null or empty");
    }
    const newResources = [...this.resources(type)];
    const idx = newResources.findIndex((p) => p.id === rc.id);
    idx === -1 ? newResources.push(rc) : (newResources[idx] = rc);
    this.setResources(type, newResources);
  }

  deleteComponent(componentPath: string) {
    const componentType = typeFrom(componentPath);
    const component = this.findResource(componentPath);
    if (component == null) {
      throw new Error(
        `failed to find resource configuration with component path ${componentPath}`,
      );
    }

    this.removeComponent(component, componentType);
  }

  /**
   * insertComponentBetween will insert a new component between c1 and c2.
   * It will update the c1 component to route to the new component, and the new component to route to c2.
   *
   * @param sourceComponentPath
   * @param targetComponentPath
   * @param newComponent
   * @param newComponentType
   * @param telemetryType
   */
  insertComponentBetween(
    sourceComponentPath: string,
    targetComponentPath: string,
    newComponent: ResourceConfiguration,
    newComponentType: ComponentType,
    telemetryType: string,
    connectToTarget: boolean = true,
  ) {
    const insertion = new BPResourceConfiguration(newComponent);
    // We need to update the c1 component to route to the new component, and the new component to route to c2
    const nodeBefore = this.findResource(sourceComponentPath);
    const nodeAfter = this.findResource(targetComponentPath);
    if (!nodeBefore || !nodeAfter) {
      throw new Error(
        `Could not find components ${sourceComponentPath} and ${targetComponentPath}`,
      );
    }

    // Replace the route to the target with the route to the new component
    nodeBefore.removeComponentPathFromPipeline(
      telemetryType,
      targetComponentPath,
    );
    nodeBefore.addRoute(
      telemetryType,
      insertion.componentPath(newComponentType),
    );
    if (connectToTarget) {
      insertion.addRoute(telemetryType, targetComponentPath);
    }

    this.updateComponent(nodeBefore, typeFrom(sourceComponentPath));
    this.updateComponent(insertion, newComponentType);
  }

  updateMeasurementInterval(measurementInterval: string) {
    const newSpec = cloneDeep(this.spec);
    newSpec.measurementInterval = measurementInterval;
    this.spec = newSpec;
  }

  // Adds key value pairs to the selector match label field.
  // Will override any existing labels with that key.
  addMatchLabels(labels: Record<string, string>) {
    this.spec.selector = {
      matchLabels: {
        ...this.spec.selector?.matchLabels,
        ...labels,
      },
    };
  }

  setExtensions(extensions: ResourceConfiguration[]) {
    const newSpec = cloneDeep(this.spec);
    newSpec.extensions = extensions;
    this.spec = newSpec;
  }

  setRollout(rollout: ResourceConfiguration) {
    const newSpec = cloneDeep(this.spec);
    newSpec.rollout = rollout;
    this.spec = newSpec;
  }

  async apply(): Promise<ResourceStatus> {
    const { updates } = await applyResources([this]);
    const update = updates.find(
      (u) => u.resource.metadata.name === this.name(),
    );

    if (update == null) {
      throw new Error(
        `failed to apply updated configuration, no update with name ${this.name()} returned.`,
      );
    }

    return update;
  }

  /**
   * setRaw sets value on the spec.raw field.
   * @param value The raw configuration string to set.
   */
  setRaw(value: string) {
    const newSpec = cloneDeep(this.spec);
    newSpec.raw = value;
    this.spec = newSpec;
  }

  /**
   * getSourceProcessors returns the processors for the source with the given ID.
   * @param sourceId The ID of the source to get processors for.
   */
  getSourceProcessors(sourceId: string): ResourceConfiguration[] {
    const source = this.spec.sources?.find((s) => s.id === sourceId);
    return source?.processors ?? [];
  }
  // _________________________ Routing Methods _________________________ //

  /**
   * removeComponentPathFromAllRoutes removes the componentPath from the routes of
   * all the resourceConfigurations in the configuration.
   * Used when deleting a component from a configuration, e.g. removing a destination.
   */
  removeComponentPathFromAllRoutes(componentPath: string) {
    const newSources: BPResourceConfiguration[] = [];
    for (const resource of this.resources("sources")) {
      const newRC = new BPResourceConfiguration(resource);
      newRC.removeComponentPathFromAllPipelines(componentPath);
      newSources.push(newRC);
    }

    const newConnectors: BPResourceConfiguration[] = [];
    for (const resource of this.resources("connectors")) {
      const newRC = new BPResourceConfiguration(resource);
      newRC.removeComponentPathFromAllPipelines(componentPath);
      newConnectors.push(newRC);
    }

    const newProcessors: BPResourceConfiguration[] = [];
    for (const resource of this.resources("processors")) {
      const newRC = new BPResourceConfiguration(resource);
      newRC.removeComponentPathFromAllPipelines(componentPath);
      newProcessors.push(newRC);
    }

    this.spec = {
      ...cloneDeep(this.spec),
      sources: newSources,
      connectors: newConnectors,
      processors: newProcessors,
    };
  }

  /**
   * removeComponentPathFromRC removes the componentPath from the routes of the component of type 'kind' at index.
   * Used when deleting one edge of a connection.
   *
   * @param removeFrom the component path of the item to remove the component path from
   * @param removePath the component path to remove
   * @param telemetryType the telemetry type to remove the component path from
   */
  removeComponentPathFromRC(
    removeFrom: string,
    removePath: string,
    telemetryType: string,
  ) {
    const resourceConfig = this.findResource(removeFrom);
    if (resourceConfig == null) {
      throw new Error(
        `failed to find resource configuration with component path ${removeFrom}`,
      );
    }
    resourceConfig.removeComponentPathFromPipeline(telemetryType, removePath);

    const componentType = removeFrom.split("/")[0] as ComponentType;
    this.updateComponent(resourceConfig, componentType);
  }

  /**
   *
   * @param forComponent the component path of the resource to add a route to
   * @param addComponentPath the component path to add
   * @param telemetryType
   */
  addComponentRoute(
    forComponent: string,
    addComponentPath: string,
    telemetryType: string,
  ) {
    const resourceConfig = findResource(this, forComponent);
    if (resourceConfig == null) {
      throw new Error(
        `failed to find resource configuration with component path ${forComponent}`,
      );
    }
    resourceConfig.addRoute(telemetryType, addComponentPath);

    this.updateComponent(
      resourceConfig,
      forComponent.split("/")[0] as ComponentType,
    );
  }

  findResource(componentPath: string): BPResourceConfiguration | null {
    return findResource(this, componentPath);
  }

  resources(type: ComponentType): ResourceConfiguration[] {
    return resources(this, type);
  }

  /**
   *
   * @returns all resources in the configuration, only particularly useful for testing.
   */
  allResources(): ResourceConfiguration[] {
    return [
      ...this.resources("sources"),
      ...this.resources("destinations"),
      ...this.resources("processors"),
      ...this.resources("connectors"),
    ];
  }

  setResources(type: ComponentType, rcs: ResourceConfiguration[]) {
    const newSpec = cloneDeep(this.spec);
    switch (type) {
      case "sources":
        newSpec.sources = rcs;
        break;
      case "destinations":
        newSpec.destinations = rcs;
        break;
      case "processors":
        newSpec.processors = rcs;
        break;
      case "connectors":
        newSpec.connectors = rcs;
        break;
    }
    this.spec = newSpec;
  }

  resourceName(componentPath: string): string {
    const rc = this.findResource(componentPath);
    if (!rc) return "";

    const resourceConfig = new BPResourceConfiguration(rc);

    // This is crazy, but the resourceName corresponds to the node id in the graph.
    //    Library sources have the form <source-name>_<source-id>
    //    Inline sources have the form 'source<source-index>_<source-id>'
    //    Library destinations have the form <destination-name>-<destination-index>
    let resourceName: string;
    switch (kindFrom(componentPath)) {
      case Kind.Source:
        resourceName = resourceConfig.isInline()
          ? `source${findResourceIndex(this, componentPath)}_${resourceConfig.ID()}`
          : `${resourceConfig.name}_${resourceConfig.ID()}`;
        break;
      case Kind.Destination:
        resourceName = `${resourceConfig.name}-${findResourceIndex(this, componentPath)}`;
        break;
      default:
        // Not ideal, but this shouldn't happen and we aren't guaranteed to be in
        // an error boundary so we can't throw an error.
        resourceName = "";
    }
    return resourceName;
  }

  /**
   * Returns true if this configuration contains a processor that requires a route receiver.
   */
  requiresRouteReceiver(): boolean {
    return this.allResources().some((rc) =>
      rc.processors?.some((p) => processorNeedsRouteReceiver(p)),
    );
  }
}

/**
 * getRCFromSpec returns the resource configuration at the given index and kind.
 */
export function findResourceByIndex(
  config: V2Config,
  kind: ComponentType,
  index: number,
) {
  let rc: ResourceConfiguration | null = null;
  switch (kind) {
    case "sources":
      const sources = config?.spec.sources ?? [];
      rc = sources[index];
      break;
    case "destinations":
      const destinations = config?.spec.destinations ?? [];
      rc = destinations[index];
      break;
    case "processors":
      const processors = config?.spec.sources?.[index]?.processors ?? [];
      rc = processors[0];
      break;
    case "connectors":
      const connectors = config?.spec.connectors ?? [];
      rc = connectors[index];
      break;
  }

  if (rc == null) {
    return null;
  }

  return new BPResourceConfiguration(rc);
}

export function findResource(
  configuration: NonNullable<V2Config>,
  componentPath: string,
): BPResourceConfiguration | null {
  const [componentType, id] = componentPath.split("/");

  for (const rc of resources(configuration, componentType)) {
    if (rc.id === id) {
      return new BPResourceConfiguration(rc);
    }
  }
  return null;
}

export function findResourceIndex(
  configuration: V2Config,
  componentPath: string,
): number {
  const [componentType, id] = componentPath.split("/");

  return resources(configuration, componentType).findIndex(
    (rc) => rc.id === id,
  );
}

function resources(
  configuration: V2Config,
  componentType: string,
): ResourceConfiguration[] {
  switch (componentType) {
    case "sources":
      return configuration?.spec.sources ?? [];
    case "destinations":
      return configuration?.spec.destinations ?? [];
    case "processors":
      return configuration?.spec.processors ?? [];
    case "connectors":
      return configuration?.spec.connectors ?? [];
    default:
      return [];
  }
}

/**
 * Returns true if the specified processor is one that requires a route receiver. This
 * matches the implementation in model/configuration.go. Note that library processors will
 * always return false because processors that require a route receiver cannot be stored
 * in the library.
 */
function processorNeedsRouteReceiver(
  processor: ResourceConfiguration,
): boolean {
  return (
    processor.type != null &&
    (processor.type.startsWith("count_logs") ||
      processor.type.startsWith("extract_metric") ||
      processor.type.startsWith("count_telemetry"))
  );
}
