import { Tabs, Tab } from "@mui/material";
import styles from "./live-or-draft-switcher.module.scss";

interface LiveOrDraftSwitcherProps {
  tab: "live" | "draft";
  onChange(tab: "live" | "draft"): void;
}

export const LiveOrDraftSwitcher: React.FC<LiveOrDraftSwitcherProps> = ({
  tab,
  onChange,
}) => {
  function handleChange(_: React.ChangeEvent<{}>, newValue: "live" | "draft") {
    onChange(newValue);
  }
  return (
    <Tabs
      value={tab}
      onChange={handleChange}
      className={styles["live-draft-switcher"]}
    >
      <Tab label="Live" value="live" data-testid="config-live-tab" />
      <Tab label="Draft" value="draft" data-testid="config-draft-tab" />
    </Tabs>
  );
};
