import { createContext, useContext } from "react";
import { Graph, ResourceConfiguration } from "../../graphql/generated";
import { AttributeName, V2Config } from "./types";

export interface PipelineGraphContextValue {
  configuration: V2Config;

  refetchConfiguration: () => void;

  period: string;

  readOnlyGraph?: boolean;

  addSourceOpen: boolean;
  addDestinationOpen: boolean;
  setAddSourceOpen: (value: boolean) => void;
  setAddDestinationOpen: (value: boolean) => void;

  agentID?: string;
}

export interface PipelineGraphProviderProps {
  configuration: V2Config;
  refetchConfiguration: () => void;
  period: string;
  readOnly?: boolean;
  addSourceOpen: boolean;
  addDestinationOpen: boolean;
  setAddSourceOpen: (value: boolean) => void;
  setAddDestinationOpen: (value: boolean) => void;
  agentID?: string;
}

export interface EditProcessorsInfo {
  componentPath: string;
  snapshotID: string;
  component: ResourceConfiguration;
}

function throwContextNotSetError() {
  throw new Error("PipelineGraphV2Context not set");
}

const defaultValue: PipelineGraphContextValue = {
  configuration: null,
  refetchConfiguration: throwContextNotSetError,
  period: "1m",
  setAddSourceOpen: throwContextNotSetError,
  setAddDestinationOpen: throwContextNotSetError,
  addSourceOpen: false,
  addDestinationOpen: false,
  agentID: undefined,
};

export const V2PipelineContext = createContext(defaultValue);

export const V2PipelineGraphProvider: React.FC<PipelineGraphProviderProps> = ({
  children,
  period,
  configuration,
  refetchConfiguration,
  setAddSourceOpen,
  setAddDestinationOpen,
  addSourceOpen,
  addDestinationOpen,
  readOnly,
  agentID,
}) => {
  return (
    <V2PipelineContext.Provider
      value={{
        configuration,
        refetchConfiguration,
        readOnlyGraph: readOnly,
        setAddSourceOpen,
        setAddDestinationOpen,
        addSourceOpen,
        addDestinationOpen,
        agentID,
        period,
      }}
    >
      {children}
    </V2PipelineContext.Provider>
  );
};

export function getSnapshotID(
  graph: Graph,
  componentPath: string,
): string | null {
  const node =
    [...graph.sources, ...graph.intermediates, ...graph.targets].find(
      (n) =>
        n.attributes[AttributeName.ComponentPath] === componentPath &&
        n.attributes[AttributeName.SnapshotID],
    ) ?? null;

  if (node) {
    return node.attributes[AttributeName.SnapshotID];
  }

  return null;
}

export function useV2PipelineGraph(): PipelineGraphContextValue {
  return useContext(V2PipelineContext);
}
