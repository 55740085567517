import { ApolloError, gql } from "@apollo/client";
import { Card, Paper, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { ReactFlowProvider } from "reactflow";
import {
  PipelineType,
  useConfigurationMetricsSubscription,
  usePreviewFeatureGateQuery,
} from "../../graphql/generated";
import { useConfigurationEditData } from "../../hooks/useConfigurationEditData";
import { ShowPageConfig } from "../../pages/configurations/configuration";
import { AddDestinationsSection } from "../../pages/configurations/configuration/AddDestinationsSection";
import { AddSourcesSection } from "../../pages/configurations/configuration/AddSourcesSection";
import { Page } from "../../utils/graph/utils";
import { periodOptionsFromConfig } from "../../utils/period-options-from-config";
import { trimVersion } from "../../utils/version-helpers";
import { MaxValueMap } from "../GraphComponents";
import { LiveOrDraftSwitcher } from "../LiveOrDraftSwitcher";
import { DEFAULT_PERIOD } from "../MeasurementControlBar";
import {
  DEFAULT_TELEMETRY_TYPE,
  MeasurementControlBar,
} from "../MeasurementControlBar/MeasurementControlBar";
import { ProcessorDialogContextProvider } from "../ResourceDialog/ProcessorDialogContext";
import { ConfigurationFlow } from "./ConfigurationFlow";
import { PipelineGraphProvider } from "./PipelineGraphContext";
import styles from "./pipeline-graph.module.scss";

gql`
  query PreviewFeatureGate {
    featureGate(id: "realtime-processor-preview")
  }

  subscription ConfigurationMetrics(
    $period: String!
    $name: String!
    $agent: String
  ) {
    configurationMetrics(period: $period, name: $name, agent: $agent) {
      metrics {
        name
        nodeID
        pipelineType
        value
        unit
      }
      maxMetricValue
      maxLogValue
      maxTraceValue
    }
  }
`;

export type MinimumRequiredConfig = Partial<ShowPageConfig>;

interface PipelineGraphProps {
  // configurationName is the versioned configuration name
  configurationName: string;
  // agentId can be specified to show the pipeline/telemetry for an agent
  agentId?: string;
  // readOnly will set edit dialogs to be read only
  readOnly?: boolean;

  tab: "live" | "draft";
  onTabChange: (tab: "live" | "draft") => void;
  showLiveDraftSwitcher: boolean;
  liveVersion: number;
  draftVersion?: number;

  // skipMeasurements will skip the subscription for pipeline measurements
  skipMeasurements?: boolean;
}

export const PipelineGraph: React.FC<PipelineGraphProps> = ({
  configurationName,
  agentId,
  readOnly,
  skipMeasurements,
  showLiveDraftSwitcher,
  liveVersion,
  draftVersion,
  tab,
  onTabChange,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedTelemetry, setSelectedTelemetry] = useState<string>(
    DEFAULT_TELEMETRY_TYPE,
  );
  const [period, setPeriod] = useState<string>(DEFAULT_PERIOD);
  const [addSourceOpen, setAddSourceOpen] = useState(false);
  const [addDestinationOpen, setAddDestinationOpen] = useState(false);
  const [maxValues, setMaxValues] = useState<MaxValueMap>({
    maxMetricValue: 0,
    maxLogValue: 0,
    maxTraceValue: 0,
  });

  function onError(err: ApolloError) {
    console.error(err);
    enqueueSnackbar(err.message, { variant: "error" });
  }

  const {
    live,
    draft,
    refetch: refetchConfiguration,
  } = useConfigurationEditData(configurationName, liveVersion, draftVersion);

  const { data: measurementData } = useConfigurationMetricsSubscription({
    variables: {
      period,
      name: trimVersion(configurationName),
      agent: agentId,
    },
    onError,
    onData({ data }) {
      if (data.data?.configurationMetrics) {
        setMaxValues({
          maxMetricValue: data.data.configurationMetrics.maxMetricValue,
          maxLogValue: data.data.configurationMetrics.maxLogValue,
          maxTraceValue: data.data.configurationMetrics.maxTraceValue,
        });
      }
    },
    skip: skipMeasurements,
  });

  const [previewEnabled, setPreviewEnabled] = useState(false);
  usePreviewFeatureGateQuery({
    onCompleted(data) {
      setPreviewEnabled(data.featureGate);
    },
    onError(err) {
      console.error(err);
      enqueueSnackbar("Error getting feature gate info", {
        variant: "error",
        key: "get-feature-gate-error",
      });
    },
  });

  const configuration = tab === "live" ? live : draft;

  return (
    <PipelineGraphProvider
      selectedTelemetryType={selectedTelemetry || DEFAULT_TELEMETRY_TYPE}
      configuration={configuration}
      refetchConfiguration={refetchConfiguration}
      addSourceOpen={addSourceOpen}
      setAddSourceOpen={setAddSourceOpen}
      addDestinationOpen={addDestinationOpen}
      setAddDestinationOpen={setAddDestinationOpen}
      readOnly={readOnly}
      maxValues={maxValues}
      agentID={agentId}
    >
      <ProcessorDialogContextProvider
        configuration={configuration}
        refetchConfiguration={refetchConfiguration}
        readOnly={!!readOnly}
        pipelineType={selectedTelemetry as PipelineType}
        withProcessorPreview={!!previewEnabled}
      >
        <MeasurementControlBar
          telemetry={selectedTelemetry!}
          onTelemetryTypeChange={setSelectedTelemetry}
          period={period ?? DEFAULT_PERIOD}
          onPeriodChange={setPeriod}
          periodOptions={periodOptionsFromConfig(configuration)}
        >
          <Stack>
            {showLiveDraftSwitcher && (
              <LiveOrDraftSwitcher tab={tab} onChange={onTabChange} />
            )}
          </Stack>
        </MeasurementControlBar>
        <GraphContainer>
          <Card className={styles.card}>
            <ReactFlowProvider>
              <ConfigurationFlow
                period={period}
                selectedTelemetry={selectedTelemetry}
                page={agentId ? Page.Agent : Page.Configuration}
                loading={configuration == null}
                configurationName={trimVersion(configurationName)}
                measurementData={measurementData}
              />
            </ReactFlowProvider>
          </Card>
        </GraphContainer>
      </ProcessorDialogContextProvider>
      {!readOnly && configuration && (
        <>
          <AddSourcesSection
            configuration={configuration}
            refetchConfiguration={refetchConfiguration}
            setAddDialogOpen={setAddSourceOpen}
            addDialogOpen={addSourceOpen}
          />
          <AddDestinationsSection
            configuration={configuration}
            refetchConfiguration={refetchConfiguration}
            setAddDialogOpen={setAddDestinationOpen}
            addDialogOpen={addDestinationOpen}
          />
        </>
      )}
    </PipelineGraphProvider>
  );
};

const GraphContainer: React.FC = ({ children }) => {
  return (
    <Paper classes={{ root: styles.container }} elevation={1}>
      {children}
    </Paper>
  );
};
