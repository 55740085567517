import { ThemeOptions, createTheme } from "@mui/material/styles";
import { ChevronRight } from "../components/Icons";
import colors from "../styles/colors";

const themeOptions: ThemeOptions = {
  secondaryFontFamily: "'Montserrat', sans-serif",
  palette: {
    primary: {
      main: colors.pixelPointBlue,
    },
    secondary: {
      main: colors.darkGray,
    },
    trace: {
      main: "#54b359",
      contrastText: colors.backgroundWhite,
    },
    debug: {
      main: "#54b399",
      contrastText: colors.backgroundWhite,
    },
    warning: {
      main: "#d68e57",
      contrastText: colors.backgroundWhite,
    },
    fatal: {
      main: "#82392b",
      contrastText: colors.backgroundWhite,
    },
  },
  typography: {
    allVariants: {
      color: colors.black,
    },

    fontFamily: "'Nunito Sans', sans-serif;",
    fontWeightBold: 600,
    button: {
      fontWeight: 700,
    },
  },

  components: {
    MuiPaper: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "0.3rem",
          boxShadow: "none",
          ":hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.middleLightGray,
          color: colors.pixelPointBlue,
          height: 10,
          borderRadius: 2,
        },
        barColorPrimary: {
          backgroundColor: colors.pixelPointBlue,
          borderRadius: 2,
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: <ChevronRight width="18px" color={colors.middleDarkGray} />,
      },
    },
  },
};

export const defaultTheme = createTheme(themeOptions);
