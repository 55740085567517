import { createContext, useContext, useEffect, useState } from "react";
import {
  Node as GraphNode,
  Kind,
  PipelineType,
} from "../../../graphql/generated";
import { useUlid } from "../../../graphql/shared-queries/get-ulid";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";
import { hasPipelineTypeFlag } from "../../../types/configuration";
import { BPConfiguration } from "../../../utils/classes";
import {
  ComponentType,
  componentTypeOfKind,
} from "../../../utils/classes/component-type";
import { newEmpty } from "../../../utils/classes/resource-configuration";
import { BPGraph } from "../../ConfigurationFlowV2/graph";
import { useNewResourceDialog } from "../../Dialogs/hooks/useNewResourceDialog";
import { useBPGraph } from "../BPGraphProvider";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { AttributeName, V2Config } from "../types";
import { EdgeMenu } from "./EdgeMenu";

interface RoutingContextProps {
  readOnly: boolean;
  telemetryType: string;
  configuration: V2Config;
}

type ComponentInfo = {
  componentPath: string;
  componentType: ComponentType;
};

interface RoutingContextValue {
  onRouteButtonClick: (
    componentType: ComponentType,
    componentPath: string,
  ) => void;
  // hasAvailableConnections returns true if the component at the given path
  // can be connected to other nodes in the graph.
  hasAvailableConnections(componentPath: string): boolean;

  // canConnect returns true if the component at the given path
  // can be connected to the currently selected component.
  canConnect(componentPath: string): boolean;

  isConnecting: boolean;

  // a callback used to connect the a component to the currently selected
  onConnect: (componentPath: string) => Promise<void>;

  // a flag to indicate if the graph is in read-only mode
  readOnly: boolean;

  // onEdgeButtonClick should open the edge menu.
  onEdgeButtonClick: (
    anchorEl: HTMLElement,
    sourceId: string,
    targetId: string,
  ) => void;
}

function throwContextNotSetError() {
  throw new Error("RoutingContext not set");
}

const defaultValue: RoutingContextValue = {
  hasAvailableConnections: () => {
    throwContextNotSetError();
    return false;
  },
  canConnect: () => {
    throwContextNotSetError();
    return false;
  },
  onRouteButtonClick: throwContextNotSetError,
  onConnect: async () => throwContextNotSetError(),
  readOnly: true,
  onEdgeButtonClick: throwContextNotSetError,
  isConnecting: false,
};

export const routingContext = createContext<RoutingContextValue>(defaultValue);

/**
 * RoutingContext provides the callbacks needed for routing in the PipelineGraph
 */
export const RoutingContextProvider: React.FC<RoutingContextProps> = ({
  children,
  readOnly,
  configuration,
  telemetryType,
}) => {
  const [connectingComponent, setConnectingComponent] =
    useState<ComponentInfo | null>(null);
  const [edgeMenuAnchor, setEdgeMenuAnchor] = useState<HTMLElement | null>(
    null,
  );
  const [edgeMenuSubject, setEdgeMenuTarget] = useState<{
    source: string;
    target: string;
  } | null>(null);

  const { createNewResource } = useNewResourceDialog();

  const { refetchConfiguration } = useV2PipelineGraph();
  const { graph } = useBPGraph();
  const ulid = useUlid();
  const v2ConnectorsEnabled = useFeatureFlag("v2Connectors");

  // Add event listener to exit the 'connecting' mode.
  useEffect(() => {
    function handleEscape(e: KeyboardEvent) {
      if (e.key === "Escape" && connectingComponent) {
        setConnectingComponent(null);
      }
    }
    document.addEventListener("keydown", handleEscape);

    return () => document.removeEventListener("keydown", handleEscape);
  }, [connectingComponent]);

  // Clear the connecting component when telemetry type changes
  useEffect(() => {
    setConnectingComponent(null);
  }, [telemetryType]);

  function handleRouteButtonClick(
    componentType: ComponentType,
    componentPath: string,
  ) {
    setConnectingComponent({ componentType, componentPath });
  }

  async function handleDeleteEdge() {
    if (!graph || !edgeMenuSubject) return;
    const source = graph.findNode(edgeMenuSubject.source);
    const target = graph.findNode(edgeMenuSubject.target);

    if (!source || !target) return;
    const cfg = new BPConfiguration(configuration);
    cfg.removeComponentPathFromRC(
      source.attributes[AttributeName.ComponentPath],
      target.attributes[AttributeName.ComponentPath],
      telemetryType,
    );

    await cfg.apply();
    refetchConfiguration();
    setEdgeMenuAnchor(null);
    setEdgeMenuTarget(null);
  }

  async function connectComponentRoute(componentPath: string) {
    if (connectingComponent == null || configuration == null) return;

    const cfg = new BPConfiguration(configuration);
    cfg.addComponentRoute(
      connectingComponent.componentPath,
      componentPath,
      telemetryType,
    );
    await cfg.apply();
    refetchConfiguration();

    setConnectingComponent(null);
  }

  async function handleInsertProcessorNode() {
    if (!edgeMenuSubject || !graph) return;
    const sourceNode = graph.findNode(edgeMenuSubject.source);
    const targetNode = graph.findNode(edgeMenuSubject.target);

    if (!sourceNode || !targetNode)
      throw new Error("couldn't find source or target node");

    const cfg = new BPConfiguration(configuration);
    const processorList = newEmpty(await ulid.new());
    cfg.insertComponentBetween(
      sourceNode.attributes[AttributeName.ComponentPath],
      targetNode.attributes[AttributeName.ComponentPath],
      processorList,
      "processors",
      telemetryType,
    );
    await cfg.apply();
    setEdgeMenuAnchor(null);
    setEdgeMenuTarget(null);
    refetchConfiguration();
  }

  function handleInsertConnectorNode() {
    if (!edgeMenuSubject || !graph) return;
    const sourceNode = graph.findNode(edgeMenuSubject.source);
    const targetNode = graph.findNode(edgeMenuSubject.target);

    if (!sourceNode || !targetNode)
      throw new Error("couldn't find source or target node");

    createNewResource({
      kind: Kind.Connector,
      onSuccess: async (newConnector) => {
        const cfg = new BPConfiguration(configuration);
        cfg.insertComponentBetween(
          sourceNode.attributes[AttributeName.ComponentPath],
          targetNode.attributes[AttributeName.ComponentPath],
          newConnector,
          componentTypeOfKind(Kind.Connector),
          telemetryType,
          false,
        );
        await cfg.apply();
        setEdgeMenuAnchor(null);
        setEdgeMenuTarget(null);
        refetchConfiguration();
      },
      onError: (e) => {
        setEdgeMenuAnchor(null);
        setEdgeMenuTarget(null);
      },
      onCancel: () => {
        setEdgeMenuAnchor(null);
        setEdgeMenuTarget(null);
      },
    });
  }

  function hasAvailableConnections(nodeID: string): boolean {
    if (!configuration) return false;

    const source = graph.findNode(nodeID);
    if (!source) return false;

    for (const target of graph.getAllNodes()) {
      if (nodesCanConnect(source, target, graph, telemetryType as PipelineType))
        return true;
    }
    return false;
  }

  function canConnect(componentPath: string): boolean {
    if (!configuration || !connectingComponent) return false;
    // get the connecting component
    const sourceNode = graph.findNodes(
      (n) =>
        n.attributes[AttributeName.ComponentPath] ===
        connectingComponent.componentPath,
    )[0];
    if (!sourceNode) return false;
    const targetNode = graph.findNodes(
      (n) => n.attributes[AttributeName.ComponentPath] === componentPath,
    )[0];
    if (!targetNode) return false;

    return nodesCanConnect(
      sourceNode,
      targetNode,
      graph,
      telemetryType as PipelineType,
    );
  }

  function handleEdgeButtonClick(
    anchorEl: HTMLElement,
    sourceNodeId: string,
    targetNodeId: string,
  ) {
    setEdgeMenuAnchor(anchorEl);
    setEdgeMenuTarget({ source: sourceNodeId, target: targetNodeId });
  }

  return (
    <routingContext.Provider
      value={{
        onRouteButtonClick: handleRouteButtonClick,
        onConnect: connectComponentRoute,
        hasAvailableConnections,
        canConnect,
        readOnly,
        onEdgeButtonClick: handleEdgeButtonClick,
        isConnecting: !!connectingComponent,
      }}
    >
      {children}

      <EdgeMenu
        anchorEl={edgeMenuAnchor}
        open={Boolean(edgeMenuAnchor)}
        onClose={() => setEdgeMenuAnchor(null)}
        onInsertProcessorNode={handleInsertProcessorNode}
        onInsertConnectorNode={
          v2ConnectorsEnabled ? handleInsertConnectorNode : undefined
        }
        onDisconnect={handleDeleteEdge}
      />
    </routingContext.Provider>
  );
};

export function useRouting(): RoutingContextValue {
  return useContext(routingContext);
}

function nodesCanConnect(
  source: GraphNode,
  target: GraphNode,
  graph: BPGraph,
  telemetryType: PipelineType,
) {
  if (!hasPipelineTypeFlag(telemetryType, graph.supportedTypeFlags(target)))
    return false;
  // Cannot connect directly to destination nodes
  if (target.type === "destinationNode") return false;
  // Cannot connect to source nodes (or their processors)
  if (target.id.startsWith("source/")) return false;
  // Cannot connect if the nodes are already connected
  if (graph.connectedNodesAndEdges(source.id).includes(target.id)) return false;
  return true;
}
