import { Backdrop, CircularProgress } from "@mui/material";
import { createContext, useContext, useState } from "react";

export interface AppLoadingContextValue {
  appLoading: boolean;
  setAppLoading: (loading: boolean) => void;
}

const defaultValue: AppLoadingContextValue = {
  appLoading: false,
  setAppLoading: () => {},
};

export const AppLoadingContext = createContext(defaultValue);

export const AppLoadingProvider: React.FC = ({ children }) => {
  const [appLoading, setAppLoading] = useState<boolean>(false);
  return (
    <AppLoadingContext.Provider
      value={{
        appLoading,
        setAppLoading,
      }}
    >
      <Backdrop
        open={appLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </AppLoadingContext.Provider>
  );
};

export function useAppLoading(): AppLoadingContextValue {
  return useContext(AppLoadingContext);
}
