import { Kind, ResourceConfiguration } from "../../graphql/generated";
import { ResourceConfigurationLabelCard } from "./ResourceConfigurationLabelCard";

interface Props {
  index: number;
  item: ResourceConfiguration;
  onEdit: () => void;
  onDelete: () => void;
  resourceKind: Kind.Processor | Kind.Extension;
  readOnly: boolean;
}

export const ViewOnlyResourceConfigurationLabel: React.FC<Props> = ({
  index,
  item,
  onEdit,
  onDelete,
  resourceKind,
  readOnly,
}) => {
  return (
    <ResourceConfigurationLabelCard
      resourceKind={resourceKind}
      index={index}
      item={item}
      onEdit={onEdit}
      onDelete={onDelete}
      readOnly={readOnly}
    />
  );
};
