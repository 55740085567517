import { V2Config } from "../components/PipelineGraphV2/types";

export const periodOptionsFromConfig = (config: V2Config) => {
  if (config == null || config.spec == null) return ["1m", "5m", "1h", "24h"];

  switch (config.spec.measurementInterval) {
    case "1m":
      return ["5m", "1h", "24h"];
    case "15m":
      return ["1h", "24h"];
    default:
      return ["1m", "5m", "1h", "24h"];
  }
};
