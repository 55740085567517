import { ApolloError, gql } from "@apollo/client";
import {
  Box,
  Button,
  CardHeader,
  ClickAwayListener,
  Dialog,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LinkButton } from "../../components/LinkButton";
import { RolloutHistory } from "../../components/RolloutHistory";
import { YamlOrLoading } from "../../components/YamlOrLoading";
import {
  Kind,
  useEditConfigDescriptionMutation,
  useGetCurrentConfigVersionQuery,
  useGetFeatureGateQuery,
  useGetLatestConfigDescriptionQuery,
  useGetRenderedConfigLazyQuery,
} from "../../graphql/generated";
import { useRefetchOnConfigurationChange } from "../../hooks/useRefetchOnConfigurationChanges";
import { DuplicateConfigDialog } from "../../pages/configurations/configuration/DuplicateConfigDialog";
import { isResourceV2 } from "../../types/resources";
import {
  getConfigPlatformIcon,
  getConfigPlatformLabel,
} from "../../utils/platform-display-data";
import { deleteResources } from "../../utils/rest/delete-resources";
import { asCurrentVersion, asLatestVersion } from "../../utils/version-helpers";
import { CardContainer } from "../CardContainer";
import { ConfirmDeleteResourceDialog } from "../ConfirmDeleteResourceDialog";
import { ContentSection, TitleSection } from "../DialogComponents";
import { RolloutOptionsDialog } from "../Dialogs/RolloutOptionsDialog.tsx/RolloutOptionsDialog";
import { FormValues } from "../ResourceConfigForm";
import styles from "./configuration-details.module.scss";

gql`
  query getCurrentConfigVersion($configurationName: String!) {
    configuration(name: $configurationName) {
      apiVersion
      metadata {
        id
        name
        version
        labels
      }
      agentCount
    }
  }

  query getLatestConfigDescription($configurationName: String!) {
    configuration(name: $configurationName) {
      apiVersion
      metadata {
        id
        name
        version
        description
        labels
      }
    }
  }

  mutation editConfigDescription($input: EditConfigurationDescriptionInput!) {
    editConfigurationDescription(input: $input)
  }
`;

interface ConfigurationDetailsProps {
  configurationName: string;
  disableEdit?: boolean;
  handleHistoryVersionClick: (v: number) => void;
  handleSaveRolloutOptions: (rt: string, fv: FormValues) => void;
}

/**
 * ConfigurationDetails shows some details about the configuration and allows
 * a user to edit the description.
 *
 * @param configurationName should be the non-versioned name of the configuration
 * @param disableDescriptionEdit if true, the description will not be editable
 * @param handleHistoryVersionClick used to sync RolloutHistory with DiffDialog
 * @param handleSaveRolloutOptions calls from parent to save rollout options into configuration
 */
export const ConfigurationDetails: React.FC<ConfigurationDetailsProps> = ({
  configurationName,
  disableEdit,
  handleHistoryVersionClick,
  handleSaveRolloutOptions,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [rawViewIsOpen, setRawViewIsOpen] = useState(false);
  const [rolloutHistoryIsOpen, setRolloutHistoryIsOpen] = useState(false);
  const [rolloutOptionsOpen, setRolloutOptionsOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const { loading: featureGateLoading, data: featureGateData } =
    useGetFeatureGateQuery({
      variables: {
        input: "progressive-rollouts",
      },
      onError: (err) => {
        console.error(err);
        enqueueSnackbar("Error querying enabled features", {
          variant: "error",
          key: "get-feature-gate-error",
        });
      },
    });

  const navigate = useNavigate();

  async function handleDelete() {
    try {
      await deleteResources([
        {
          kind: Kind.Configuration,
          metadata: {
            name: configurationName,
          },
        },
      ]);
      enqueueSnackbar(`Deleted configuration ${configurationName}`, {
        variant: "success",
      });
      setDeleteDialogOpen(false);
      navigate("/configurations");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to delete configuration", { variant: "error" });
    }
  }

  const { enqueueSnackbar } = useSnackbar();
  function onError(error: ApolloError) {
    enqueueSnackbar(error.message, { variant: "error" });
  }

  const { data: currentVersionData, refetch: refetchCurrent } =
    useGetCurrentConfigVersionQuery({
      variables: {
        configurationName: asCurrentVersion(configurationName),
      },
      onError,
      fetchPolicy: "cache-and-network",
    });
  const { data: latestVersionData, refetch: refetchLatest } =
    useGetLatestConfigDescriptionQuery({
      variables: {
        configurationName: asLatestVersion(configurationName),
      },
      onError,
      fetchPolicy: "cache-and-network",
    });

  useRefetchOnConfigurationChange(configurationName, () => {
    refetchCurrent();
    refetchLatest();
    refetch();
  });

  const [editConfigDescription] = useEditConfigDescriptionMutation();

  async function handleEditDescriptionSave(description: string) {
    await editConfigDescription({
      variables: {
        input: {
          name: configurationName,
          description,
        },
      },
    });

    await refetchLatest();
  }

  const [fetchRawConfig, { data: rawData, refetch }] =
    useGetRenderedConfigLazyQuery({
      variables: { name: configurationName },
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    if (rawViewIsOpen) {
      fetchRawConfig();
    }
  }, [rawViewIsOpen, fetchRawConfig]);

  // Check the initial loading for current and latest version data
  useEffect(() => {
    if (currentVersionData && latestVersionData && initialLoading) {
      setInitialLoading(false);
    }
  }, [currentVersionData, latestVersionData, initialLoading]);

  const details: DetailProps[] = [
    {
      label: "Name",
      value: latestVersionData?.configuration?.metadata.name,
      version: currentVersionData?.configuration?.metadata.version,
      button: (
        <LinkButton onClick={() => setRolloutHistoryIsOpen(true)}>
          History
        </LinkButton>
      ),
    },
    {
      label: "Description",
      value: latestVersionData?.configuration?.metadata.description ?? "",
      onChange: disableEdit ? undefined : handleEditDescriptionSave,
      disableEdit: disableEdit,
    },
    {
      label: "Platform",
      icon: getConfigPlatformIcon(
        currentVersionData?.configuration?.metadata.labels.platform ??
          latestVersionData?.configuration?.metadata.labels.platform,
      ),
      value: getConfigPlatformLabel(
        currentVersionData?.configuration?.metadata.labels.platform ??
          latestVersionData?.configuration?.metadata.labels.platform,
      ),
    },
    {
      label: "Rollout",
      value: `Rolled out to ${
        currentVersionData?.configuration?.agentCount ?? "0"
      } Agent${currentVersionData?.configuration?.agentCount === 1 ? "" : "s"}`,
      button:
        !featureGateLoading && featureGateData?.enabled ? (
          <LinkButton onClick={() => setRolloutOptionsOpen(true)}>
            Rollout Options
          </LinkButton>
        ) : null,
    },
  ];

  if (isResourceV2(latestVersionData?.configuration)) {
    details.push({
      label: "API Version",
      value: latestVersionData?.configuration?.apiVersion,
    });
  }

  function onSaveRolloutOptions(rolloutType: string, formValues: FormValues) {
    setRolloutOptionsOpen(false);
    handleSaveRolloutOptions(rolloutType, formValues);
  }

  return (
    <CardContainer>
      <CardHeader
        action={
          disableEdit ? null : (
            <Stack direction={"row"} spacing={1}>
              <Button
                data-testid="config-details-raw-button"
                variant="outlined"
                size="small"
                onClick={() => setRawViewIsOpen(true)}
              >
                View Configuration File
              </Button>
              <Button
                data-testid="config-details-duplicate-button"
                variant="outlined"
                size="small"
                onClick={() => setDuplicateDialogOpen(true)}
              >
                Duplicate
              </Button>
              <Button
                data-testid="config-details-delete-button"
                variant="outlined"
                size="small"
                onClick={() => setDeleteDialogOpen(true)}
                color="error"
              >
                Delete
              </Button>
            </Stack>
          )
        }
        title={"Details"}
        titleTypographyProps={{ fontWeight: 400 }}
        sx={{ padding: "0 0 3px 0" }}
      />
      <Stack direction={"row"}>
        <Stack flexGrow={1}>
          {details.map((detail) => (
            <Detail key={`config-detail-${detail.label}`} {...detail} />
          ))}
        </Stack>
      </Stack>
      <ConfirmDeleteResourceDialog
        onDelete={handleDelete}
        onCancel={() => setDeleteDialogOpen(false)}
        action={"delete"}
        open={deleteDialogOpen}
      >
        <Typography>
          Are you sure you want to delete this configuration?
        </Typography>
      </ConfirmDeleteResourceDialog>
      <DuplicateConfigDialog
        latestConfigName={
          currentVersionData?.configuration?.metadata.version ===
          latestVersionData?.configuration?.metadata.version
            ? undefined
            : asLatestVersion(configurationName)
        }
        currentConfigName={
          currentVersionData?.configuration
            ? asCurrentVersion(configurationName)
            : undefined
        }
        open={duplicateDialogOpen}
        onClose={() => setDuplicateDialogOpen(false)}
        onSuccess={() => {
          setDuplicateDialogOpen(false);
        }}
      />
      {/* View Config File Dialog */}
      <Dialog
        open={rawViewIsOpen}
        onClose={() => setRawViewIsOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <TitleSection
          onClose={() => setRawViewIsOpen(false)}
          title={rawData?.configuration?.metadata.name}
        ></TitleSection>
        <ContentSection>
          <YamlOrLoading value={rawData?.configuration?.rendered} />
        </ContentSection>
      </Dialog>
      {/* Rollout History Dialog */}
      <Dialog
        open={rolloutHistoryIsOpen}
        onClose={() => setRolloutHistoryIsOpen(false)}
        fullWidth
        style={{ minWidth: "1000px" }}
      >
        <TitleSection
          onClose={() => setRolloutHistoryIsOpen(false)}
          title={"Rollout History"}
        ></TitleSection>
        <ContentSection>
          <RolloutHistory
            configurationName={configurationName}
            handleHistoryVersionClick={handleHistoryVersionClick}
            setDialogOpen={setRolloutHistoryIsOpen}
          />
        </ContentSection>
      </Dialog>
      <RolloutOptionsDialog
        onCancel={() => {
          setRolloutOptionsOpen(false);
        }}
        onSave={onSaveRolloutOptions}
        open={rolloutOptionsOpen}
        readOnly={disableEdit}
        configName={asCurrentVersion(configurationName)}
      />
    </CardContainer>
  );
};

interface DetailProps {
  label: string;
  icon?: ReactElement | null;
  value?: string | number;
  version?: number; // Only intended for use with the version in the name field
  // onChange will render an edit icon and allow the user to edit the value
  // Only intended for use with the description field.
  onChange?: (value: string) => Promise<void>;
  button?: ReactElement | null;
  disableEdit?: boolean;
}

const Detail: React.FC<DetailProps> = ({
  icon,
  label,
  value,
  version,
  onChange,
  button,
  disableEdit,
}) => {
  const [editing, setEditing] = useState(false);
  const textboxRef = useRef<HTMLInputElement | null>(null);

  function handleEdit() {
    setEditing(true);
  }

  async function handleExitEditing() {
    const newDescription = textboxRef.current?.value;
    if (newDescription != null) {
      await onChange?.(newDescription);
    }
    setEditing(false);
  }

  const handleTextFieldKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents the default behavior of the Enter key (e.g., creating a new line)
      handleExitEditing();
    }
  };

  return (
    <Stack direction={"row"} style={{ marginTop: "0.5rem" }}>
      <Stack direction="row" height="24px">
        <Typography fontWeight={600} classes={{ root: styles["key"] }}>
          {label}
        </Typography>
      </Stack>
      {onChange && editing ? (
        <ClickAwayListener onClickAway={handleExitEditing}>
          <TextField
            multiline
            inputRef={textboxRef}
            defaultValue={value}
            fullWidth
            className={styles["edit-box"]}
            inputProps={{ maxLength: 128 }}
            variant="standard"
            autoFocus
            onFocus={(e) => e.currentTarget.setSelectionRange(-1, -1)}
            onKeyDown={(e) => handleTextFieldKeyDown(e)}
          />
        </ClickAwayListener>
      ) : label === "Description" ? (
        <Typography className={styles.value}>
          {value}
          {value && " "}
          {!disableEdit && (
            <LinkButton
              onClick={handleEdit}
              buttonClassName={styles["edit-button"]}
              dataTestID="edit-description-button"
            >
              edit
            </LinkButton>
          )}
        </Typography>
      ) : (
        <Stack direction={"row"} alignItems={"center"}>
          {icon && <Box className={styles["platform-icon"]}>{icon}</Box>}
          {value && (
            <Stack direction={"row"}>
              <Typography className={styles.value}>{value}</Typography>
              {version && (
                <Typography className={styles.version}>v{version}</Typography>
              )}
            </Stack>
          )}
          {button}
        </Stack>
      )}
    </Stack>
  );
};
