import {
  Box,
  Grid2 as Grid,
  Grid2Props as GridProps,
  Stack,
  StackProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { AgentMonitoring } from "../../../components/AgentMonitoring";
import { ConfigurationMonitoring } from "../../../components/ConfigurationMonitoring";
import { ConfigurationSummarySection } from "../../../components/ConfigurationSummarySection/ConfigurationSummarySection";
import { DataSummarySection } from "../../../components/DataSummarySection";
import { withEENavBar } from "../../../components/EENavBar";
import { SummaryPageCard } from "../../../components/SummaryPageCard";
import { withRBAC } from "../../../contexts/RBAC";
import { withRequireLogin } from "../../../contexts/RequireLogin";
import { useGetFeatureGateQuery } from "../../../graphql/generated";
import { useAppBarHeight } from "../../../hooks/useAppBarHeight";
import colors from "../../../styles/colors";
import { RecentActivitySection } from "./RecentActivitySection";
import { SummaryPageContextProvider } from "./SummaryPageContext";

export const SummaryPageContent: React.FC = () => {
  const [enabled, setEnabled] = useState<boolean>();

  // Height is the height of the viewport minus the height of the app bar
  const height = `calc(100vh - ${useAppBarHeight()}px)`;
  const theme = useTheme();
  const isLgOrLarger = useMediaQuery(theme.breakpoints.up("lg"));

  const LayoutContainer = isLgOrLarger ? FlexContainer : GridContainer;

  const outerContainerProps = isLgOrLarger
    ? ({
        spacing: 3,
        direction: "row",
      } as StackProps)
    : ({ container: true, spacing: 3, wrap: "wrap-reverse" } as GridProps);

  const column1Props = isLgOrLarger
    ? ({ width: 398 } as StackProps)
    : ({ lg: 4, md: 12, item: true } as GridProps);

  const column2Props = isLgOrLarger
    ? ({ flexGrow: 1 } as StackProps)
    : ({ lg: 8, md: 12, item: true } as GridProps);

  useGetFeatureGateQuery({
    variables: {
      input: "overview-summary-page",
    },
    onCompleted(data) {
      setEnabled(data.enabled);
    },
  });

  if (enabled === false) {
    return <Navigate to="/overview" />;
  }

  return (
    <SummaryPageContextProvider>
      <Box maxWidth={2000} margin="auto" minHeight={height} padding={3}>
        {/* Page Container */}
        <LayoutContainer {...outerContainerProps}>
          {/* Column 1 */}
          <LayoutContainer {...column1Props}>
            <Grid container spacing={3}>
              <Grid size={{ lg: 12, xs: 6 }}>
                <SummaryPageCard cardStyles={{ minHeight: 500, minWidth: 398 }}>
                  <Stack spacing={3} width={"100%"}>
                    <AgentMonitoring />
                    <ConfigurationMonitoring />
                  </Stack>
                </SummaryPageCard>
              </Grid>

              <Grid size={{ lg: 12, xs: 6 }}>
                <SummaryPageCard cardStyles={{ minHeight: 392, minWidth: 398 }}>
                  <RecentActivitySection />
                </SummaryPageCard>
              </Grid>
            </Grid>
          </LayoutContainer>

          {/* Column 2 */}
          <LayoutContainer {...column2Props}>
            <Grid container spacing={3}>
              <Grid size={{ xs: 12 }}>
                <SummaryPageCard cardStyles={{ height: 500 }}>
                  <DataSummarySection />
                </SummaryPageCard>
              </Grid>

              <Grid size={{ xs: 12 }}>
                <SummaryPageCard cardStyles={{ minHeight: 312 }}>
                  <ConfigurationSummarySection />
                </SummaryPageCard>
              </Grid>

              {/* TODO: Uncomment when source section is implemented 
            <SummaryPageCard title="Top 5 Destinations">
              <DestinationsSummarySection />
            </SummaryPageCard> */}
            </Grid>
          </LayoutContainer>
        </LayoutContainer>
      </Box>
    </SummaryPageContextProvider>
  );
};

const GridContainer: React.FC<GridProps> = ({ children, ...props }) => {
  return <Grid {...props}>{children}</Grid>;
};

const FlexContainer: React.FC<StackProps> = ({ children, ...props }) => {
  return <Stack {...props}>{children}</Stack>;
};

export const SummaryPage = withRequireLogin(
  withRBAC(
    withEENavBar(SummaryPageContent, {
      style: { backgroundColor: colors.white },
    }),
  ),
);
