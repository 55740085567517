import { createContext, useContext, useEffect, useState } from "react";
import {
  Kind,
  useGetResourcesLazyQuery,
  useGetResourceTypesLazyQuery,
} from "../../../graphql/generated";
import { useUlid } from "../../../graphql/shared-queries/get-ulid";
import { ResourceKind } from "../../../types/resources";
import { BPResourceConfiguration } from "../../../utils/classes";
import { resourceTypeOfKind } from "../../../utils/classes/component-type";
import { useAppLoading } from "../../AppLoading/useAppLoading";
import {
  DialogResource,
  NewResourceDialog,
  ResourceType,
} from "../../ResourceDialog";
import { DialogAction } from "./utils";

// The result of the NewResourceAction will be a BPResourceConfiguration representing
// either an inline resource or a named library resource. Check
// BPResourceConfiguration.isInline() if the handling for each case needs to be different.
export interface NewResourceAction
  extends DialogAction<BPResourceConfiguration> {
  // The kind of resource that is being created.
  kind: ResourceKind;

  // The platform associated with the configuration. If unspecified "unknown" will be
  // used.
  platform?: string;

  // The supported telemetry types of the resource type that is
  // being configured.  a subset of ['logs', 'metrics', 'traces']
  telemetryTypes?: string[];

  fromLibrary?: boolean;
}

export interface NewResourceDialogContextValue {
  createNewResource: (action: NewResourceAction) => void;
}

const defaultValue: NewResourceDialogContextValue = {
  createNewResource: () => {
    throw new Error("NewResourceDialogProvider not specified");
  },
};

export const NewResourceDialogContext = createContext(defaultValue);

export const NewResourceDialogProvider: React.FC = ({ children }) => {
  const [action, setAction] = useState<NewResourceAction | undefined>();
  const [open, setOpen] = useState<boolean>(false);

  const [resourceTypes, setResourceTypes] = useState<ResourceType[]>();
  const [libraryResources, setLibraryResources] = useState<DialogResource[]>();

  const { setAppLoading } = useAppLoading();
  const ulid = useUlid();

  // ----------------------------------------------------------------------
  // network requests

  function onError(e: unknown) {
    console.error(e);
    action?.onError?.(e);
    setOpen(false);
  }

  const [fetchLibraryResources] = useGetResourcesLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setLibraryResources(
        data.resources.map((r) => ({
          metadata: {
            name: r.metadata.name,
          },
          spec: r.spec,
        })),
      );
    },
    onError,
  });

  const [fetchResourceTypes] = useGetResourceTypesLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setResourceTypes(data.resourceTypes);
    },
    onError,
  });

  // ----------------------------------------------------------------------

  // trigger fetching of resource types and library resources when the dialog is opened
  useEffect(() => {
    if (open && action != null) {
      setResourceTypes(undefined);
      setLibraryResources(undefined);

      fetchResourceTypes({
        variables: { kind: resourceTypeOfKind(action.kind) },
      });
      fetchLibraryResources({ variables: { kind: action.kind } });
    }
  }, [open, action, fetchResourceTypes, fetchLibraryResources]);

  // clear resource types and library resources when the dialog is closed
  useEffect(() => {
    if (!open) {
      setAction(undefined);
      setResourceTypes(undefined);
      setLibraryResources(undefined);
    }
  }, [open]);

  // determine if we have the information we need to display the dialog
  const isEditing = open && action != null;
  const isReady = resourceTypes != null && libraryResources != null;
  const isVisible = isEditing && isReady;
  useEffect(() => {
    setAppLoading(isEditing && !isReady);
  }, [isEditing, isReady, setAppLoading]);

  // ----------------------------------------------------------------------
  // handlers

  async function handleSaveNew(
    parameters: { [key: string]: any },
    resourceType: ResourceType,
    type?: string,
  ) {
    const newConnector = new BPResourceConfiguration();
    newConnector.id = await ulid.new();
    newConnector.type = resourceType.metadata.name;
    newConnector.setParamsFromMap(parameters);
    action?.onSuccess?.(newConnector);
    setOpen(false);
  }

  async function handleSaveExisting(resource: DialogResource) {
    const newConnector = new BPResourceConfiguration();
    newConnector.id = await ulid.new();
    newConnector.name = resource.metadata.name;
    action?.onSuccess?.(newConnector);
    setOpen(false);
  }

  function onClose() {
    action?.onCancel?.();
    setOpen(false);
  }

  // ----------------------------------------------------------------------

  function createNewResource(action: NewResourceAction) {
    setAction(action);
    setOpen(true);
  }

  return (
    <NewResourceDialogContext.Provider value={{ createNewResource }}>
      <NewResourceDialog
        platform={action?.platform ?? "unknown"}
        kind={action?.kind ?? Kind.Connector}
        telemetryTypes={action?.telemetryTypes ?? []}
        resourceTypes={resourceTypes ?? []}
        resources={libraryResources ?? []}
        existingResourceNames={
          libraryResources?.map((r) => r.metadata.name) ?? []
        }
        open={isVisible}
        onSaveNew={handleSaveNew}
        onSaveExisting={handleSaveExisting}
        onClose={onClose}
        fromLibrary={action?.fromLibrary}
      />
      {children}
    </NewResourceDialogContext.Provider>
  );
};

export function useNewResourceDialog() {
  return useContext(NewResourceDialogContext);
}
