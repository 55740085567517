import { gql } from "@apollo/client";
import {
  CardHeader,
  CircularProgress,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";
import { enqueueSnackbar } from "notistack";
import { useGetConfigurationSummaryQuery } from "../../graphql/generated";
import { useSummaryPageContext } from "../../pages/overview/SummaryPage/SummaryPageContext";
import colors from "../../styles/colors";
import { formatBytes } from "../../utils/graph/utils";
import { xMin } from "../DataSummaryGraph";
import { SearchLink } from "../SearchLink";
import styles from "./configuration-summary-section.module.scss";

gql`
  query GetConfigurationSummary(
    $period: String!
    $interval: String!
    $telemetryType: String!
  ) {
    configurationSummary(
      period: $period
      interval: $interval
      telemetryType: $telemetryType
      filterGateways: false
    ) {
      name
      totalIngest
      totalEgress
      dataset {
        timestamp
        s0Value
        d1Value
      }
    }
  }
`;

export const ConfigurationSummarySection: React.FC = () => {
  const { period, measurementInterval, telemetryType } =
    useSummaryPageContext();
  const { data, loading } = useGetConfigurationSummaryQuery({
    variables: {
      period: period,
      interval: measurementInterval,
      telemetryType: telemetryType!,
    },
    skip: telemetryType === undefined,
    onError: (error) => {
      enqueueSnackbar("Error loading configuration summary", {
        variant: "error",
      });
      console.error(error);
    },
  });

  return (
    <Stack>
      <CardHeader
        title="Top 5 Configurations"
        titleTypographyProps={{
          fontSize: "1rem",
          fontWeight: "700",
          textTransform: "uppercase",
        }}
      />
      <Divider className={styles["divider"]} />
      {data == null || loading ? (
        <Stack height={200} alignItems={"center"} justifyContent={"center"}>
          <CircularProgress size={100} />
        </Stack>
      ) : (
        <Stack className={styles["content"]}>
          {data?.configurationSummary.length === 0 ? (
            <Typography variant="subtitle1">No data</Typography>
          ) : (
            <TableContainer classes={{ root: styles.tableContainer }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {[
                      "Configuration",
                      "Data in",
                      "Total collected",
                      "Data out",
                      "Total sent",
                    ].map((text) => (
                      <TableCell
                        className={`${styles.cell} ${styles.greyText}`}
                        key={text}
                        align="left"
                      >
                        {text}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.configurationSummary.map((row, index) => (
                    <TableRow key={index} classes={{ root: styles.tableRow }}>
                      <TableCell classes={{ root: styles.cell }}>
                        <SearchLink
                          path={`/configurations/${row.name}`}
                          displayName={row.name}
                          className={styles.link}
                        />
                      </TableCell>

                      {row.dataset.length < 2 ? (
                        <TableCell
                          classes={{ root: styles.lineCell }}
                        ></TableCell>
                      ) : (
                        <TableCell classes={{ root: styles.lineCell }}>
                          {row.dataset.length === 0 ? null : (
                            <SparkLineChart
                              valueFormatter={formatBytes}
                              data={row.dataset.map((point) => point.s0Value)}
                              height={25}
                              colors={[colors.lightGreen]}
                              showTooltip
                              showHighlight
                              xAxis={{
                                scaleType: "time",
                                data: row.dataset.map(
                                  (point) => new Date(point.timestamp),
                                ),
                                valueFormatter: (date: Date) => {
                                  return date.toLocaleTimeString();
                                },
                                min: xMin(measurementInterval),
                              }}
                              curve="monotoneX"
                            />
                          )}
                        </TableCell>
                      )}
                      <TableCell classes={{ root: styles.cell }}>
                        {formatBytes(row.totalIngest)}
                      </TableCell>
                      {row.dataset.length < 2 ? (
                        <TableCell
                          classes={{ root: styles.lineCell }}
                        ></TableCell>
                      ) : (
                        <TableCell classes={{ root: styles.lineCell }}>
                          {row.dataset.length === 0 ? null : (
                            <SparkLineChart
                              valueFormatter={formatBytes}
                              data={row.dataset.map((point) => point.d1Value)}
                              height={25}
                              colors={[colors.lightBlue]}
                              showTooltip
                              showHighlight
                              xAxis={{
                                scaleType: "time",
                                data: row.dataset.map(
                                  (point) => new Date(point.timestamp),
                                ),
                                valueFormatter: (date: Date) => {
                                  return date.toLocaleTimeString();
                                },
                                min: xMin(measurementInterval),
                              }}
                              curve="monotoneX"
                            />
                          )}
                        </TableCell>
                      )}
                      <TableCell classes={{ root: styles.cell }}>
                        {formatBytes(row.totalEgress)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      )}
    </Stack>
  );
};
