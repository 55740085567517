import { Button, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import colors from "../../../styles/colors";
import { ExternalLinkIcon } from "../../Icons";
import { SafeLink } from "../../SafeLink";

export interface ConfigurationCountCellProps {
  resourceType: "source" | "processor" | "connector" | "destination";
  resourceName: string;
  count: number;
}

/**
 * Table cell that shows the number of configurations using a resource, with a link to the configurations page
 * showing those configurations
 */
export const ConfigurationCountCellContent: React.FC<
  ConfigurationCountCellProps
> = ({ resourceName, resourceType, count }) => {
  const configsWithResourceURL = useMemo(() => {
    const query = `${resourceType}:${resourceName}`;
    const params = new URLSearchParams();
    params.set("query", query);

    return `/configurations?${params.toString()}`;
  }, [resourceName, resourceType]);

  return (
    <SafeLink
      href={configsWithResourceURL}
      target="_blank"
      rel="noopener noreferrer"
      underline="hover"
      style={{ color: colors.semitoneBlue }}
      data-testid={`configuration-count-link-${resourceName}`}
    >
      <Button
        endIcon={<ExternalLinkIcon color={colors.semitoneBlue} width={15} />}
        sx={{
          padding: "0",
          "& .MuiButton-endIcon": {
            marginRight: "0",
          },
        }}
      >
        <Typography fontSize={14} color={colors.semitoneBlue}>
          {count}
        </Typography>
      </Button>
    </SafeLink>
  );
};

export const ConfigurationCountCell = memo(ConfigurationCountCellContent);
