import { Typography } from "@mui/material";
import { createContext, useContext, useState } from "react";
import { ResourceKind } from "../../../types/resources";
import { ConfirmDeleteResourceDialog } from "../../ConfirmDeleteResourceDialog";
import { DialogAction } from "./utils";

interface ConfirmDeleteResourceAction extends DialogAction<undefined> {
  kind: ResourceKind;
  action: "delete" | "remove";
}

export interface ConfirmDeleteResourceDialogContextValue {
  confirmDeleteResource: (action: ConfirmDeleteResourceAction) => void;
}

const defaultValue: ConfirmDeleteResourceDialogContextValue = {
  confirmDeleteResource: () => {
    throw new Error("ConfirmDeleteResourceDialogProvider not specified");
  },
};

export const ConfirmDeleteResourceDialogContext = createContext(defaultValue);

export const ConfirmDeleteResourceDialogProvider: React.FC = ({ children }) => {
  const [action, setAction] = useState<
    ConfirmDeleteResourceAction | undefined
  >();
  const [open, setOpen] = useState<boolean>(false);

  function confirmDeleteResource(action: ConfirmDeleteResourceAction) {
    setAction(action);
    setOpen(true);
  }

  function onClose() {
    action?.onCancel?.();
    setOpen(false);
  }

  function onDelete() {
    action?.onSuccess?.(undefined);
    setOpen(false);
  }

  return (
    <ConfirmDeleteResourceDialogContext.Provider
      value={{ confirmDeleteResource }}
    >
      <ConfirmDeleteResourceDialog
        open={open}
        onClose={onClose}
        onCancel={onClose}
        onDelete={onDelete}
        action={action?.action ?? "remove"}
      >
        <Typography>
          {`Are you sure you want to remove this ${action?.kind}?`}
        </Typography>
      </ConfirmDeleteResourceDialog>
      {children}
    </ConfirmDeleteResourceDialogContext.Provider>
  );
};

/**
 * Provides a confirmDeleteResource function that will display a confirmation dialog and
 * call the appropriate action handler based on the user's choice.
 */
export function useConfirmDeleteResourceDialog(): ConfirmDeleteResourceDialogContextValue {
  return useContext(ConfirmDeleteResourceDialogContext);
}
