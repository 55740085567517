import {
  Stack,
  CircularProgress,
  Typography,
  CardHeader,
  Divider,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { SearchLink } from "../../../../components/SearchLink";
import {
  useGetAuditEventsQuery,
  GetAuditEventsQuery,
  AuditEventAction,
} from "../../../../graphql/generated";
import colors from "../../../../styles/colors";
import { trimVersion } from "../../../../utils/version-helpers";
import styles from "./recent-activity.module.scss";

type Activity = { timestamp: Date; message: JSX.Element }[];

/**
 * RecentActivitySection displays the 5 most recent audit events.
 *
 * @returns JSX.Element
 */
export const RecentActivitySection: React.FC = () => {
  const [recentActivity, setRecentActivity] = useState<Activity>([]);

  const { enqueueSnackbar } = useSnackbar();

  const { loading } = useGetAuditEventsQuery({
    variables: {
      sort: "-timestamp",
    },
    onCompleted(data) {
      const messages = makeMessages(data.auditEvents);
      setRecentActivity(messages);
    },
    onError(error) {
      console.error(error);
      enqueueSnackbar("Failed to load recent activity", {
        variant: "error",
      });
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return (
      <Stack
        height={40}
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack>
      <CardHeader
        title="Recent Activity"
        titleTypographyProps={{
          fontSize: "1rem",
          fontWeight: "700",
          textTransform: "uppercase",
        }}
      />
      <Divider className={styles["divider"]} />
      <Stack className={styles["content"]}>
        {recentActivity.map(({ timestamp, message }, index) => (
          <Stack direction="row">
            <Stack
              key={`${timestamp.toISOString()}-${index}`}
              borderTop={
                index > 0 ? `1px solid ${colors.verylightGray}` : "none"
              }
              padding={"4px"}
              direction={"column"}
              width="100%"
            >
              {message}
              <Typography variant="caption" color="textSecondary">
                {timestamp.toLocaleString()}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

function makeMessages(
  auditEvents: GetAuditEventsQuery["auditEvents"],
): Activity {
  const activity: Activity = [];
  for (const event of auditEvents) {
    const { user } = event;

    const resourceName = trimVersion(event.resourceName);

    var action = event.action?.toLowerCase();
    if (action === AuditEventAction.Pending) {
      action = "created";
    }

    let resourceKind = event.resourceKind?.toLowerCase();

    if (resourceKind === "rollout") {
      resourceKind += " for configuration";
    }

    let forConfig = <></>;
    switch (resourceKind) {
      case "source":
      case "destination":
      case "processor":
        if (event.configuration) {
          forConfig = (
            <>
              <span style={{ color: colors.darkGray }}>
                {" in configuration "}
              </span>
              <SearchLink
                path={`/configurations/${trimVersion(event.configuration)}`}
                displayName={trimVersion(event.configuration)}
                style={{ textDecoration: "none" }}
              />
            </>
          );
        }
    }

    activity.push({
      timestamp: new Date(event.timestamp),
      message: (
        <Typography fontSize={14}>
          <span style={{ fontWeight: "bold" }}>{user} </span>
          <span style={{ color: colors.darkGray }}>
            {action} {resourceKind.toLowerCase()}
          </span>{" "}
          {resourceKind === "rollout for configuration" ? (
            <SearchLink
              path={`/configurations/${resourceName}`}
              displayName={resourceName}
              style={{ textDecoration: "none" }}
            />
          ) : (
            <span style={{ color: colors.darkGray }}>{resourceName}</span>
          )}
          {forConfig}
        </Typography>
      ),
    });

    if (activity.length >= 5) {
      break;
    }
  }

  return activity;
}
