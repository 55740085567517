import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { createContext, useContext, useEffect, useState } from "react";
import { Kind } from "../../../graphql/generated";
import { ResourceStatus } from "../../../types/resources";
import {
  deleteResources,
  MinimumDeleteResource,
} from "../../../utils/rest/delete-resources";
import { ConfirmDeleteResourceDialog } from "../../ConfirmDeleteResourceDialog";
import { FailedDeleteDialog } from "../../Tables/DestinationsTable/FailedDeleteDialog";
import { DialogAction } from "./utils";

export type OnDeleteHandler = () => void;
export interface DeleteAction extends DialogAction<ResourceStatus[]> {
  confirm: boolean;
  resources: MinimumDeleteResource[];
}

export interface DeleteResourcesDialogContextValue {
  // deleteResources will delete the specified resources and return the status of each resource
  deleteResources: (action: DeleteAction) => void;
}

const defaultValue: DeleteResourcesDialogContextValue = {
  deleteResources: () => {
    throw new Error("DeleteResourcesDialogProvider not specified");
  },
};

export const DeleteResourcesDialogContext = createContext(defaultValue);

export interface DeleteResourcesDialogProviderProps {
  kind: Kind;
}

export const DeleteResourcesDialogProvider: React.FC<
  DeleteResourcesDialogProviderProps
> = ({ kind, children }) => {
  const [action, setAction] = useState<DeleteAction | undefined>();

  const [open, setOpen] = useState<boolean>(false);

  const [failedDeletes, setFailedDeletes] = useState<ResourceStatus[]>([]);
  const [failedDeletesOpen, setFailedDeletesOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (failedDeletes.length > 0) {
      setFailedDeletesOpen(true);
    }
  }, [failedDeletes, setFailedDeletesOpen]);

  async function deleteResourcesItems(deleteAction?: DeleteAction) {
    if (deleteAction == null) {
      return;
    }
    try {
      const { updates } = await deleteResources(deleteAction.resources);
      setOpen(false);

      const failures = updates.filter((u) => u.status !== "deleted");
      setFailedDeletes(failures);

      deleteAction?.onSuccess(updates);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to delete resources.", { variant: "error" });
      deleteAction?.onError?.(err);
    }

    // reset the state
    setAction(undefined);
  }

  function onAcknowledge() {
    setFailedDeletesOpen(false);
  }

  function close() {
    setOpen(false);
    action?.onCancel?.();
    setAction(undefined);
  }

  return (
    <DeleteResourcesDialogContext.Provider
      value={{
        deleteResources: (deleteAction: DeleteAction) => {
          setAction(deleteAction);
          if (deleteAction.confirm) {
            setOpen(true);
          } else {
            deleteResourcesItems(deleteAction);
          }
        },
      }}
    >
      <ConfirmDeleteResourceDialog
        open={open}
        onDelete={() => deleteResourcesItems(action)}
        onClose={close}
        onCancel={close}
        action={"delete"}
      >
        <Typography>
          Are you sure you want to delete {action?.resources.length} {kind}
          {(action?.resources.length ?? 0) > 1 && "s"}?
        </Typography>
      </ConfirmDeleteResourceDialog>
      <FailedDeleteDialog
        open={failedDeletesOpen}
        failures={failedDeletes}
        onAcknowledge={onAcknowledge}
        onClose={() => setFailedDeletesOpen(false)}
      />
      {children}
    </DeleteResourcesDialogContext.Provider>
  );
};

export function useDeleteResourcesDialog(): DeleteResourcesDialogContextValue {
  return useContext(DeleteResourcesDialogContext);
}
